import { Box, Button, IconButton, MenuItem, Switch, Typography } from '@mui/material'
import {
  OrderDirection,
  ROLE,
  useGetAllCountriesQuery,
  useGetEmployerByIdQuery,
  useGetIcpByIdQuery,
  useGetWorkSchedulesByEmployerIdQuery
} from '@epix-web-apps/core'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { SortModel, ScrollableDataTable, usePaginationModel } from '../../components/data-table'
import { CanView, Configuration, DetailPageBaseQueryParams, HeaderTitleNavigation } from '@epix-web-apps/ui'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import AddEditWorkSchedule from '../../components/schedule-components/add-edit-work-schedule'
import DropdownMenu from '../../components/dropdown-menu'
import EditDayEntries from '../../components/schedule-components/edit-day-entries/edit-day-entries'
import SettingsButton from '../../components/settings-button'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import DoneIcon from '@mui/icons-material/Done'
import FilterButton from '../../components/filter-button'
import { useWorkScheduleFilterStore } from '../../stores/workschedule-filter-store'

/* eslint-disable-next-line */
export interface WorkschedulePageProps {}

export type WorkSchedulePageParams = DetailPageBaseQueryParams & {
  employerid: string
}

export function WorkschedulePage(props: WorkschedulePageProps) {
  const params = useParams<WorkSchedulePageParams>()
  const { t } = useTranslation()
  const { paginationModel, setPaginationModel, resetPaginationModel } = usePaginationModel()
  const [sortModel, setSortModel] = useState(new SortModel('', OrderDirection.Asc))
  const [tableData, setTableData] = useState<GridRowsProp>()
  const [rowCount, setRowCount] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [workScheduleId, setWorkScheduleId] = useState('')
  const { openFlyIn } = useFlyIn()
  const { showInactive, setShowInactive } = useWorkScheduleFilterStore()

  const { data: getEmployerById } = useGetEmployerByIdQuery(
    {
      employerId: params.employerid || ''
    },
    {
      enabled: !!params.employerid
    }
  )
  const { data: getIcpById } = useGetIcpByIdQuery(
    {
      icpId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )
  const { data: getCountries } = useGetAllCountriesQuery()

  const {
    data: workschedules,
    isLoading: isLoadingWorkSchedules,
    refetch: refetchWorkSchedules
  } = useGetWorkSchedulesByEmployerIdQuery(
    {
      employerId: params.employerid || '',
      offset: paginationModel.offset,
      limit: paginationModel.pageSize,
      sortByProperty: sortModel.field,
      orderDirection: sortModel.orderDirection,
      showInactive: showInactive
    },
    {
      enabled: !!params.employerid,
      suspense: false
    }
  )

  const code = getIcpById?.icpById.code
  const country = getCountries?.countries.find(c => c.code.includes(code ? code : ''))?.name
  const payrollProvider = getIcpById?.icpById.payrollProvider
  const company = getEmployerById?.employerById.companyName

  const rowActions = (rowParams: GridRenderCellParams) => (
    <IconButton
      aria-label="row actions"
      aria-controls="menu-row"
      aria-haspopup="true"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        setAnchorEl(e.currentTarget)
        setWorkScheduleId(rowParams.row.id)
      }}
    >
      <MoreHorizIcon />
    </IconButton>
  )

  const activeActions = (rowParams: GridRenderCellParams) => (rowParams.value ? <DoneIcon /> : <VisibilityOffIcon />)

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: t('workschedulepage.datatable.column.code'),
      flex: 1,
      sortable: true
    },
    {
      field: 'description',
      headerName: t('workschedulepage.datatable.column.description'),
      flex: 2
    },
    {
      field: 'labelOnCalendar',
      headerName: t('workschedulepage.datatable.column.labelOnCalendar'),
      flex: 1
    },
    {
      field: 'ftePercentage',
      headerName: t('workschedulepage.datatable.column.ftePercentage'),
      flex: 1
    },
    {
      field: 'averageNumberOfHoursPerWeek',
      headerName: t('workschedulepage.datatable.column.averageNumberOfHoursPerWeek'),
      flex: 1
    },
    {
      field: 'effectiveNumberOfHoursPerWeek',
      headerName: t('workschedulepage.datatable.column.effectiveNumberOfHoursPerWeek'),
      flex: 1
    },
    {
      field: 'isActive',
      headerName: t('workschedulepage.datatable.column.isactive'),
      flex: 0.5,
      renderCell: activeActions
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  useEffect(() => {
    resetPaginationModel()
  }, [showInactive])

  const filterBar = (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: [2],
          paddingLeft: [2],
          paddingRight: [2]
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERS(`${params.id}`)}
          title={t('workschedule.title')}
        />
        <Box sx={{ display: 'flex', gap: 1 }}>
          <FilterButton
            filters={{
              showInactive
            }}
          >
            <p>
              <Switch
                checked={showInactive}
                onChange={e => setShowInactive(e.target.checked)}
                aria-label={t('workschedulepage.filters.showinactiveworkschedule')}
              />
              {t('workschedulepage.filters.showinactiveworkschedule')}
            </p>
          </FilterButton>
          <CanView roles={[ROLE.ADMIN, ROLE.MANAGELISTS]}>
            <SettingsButton
              menuItems={[
                {
                  label: t('workschedulepage.settings.shiftcodes'),
                  to: `/configuration/icp-providers/${params.id}/employers/${params.employerid}/shift-codes`
                }
              ]}
            />
          </CanView>

          <Button
            variant="contained"
            onClick={() =>
              openFlyIn({
                content: <AddEditWorkSchedule employerId={params.employerid} />,
                callbackAfterClose: () => refetchWorkSchedules
              })
            }
          >
            <AddIcon />
            {t('workschedulepage.button.add')}
          </Button>
        </Box>
      </Box>
      <Typography color={'gray'} variant="h3" paddingLeft={2}>
        {country} - {payrollProvider} - {company}
      </Typography>
    </Box>
  )

  useEffect(() => {
    if (workschedules && workschedules.workSchedulesByEmployerId) {
      const workSchedulesData = workschedules.workSchedulesByEmployerId
      setRowCount(workSchedulesData.totalCount)
      setTableData(
        workSchedulesData.data.map(row => {
          return {
            id: row.id,
            code: row.code,
            description: row.description,
            labelOnCalendar: row.labelOnCalendar,
            ftePercentage: row.ftePercentage,
            averageNumberOfHoursPerWeek: row.averageNumberOfHoursPerWeek,
            effectiveNumberOfHoursPerWeek: row.effectiveNumberOfHoursPerWeek,
            isActive: row.isActive
          }
        })
      )
    }
  }, [workschedules])

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        isLoading={isLoadingWorkSchedules}
        sortModel={sortModel}
        onSortChange={setSortModel}
        filterBarElement={filterBar}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <AddEditWorkSchedule employerId={params.employerid} workScheduleId={workScheduleId} />,
              callbackAfterClose: () => refetchWorkSchedules
            })
          }
        >
          {t('workschedulepage.list.row.menu.edit-workschedule')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <EditDayEntries employerId={params.employerid} workScheduleId={workScheduleId} />,
              callbackAfterClose: () => refetchWorkSchedules
            })
          }
        >
          {t('workschedulepage.list.row.menu.edit-day-entries')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default WorkschedulePage
