import { OrderDirection, useGetTeamMembersByTeamIdQuery } from '@epix-web-apps/core'
import { Button, Box, IconButton, MenuItem, Typography } from '@mui/material'
import { useTheme } from '@mui/material'
import { People, useFlyIn } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { DataTable, usePaginationModel, SortModel } from '../../data-table'
import DataTableEmpty from '../../data-table/data-table-empty/data-table-empty'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import AddTeamMembers from '../add-team-members/add-team-members'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import DropdownMenu from '../../dropdown-menu/dropdown-menu'
import RemoveTeamMember from '../remove-team-member/remove-team-member'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import MailingListFlyIn from '../mailing-list-flyin'

/* eslint-disable-next-line */
export interface TeamMembersProps {
  teamId: string | undefined
}

export function TeamMembers({ teamId }: TeamMembersProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [tableData, setTableData] = useState<GridRowsProp>()
  const [rowCount, setRowCount] = useState(0)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { paginationModel, setPaginationModel } = usePaginationModel()
  const { openFlyIn } = useFlyIn()
  const [clickedTeamMember, setClickedTeamMemberId] = useState<GridRowId>()

  const rowActions = (rowParams: GridRenderCellParams) => (
    <IconButton
      aria-label="row actions"
      aria-controls="menu-row"
      aria-haspopup="true"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        setClickedTeamMemberId(rowParams.id)
        setAnchorEl(e.currentTarget)
      }}
    >
      <MoreHorizIcon />
    </IconButton>
  )

  const columns: GridColDef[] = [
    {
      field: 'personName',
      headerName: t('teammembers.datatable.column.member'),
      flex: 1,
      sortable: false
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns[0]?.field, OrderDirection.Asc))

  const {
    data: getTeamMembers,
    isLoading: isLoadingTeamMembers,
    refetch: refetchTeamMembers
  } = useGetTeamMembersByTeamIdQuery({
    teamId: teamId || '',
    offset: paginationModel.offset,
    limit: paginationModel.pageSize
  })

  const goToPersonDetailPage = (personId: string) => {
    navigate(People.PEOPLE_ID_DETAILS(personId))
  }

  useEffect(() => {
    if (getTeamMembers && getTeamMembers?.allTeamMembersByTeamId.data) {
      const data = getTeamMembers?.allTeamMembersByTeamId.data
      setTableData(
        data.map(row => {
          return {
            id: row.id,
            personId: row.personId,
            personName: row.personName
          }
        })
      )
      setRowCount(getTeamMembers.allTeamMembersByTeamId.totalCount)
    }
  }, [getTeamMembers?.allTeamMembersByTeamId])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid ' + theme.palette.divider
        }}
      >
        <Typography variant="h4">{t('teamdetailspage.members.title')}</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            onClick={() =>
              openFlyIn({
                content: <MailingListFlyIn teamId={teamId} />
              })
            }
          >
            {t('teamdetailspage.members.mailinglist')}
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() =>
              openFlyIn({
                content: <AddTeamMembers teamId={teamId} />,
                callbackAfterClose: () => refetchTeamMembers
              })
            }
          >
            {t('teamdetailspage.members.addmembers')}
          </Button>
        </Box>
      </Box>
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        density="compact"
        onSortChange={setSortModel}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowClick={params => goToPersonDetailPage(params.row.personId)}
        isLoading={isLoadingTeamMembers}
        sortModel={sortModel}
        emptyStateElement={
          <DataTableEmpty
            title={t('emptystate.teammembers')}
            icon={<GroupAddIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
          />
        }
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <RemoveTeamMember teamId={teamId} teamMemberId={clickedTeamMember?.toString()} />,
              callbackAfterClose: () => refetchTeamMembers
            })
          }
        >
          {t('assignedrolespage.list.row.menu.remove-teammember')}
        </MenuItem>
      </DropdownMenu>
    </Box>
  )
}

export default TeamMembers
