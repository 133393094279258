import { Navigate, Route } from 'react-router-dom'
import HomePage from './selfservice/pages/home-page/home-page'
import ProfileSettingsPage from './selfservice/pages/profile-settings-page/profile-settings-page'
import { BalanceOverview } from './selfservice/components/balance-components/balance-overview'
import { BalanceCalculationRequestPolicy } from './selfservice/components/balance-components/balance-calculation-request-policy'
import { RequestAbsence } from './selfservice/components/absence-components/request-absence'
import { DayDetailCalendar } from './selfservice/components/calendar-components/day-detail-calendar'
import { MyAbsenceRequests } from './selfservice/components/absence-components/my-absence-requests'
import { TeamCalendar } from './selfservice/pages/team-calendar/team-calendar'
import { AbsenceRequestsDetail } from './selfservice/components/absence-components/absence-requests-detail'
import { SpecificPersonCalendar } from './selfservice/pages/specific-person-calendar'
import { PendingRequests } from './selfservice/pages/pending-requests'
import { MyAbsenceRequestsDetail } from './selfservice/components/absence-components/my-absence-requests-detail/my-absence-requests-detail'
import { TeamCalendarFilter } from './selfservice/components/team-calendar-components/team-calendar-filter'
import { IsAuthorized, SelfService } from '@epix-web-apps/ui'
import { Documents } from './selfservice/components/document-components/documents'
import { PendingTasks } from './selfservice/pages/pending-tasks'
import { DocumentDetail } from './selfservice/components/document-components/document-detail'
import { Module } from '@epix-web-apps/core'

export const useSelfServiceRouting = () => {
  return (
    <>
      <Route path="/" element={<Navigate to={SelfService.ROOT()} replace />} />
      <Route path={SelfService.ROOT()}>
        <Route index element={<HomePage />} />
        <Route
          path={SelfService.PERSON_CALENDAR_TEAMID_ID(':teamid', ':id')}
          index
          element={<SpecificPersonCalendar />}
        />
        <Route path={SelfService.BALANCE_OVERVIEW()} index element={<BalanceOverview />} />
        <Route path={SelfService.BALANCE_CALCULATION_ID(':id')} element={<BalanceCalculationRequestPolicy />} />
        <Route path={SelfService.DAY_DETAIL_DAY_ID_TEAMID(':day', ':id', ':teamid?')} element={<DayDetailCalendar />} />
        <Route path={SelfService.PROFILE_SETTINGS()} element={<ProfileSettingsPage />} />
        <Route path={SelfService.REQUEST_ABSENCE_TEAMID_ID(':teamid?', ':id')} element={<RequestAbsence />} />
        <Route path={SelfService.MY_REQUESTS()} element={<MyAbsenceRequests />} />
        <Route path={SelfService.MY_REQUESTS_ID(':id')} element={<MyAbsenceRequestsDetail />} />
        <Route path={SelfService.TEAM_CALENDAR_TEAMID_ID(':teamid', ':id')} element={<TeamCalendar />} />
        <Route path={SelfService.TEAM_CALENDAR_TEAMID_ID_FILTER(':teamid', ':id')} element={<TeamCalendarFilter />} />
        <Route path={SelfService.PENDING_REQUESTS()} element={<PendingRequests />} />
        <Route path={SelfService.ABSENCE_REQUEST_ID(':id')} element={<AbsenceRequestsDetail />} />
        <Route
          path={SelfService.DOCUMENTS()}
          element={
            <IsAuthorized module={Module.Selfservicedocuments}>
              <Documents />
            </IsAuthorized>
          }
        />
        <Route
          path={SelfService.DOCUMENTS_CONTRACTID_DOCUMENTTYPEID(':contractid', ':documenttypeid')}
          element={
            <IsAuthorized module={Module.Selfservicedocuments}>
              <DocumentDetail />
            </IsAuthorized>
          }
        />
        <Route
          path={SelfService.DOCUMENTS_EMPLOYERID_DOCUMENTTYPEID(':employerid', ':documenttypeid')}
          element={
            <IsAuthorized module={Module.Selfservicedocuments}>
              <DocumentDetail />
            </IsAuthorized>
          }
        />
        <Route path={SelfService.PENDING_TASKS()} element={<PendingTasks />} />
      </Route>
    </>
  )
}
