import {
  GraphqlError,
  isAdmin,
  OrderDirection,
  useGetAllIcpsQuery,
  useGetIcpByIdQuery,
  useGetMeQuery,
  useIsIcpLimitReachedQuery,
  useUpdateIcpMutation
} from '@epix-web-apps/core'
import {
  FeatureForPurchaseIndicator,
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditIcpProps {
  icpId: string
}

export function EditIcp({ icpId }: EditIcpProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const { data: me } = useGetMeQuery()
  const { data: isLimitReachedData } = useIsIcpLimitReachedQuery()

  const editIcpInfoSchema = object({
    payrollProvider: string().optional().nullable(),
    country: string().optional().nullable(),
    code: string().max(3, t('form.validation.codemaxchars')),
    active: boolean()
  }).refine(
    input =>
      !getAllIcps?.icps.data.find(icp => icp.code === input.code && icp.active && icp.id !== icpId)
        ? input.code
        : !input.code,
    {
      message: t('form.validation.codeunique'),
      path: ['endDate']
    }
  )

  type EditIcpInfoForm = TypeOf<typeof editIcpInfoSchema>

  const { data: getAllIcps } = useGetAllIcpsQuery(
    {
      activeOnly: false,
      offset: 0,
      limit: -1,
      sortByProperty: null,
      orderDirection: OrderDirection.Asc
    },
    {
      suspense: false
    }
  )

  const { data: getIcpById, refetch: refetchIcpById } = useGetIcpByIdQuery(
    {
      icpId: icpId || ''
    },
    {
      enabled: !!icpId
    }
  )

  const { closeFlyIn } = useFlyIn()
  const mutationUpdate = useUpdateIcpMutation()

  const form = useForm<EditIcpInfoForm>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      payrollProvider: getIcpById?.icpById.payrollProvider,
      country: getIcpById?.icpById.country,
      code: getIcpById?.icpById.code,
      active: getIcpById?.icpById.active ?? false
    },
    resolver: zodResolver(editIcpInfoSchema)
  })

  useEffect(() => {
    form.setValue('active', getIcpById?.icpById.active ?? false)
  }, [])

  const handleOnSubmit = async (icp: EditIcpInfoForm) => {
    await mutationUpdate
      .mutateAsync({
        updateIcpCommand: {
          id: icpId,
          code: icp.code,
          active: icp.active,
          payrollProvider: getIcpById?.icpById?.payrollProvider ?? ''
        }
      })
      .then(() => {
        refetchIcpById()
        closeFlyIn()
      })
      .catch(e => setBackendErrors([e]))
  }

  const disableActiveSwitch = !getIcpById?.icpById.active && isLimitReachedData?.isIcpLimitReached

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editicp.title')}</Typography>

      <FormGridLayout>
        {isAdmin(me?.me.roles) && <FormInput sx={12} name="code" label={t('form.field.icpcode')} />}

        <FormInput disabled sx={12} name="payrollProvider" label={t('form.field.payrollprovider')} />
        <FormInput disabled sx={12} name="country" label={t('form.field.country')} />

        {isAdmin(me?.me.roles) && (
          <Grid item xs={12}>
            <FormSwitch name="active" label={t('form.field.active')} disabled={disableActiveSwitch} />
            {disableActiveSwitch && (
              <Box sx={{ fontStyle: 'italic' }}>
                {t('limits.providerlimitreached')} <FeatureForPurchaseIndicator />
              </Box>
            )}
          </Grid>
        )}

        <Grid xs={12} item>
          <Typography sx={{ fontStyle: 'italic', color: theme.palette.text.secondary }}>
            <Trans i18nKey="form.field.activedescription" values={{ settingscode: getIcpById?.icpById.settingsCode }} />
          </Typography>
        </Grid>
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={mutationUpdate.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditIcp
