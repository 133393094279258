import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Box, Button, IconButton, MenuItem, Typography } from '@mui/material'
import { Configuration, DetailPageBaseQueryParams, HeaderTitleNavigation, useFlyIn } from '@epix-web-apps/ui'
import { SortModel, ScrollableDataTable, usePaginationModel } from '../../components/data-table'
import {
  OrderDirection,
  useGetAllShiftCodesByEmployerIdQuery,
  useGetEmployerByIdQuery,
  useGetIcpByIdQuery
} from '@epix-web-apps/core'
import AddIcon from '@mui/icons-material/Add'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import AddEditShiftCode from '../../components/employer-components/add-edit-shift-code'
import DropdownMenu from '../../components/dropdown-menu'

// eslint-disable-next-line
export interface ShiftCodesPageProps {}

export type ShiftCodesPageParams = DetailPageBaseQueryParams & {
  employerid: string
}

export function ShiftCodesPage(props: ShiftCodesPageProps) {
  const [tableData, setTableData] = useState<GridRowsProp>()
  const [rowCount, setRowCount] = useState(0)
  const { paginationModel, setPaginationModel } = usePaginationModel()
  const [clickedShiftCodeId, setClickedShiftCodeId] = useState('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const params = useParams<ShiftCodesPageParams>()

  const employerId = params.employerid ?? ''
  const icpId = params.id ?? ''

  const { data: getEmployerById } = useGetEmployerByIdQuery({
    employerId: employerId
  })
  const { data: getIcpById } = useGetIcpByIdQuery({
    icpId: icpId
  })

  const country = getIcpById?.icpById.countryName
  const payrollProvider = getIcpById?.icpById.payrollProvider
  const company = getEmployerById?.employerById.companyName

  const filterBar = (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: [2],
          paddingLeft: [2],
          paddingRight: [2]
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_WORKSCHEDULE(`${icpId}`, `${params.employerid}`)}
          title={t('shiftcodespage.title')}
        />

        <Button
          variant="contained"
          onClick={() =>
            openFlyIn({
              content: <AddEditShiftCode employerId={employerId}></AddEditShiftCode>,
              callbackAfterClose: () => refetchShiftCodes
            })
          }
        >
          <AddIcon />
          {t('shiftcodespage.button.add')}
        </Button>
      </Box>
      <Typography color={'gray'} variant="h3" paddingLeft={2}>
        {country} - {payrollProvider} - {company}
      </Typography>
    </Box>
  )

  const rowActions = (rowParams: GridRenderCellParams) => (
    <IconButton
      aria-label="row actions"
      aria-controls="menu-row"
      aria-haspopup="true"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        setClickedShiftCodeId(rowParams.row.id)
        setAnchorEl(e.currentTarget)
      }}
    >
      <MoreHorizIcon />
    </IconButton>
  )

  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName: t('shiftcodespage.datatable.column.description'),
      flex: 1,
      sortable: true
    },
    {
      field: 'code',
      headerName: t('shiftcodespage.datatable.column.code'),
      flex: 1,
      sortable: true
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns?.[0].field, OrderDirection.Asc))

  const {
    data: shiftCodesData,
    isLoading,
    refetch: refetchShiftCodes
  } = useGetAllShiftCodesByEmployerIdQuery({
    employerId: params.employerid ?? '',
    limit: paginationModel.pageSize,
    offset: paginationModel.offset,
    sortByProperty: sortModel.field,
    orderDirection: sortModel.orderDirection
  })

  useEffect(() => {
    if (shiftCodesData && shiftCodesData.allShiftCodesByEmployerId) {
      const shiftcodes = shiftCodesData.allShiftCodesByEmployerId
      setRowCount(shiftcodes.totalCount)
      setTableData(
        shiftcodes.data.map(row => {
          return {
            id: row.id,
            code: row.code,
            description: row.description
          }
        })
      )
    }
  }, [shiftCodesData])

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        isLoading={isLoading}
        sortModel={sortModel}
        onSortChange={setSortModel}
        filterBarElement={filterBar}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <AddEditShiftCode employerId={employerId} shiftCodeId={clickedShiftCodeId} />,
              callbackAfterClose: () => refetchShiftCodes
            })
          }
        >
          {t('shiftcodespage.list.row.menu.editdetails')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default ShiftCodesPage
