import {
  addDays,
  FormatDateToDDMM,
  ToBackendFormatedDate,
  UpcomingStartersLeaversModel,
  useGetPagedLeaversQuery
} from '@epix-web-apps/core'
import { People, useFlyIn, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Grid } from '@mui/material'
import { HideableSuspensePaper } from '../../suspense-paper'
import { useTranslation } from 'react-i18next'
import HomepageEventWidget from '../homepage-event-widget'
import { useNavigate } from 'react-router-dom'
import { OverviewEventLeavers } from '../overview-event-leavers'

/* eslint-disable-next-line */
export interface UpcomingLeaversComponentProps {}

export function UpcomingLeaversComponent(props: UpcomingLeaversComponentProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const { openFlyIn } = useFlyIn()
  const { data: getPagedLeavers, isLoading } = useGetPagedLeaversQuery(
    {
      icps: icpFilters,
      startDate: ToBackendFormatedDate(new Date()),
      endDate: ToBackendFormatedDate(addDays(new Date(), 30)),
      offset: 0,
      limit: 4
    },
    { suspense: false }
  )

  if (getPagedLeavers?.pagedLeavers && getPagedLeavers.pagedLeavers.totalCount === 0) return null

  return (
    <Grid item sm={12} lg={6}>
      <HideableSuspensePaper isLoading={isLoading} title={t('homepage.event.leavers')}>
        <HomepageEventWidget
          data={getPagedLeavers?.pagedLeavers.data ?? []}
          length={getPagedLeavers?.pagedLeavers.totalCount ?? 0}
          subtext={(item: UpcomingStartersLeaversModel) =>
            `${item.countryKey} (${FormatDateToDDMM(new Date(item.upcomingDate))})`
          }
          onItemClick={item => navigate(People.PEOPLE_ID_DETAILS(item.personId))}
          onMoreClick={() => {
            openFlyIn({ content: <OverviewEventLeavers /> })
          }}
        />
      </HideableSuspensePaper>
    </Grid>
  )
}

export default UpcomingLeaversComponent
