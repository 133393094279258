import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormSelect
} from '@epix-web-apps/ui'
import { Box, Grid, Typography } from '@mui/material'
import { FormSwitch } from '../../form-components/form-switch'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  FormSelectOption,
  numericString,
  TenantOwner,
  useGetFeaturesQuery,
  useGetTenantConfigurationQuery,
  useUpdateTenantConfigurationMutation
} from '@epix-web-apps/core'
import { useForm, useWatch } from 'react-hook-form'
import { boolean, object, TypeOf, nativeEnum, number } from 'zod'

const tenantOwnerOptions = Object.values(TenantOwner).map(v => new FormSelectOption(v, v))

export function TenantFeaturesForm() {
  const { t } = useTranslation()
  const { data: tenantConfigurationData, refetch: refetchFeatures } = useGetTenantConfigurationQuery()
  const { data: getFeatureFlags } = useGetFeaturesQuery()
  const {
    calculatedByEpix,
    hasCalendarManagement,
    hasCompanyCars,
    hasDocumentManagement,
    hasPayComponentManagement,
    hasSelfService,
    tenantOwner,
    hasSmartPayComponents,
    hasEpixCopilot,
    allowActivatingDefaultProviders,
    activePeopleLimit,
    providerLimit,
    adminLimit
  } = tenantConfigurationData?.tenantConfiguration ?? {}
  const updatemutation = useUpdateTenantConfigurationMutation()

  const editConfigurationForm = object({
    calculatedByEpix: boolean(),
    hasPayComponentManagement: boolean(),
    hasCalendarManagement: boolean(),
    hasDocumentManagement: boolean(),
    hasSelfService: boolean(),
    hasCompanyCars: boolean(),
    hasSmartPayComponents: boolean(),
    hasEpixCopilot: boolean(),
    allowActivatingDefaultProviders: boolean(),
    activePeopleLimit: numericString(number().nonnegative()),
    providerLimit: numericString(number().nonnegative()),
    adminLimit: numericString(number().nonnegative()),
    tenantOwner: nativeEnum(TenantOwner)
  })
  type EditConfigurationForm = TypeOf<typeof editConfigurationForm>

  const featuresForm = useForm<EditConfigurationForm>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      calculatedByEpix: calculatedByEpix,
      hasCalendarManagement: hasCalendarManagement,
      hasDocumentManagement: hasDocumentManagement,
      hasPayComponentManagement: hasPayComponentManagement,
      hasSelfService: hasSelfService,
      hasCompanyCars: hasCompanyCars,
      hasSmartPayComponents: hasSmartPayComponents,
      hasEpixCopilot: hasEpixCopilot,
      allowActivatingDefaultProviders: allowActivatingDefaultProviders,
      activePeopleLimit: activePeopleLimit?.toString(),
      providerLimit: providerLimit?.toString(),
      adminLimit: adminLimit?.toString(),
      tenantOwner: tenantOwner
    },
    resolver: zodResolver(editConfigurationForm)
  })

  async function handleOnSubmit(formData: EditConfigurationForm) {
    await updatemutation
      .mutateAsync({
        updateTenantConfigurationCommand: {
          calculatedByEpix: formData.calculatedByEpix,
          hasCalendarManagement: formData.hasCalendarManagement,
          hasCompanyCars: formData.hasCompanyCars,
          hasDocumentManagement: formData.hasDocumentManagement,
          hasPayComponentManagement: formData.hasPayComponentManagement,
          hasSelfService: formData.hasSelfService,
          hasSmartPayComponents: formData.hasSmartPayComponents,
          hasEpixCopilot: formData.hasEpixCopilot,
          allowActivatingDefaultProviders: formData.allowActivatingDefaultProviders,
          activePeopleLimit: formData.activePeopleLimit,
          providerLimit: formData.providerLimit,
          adminLimit: formData.adminLimit,
          tenantOwner: formData.tenantOwner
        }
      })
      .then(() => {
        refetchFeatures()
      })
  }

  const { control } = featuresForm
  const watchCalendarManagement = useWatch({
    control,
    name: 'hasCalendarManagement'
  })

  return (
    <>
      <Typography variant="h3" color="textSecondary">
        {t('tenantconfigurationpage.tenantfeatures.title')}
      </Typography>
      <FormContainer sx={{ width: '100%' }} form={featuresForm} onSubmit={featuresForm.handleSubmit(handleOnSubmit)}>
        <Grid container>
          <FormGridLayout item xs={6} paddingRight={2}>
            <FormSwitch
              sx={12}
              name="calculatedByEpix"
              onChange={e => {
                if (e) {
                  featuresForm.resetField('hasPayComponentManagement', {
                    defaultValue: true
                  })
                  featuresForm.resetField('hasCalendarManagement', { defaultValue: true })
                  featuresForm.resetField('hasSelfService', { defaultValue: true })
                }
              }}
              label={t('form.field.payrollviaepix')}
            />

            <FormSwitch sx={12} name="hasPayComponentManagement" label={t('form.field.paycomponentmanagement')} />

            <FormSwitch
              sx={12}
              name="hasCalendarManagement"
              onChange={e => {
                if (!e) {
                  featuresForm.resetField('hasSelfService', { defaultValue: false })
                }
              }}
              label={t('form.field.calendarmanagement')}
            />

            <Box ml={8}>
              <FormSwitch
                sx={12}
                disabled={!watchCalendarManagement}
                name="hasSelfService"
                label={t('form.field.selfservice.hasselfservice')}
              />
            </Box>

            <FormSwitch sx={12} name="hasCompanyCars" label={t('form.field.hascompanycars')} />

            <FormSwitch sx={12} name="hasDocumentManagement" label={t('form.field.hasdocumentmanagement')} />

            {getFeatureFlags?.features.smartPayComponents && (
              <FormSwitch sx={12} name="hasSmartPayComponents" label={t('form.field.hassmartpaycomponents')} />
            )}

            <FormSwitch sx={12} name="hasEpixCopilot" label={'Epix Copilot'} />

            <FormSwitch
              sx={12}
              name="allowActivatingDefaultProviders"
              label={t('form.field.allowactivatingdefaultproviders')}
            />

            <FormSelect sx={12} name="tenantOwner" label={t('form.field.tenantowner')} options={tenantOwnerOptions} />
          </FormGridLayout>

          <Grid item xs={6}>
            <FormGridLayout item>
              <FormInput sx={12} name="activePeopleLimit" label={t('form.field.activepeoplelimit')} />
              <FormInput sx={12} name="providerLimit" label={t('form.field.providerlimit')} />
              <FormInput sx={12} name="adminLimit" label={t('form.field.adminlimit')} />
            </FormGridLayout>
          </Grid>
        </Grid>
        <FormErrorList />
        <FormActionButtons isMutating={updatemutation.isLoading} />
      </FormContainer>
    </>
  )
}
