import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import {
  DEFAULT_LOCALE,
  OrderDirection,
  useGetContractCorrectionsByProcessIdQuery,
  useGetPayrollClosureProcessQuery
} from '@epix-web-apps/core'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { DataTable, usePaginationModel, SortModel } from '../../../data-table'
import DataTableEmpty from '../../../data-table/data-table-empty/data-table-empty'
import DoneIcon from '@mui/icons-material/Done'
import { minWidthPayrollClosure } from '../steps'
import { StepParams } from '../../generic-steps'
import DataExceedsPagesize from '../data-exceeds-pagesize'

/* eslint-disable-next-line */
export interface ContractCorrectionsComponentProps {}

export function ContractCorrectionsComponent(props: ContractCorrectionsComponentProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useParams<StepParams>()
  const [rowCount, setRowCount] = useState(0)
  const { paginationModel } = usePaginationModel(0, 101)
  const [tableData, setTableData] = useState<GridRowsProp>()

  const { data: getPayrollClosureProcess } = useGetPayrollClosureProcessQuery(
    {
      id: params?.id || ''
    },
    {
      enabled: !!params?.id
    }
  )

  const { data: contractCorrections, isLoading: isLoadingContractCorrections } =
    useGetContractCorrectionsByProcessIdQuery(
      {
        processId: getPayrollClosureProcess?.payrollClosureProcess.id || '',
        offset: paginationModel.offset,
        limit: paginationModel.pageSize
      },
      {
        suspense: false
      }
    )

  const columns: GridColDef[] = [
    {
      field: 'version',
      headerName: t('personcontractdata.datatable.column.version'),
      flex: 200,
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employerNumber',
      headerName: t('personcontractdata.datatable.column.employernumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employeeNumber',
      headerName: t('personcontractdata.datatable.column.employeenumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'durationTypeKey',
      headerName: t('personcontractdata.datatable.column.durationtypekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'durationType',
      headerName: t('personcontractdata.datatable.column.durationtype'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'specificDurationType',
      headerName: t('personcontractdata.datatable.column.specificdurationtype'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'startDate',
      headerName: t('personcontractdata.datatable.column.startdate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'probationEndDate',
      headerName: t('personcontractdata.datatable.column.probationenddate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'fixedTermEndDate',
      headerName: t('personcontractdata.datatable.column.fixedtermenddate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'effectiveEndDate',
      headerName: t('personcontractdata.datatable.column.effectiveenddate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'reasonEndDateKey',
      headerName: t('personcontractdata.datatable.column.reasonenddatekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'reasonEndDate',
      headerName: t('personcontractdata.datatable.column.reasonenddate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'specificReasonEndDate',
      headerName: t('personcontractdata.datatable.column.specificreasonenddate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'specificReasonEndDateName',
      headerName: t('personcontractdata.datatable.column.specificreasonenddatename'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'payGroupCode',
      headerName: t('personcontractdata.datatable.column.paygroupcode'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'payGroup',
      headerName: t('personcontractdata.datatable.column.paygroup'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employeeTypeKey',
      headerName: t('personcontractdata.datatable.column.employeetypekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employeeType',
      headerName: t('personcontractdata.datatable.column.employeetype'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'specificEmployeeType',
      headerName: t('personcontractdata.datatable.column.specificemployeetype'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'populationTypeKey',
      headerName: t('personcontractdata.datatable.column.populationtypekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'populationType',
      headerName: t('personcontractdata.datatable.column.populationtype'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'specificPopulationTypeCode',
      headerName: t('personcontractdata.datatable.column.specificpopulationtypecode'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'specificPopulationType',
      headerName: t('personcontractdata.datatable.column.specificpopulationtype'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'bankAccountHolder',
      headerName: t('personcontractdata.datatable.column.bankaccountholder'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'internationalBankAccount',
      headerName: t('personcontractdata.datatable.column.internationalbankaccount'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'bankIdentierCode',
      headerName: t('personcontractdata.datatable.column.bankidentiercode'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'bankSortCode',
      headerName: t('personcontractdata.datatable.column.banksortcode'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'branchUnitCode',
      headerName: t('personcontractdata.datatable.column.branchunitcode'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'branchUnit',
      headerName: t('personcontractdata.datatable.column.branchunit'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'department',
      headerName: t('personcontractdata.datatable.column.department'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'costCenter',
      headerName: t('personcontractdata.datatable.column.costcenter'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'fte',
      headerName: t('personcontractdata.datatable.column.fte'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'workPermitKey',
      headerName: t('personcontractdata.datatable.column.workpermitkey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'workPermitKeyStartDate',
      headerName: t('personcontractdata.datatable.column.workpermitkeystartdate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'workPermitEndDate',
      headerName: t('personcontractdata.datatable.column.workpermitenddate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'notes',
      headerName: t('personcontractdata.datatable.column.notes'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    }
  ]

  const [sortModel, _] = useState(new SortModel(columns?.[1]?.field, OrderDirection.Asc))

  useEffect(() => {
    if (contractCorrections && contractCorrections?.contractCorrectionsByProcessId) {
      const contractCorrectionsData = contractCorrections.contractCorrectionsByProcessId
      setTableData(
        contractCorrectionsData.data.map((row: any, index: number) => {
          return {
            id: index,
            version: row.version,
            employeeNumber: row.employeeNumber,
            employerNumber: row.employerNumber,
            durationType: row.durationType?.value,
            durationTypeKey: row.durationType?.key,
            specificDurationType: row.icpDurationTypeName,
            startDate: new Date(row.startDate).toLocaleDateString(DEFAULT_LOCALE),
            probationEndDate: row.probationEndDate
              ? new Date(row.probationEndDate).toLocaleDateString(DEFAULT_LOCALE)
              : null,
            fixedTermEndDate: row.fixedTermEndDate
              ? new Date(row.fixedTermEndDate).toLocaleDateString(DEFAULT_LOCALE)
              : null,
            effectiveEndDate: row.effectiveEndDate
              ? new Date(row.effectiveEndDate).toLocaleDateString(DEFAULT_LOCALE)
              : null,
            reasonEndDateKey: row.reasonEndDate?.key,
            reasonEndDate: row.reasonEndDate?.value,
            specificReasonEndDate: row.icpReasonEndDateCode,
            specificReasonEndDateName: row.icpReasonEndDateName,
            payGroupCode: row.paygroupCode,
            payGroup: row.paygroup,
            employeeTypeKey: row.employeeType?.key,
            employeeType: row.employeeType?.value,
            specificEmployeeType: row.icpEmployeeTypeName,
            populationTypeKey: row.populationType?.key,
            populationType: row.populationType?.value,
            specificPopulationTypeCode: row.icpPopulationTypeCode,
            specificPopulationType: row.icpPopulationTypeName,
            bankAccountHolder: row.bankInformation.accountHolder,
            internationalBankAccount: row.bankInformation.iban,
            bankIdentierCode: row.bankInformation.bic,
            bankSortCode: row.bankInformation.bankSortCode,
            branchUnitCode: row.branchUnitCode,
            branchUnit: row.branchUnit,
            departement: row.departement,
            costCenter: row.costCenter,
            fte: row.ftePercentage,
            workPermitKey: row.workPermit?.key,
            workPermitKeyStartDate: row.workPermitStartDate
              ? new Date(row.workPermitStartDate).toLocaleDateString(DEFAULT_LOCALE)
              : null,
            workPermitEndDate: row.workPermitEndDate
              ? new Date(row.workPermitEndDate).toLocaleDateString(DEFAULT_LOCALE)
              : null,
            notes: row.notes
          }
        })
      )
      setRowCount(contractCorrectionsData.totalCount)
    }
  }, [contractCorrections])

  return (
    <>
      {rowCount === paginationModel.pageSize && <DataExceedsPagesize />}
      <DataTable
        data={tableData}
        columns={columns}
        isLoading={isLoadingContractCorrections}
        sortModel={sortModel}
        hideFooter={true}
        emptyStateElement={
          <DataTableEmpty
            title={t('emptystate.process.contract')}
            icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
          />
        }
      />
    </>
  )
}

export default ContractCorrectionsComponent
