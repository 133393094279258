import { GraphqlError } from '@epix-web-apps/core'
import { FormHelperText, Grid } from '@mui/material'
import { FieldError, FieldErrors, useFormContext } from 'react-hook-form'

/* eslint-disable-next-line */
export interface FormErrorListProps {
  customErrors?: FieldErrors<object> | Array<GraphqlError> | Array<FieldError | GraphqlError | undefined>
}

const displayErrors = (inputErrors: FieldErrors<object> | Array<GraphqlError> | Array<FieldError | GraphqlError>) => {
  if (!inputErrors) {
    return
  }
  const errorsToDisplay = Object.entries(inputErrors).flatMap(([type, error]) => {
    if (error.extensions) {
      // graphql error
      const errors: Array<string> = []
      for (let index = 0; index < Object.keys(error.extensions).length; index++) {
        const values = Object.values(error.extensions)[index] as Array<string>
        if (Array.isArray(values)) {
          values.forEach(value => {
            errors.push(value)
          })
        } else {
          return values
        }
      }
      return errors
    }
    return error.message
  })

  return errorsToDisplay?.map((message, index) => (
    <FormHelperText key={index} error>
      {message ? message : <span></span>}
    </FormHelperText>
  ))
}

export function FormErrorList({ customErrors }: FormErrorListProps) {
  const form = useFormContext()
  return (
    <Grid container direction={'column'} paddingBottom={2}>
      {displayErrors(form?.formState?.errors)}
      {customErrors && displayErrors(customErrors)}
    </Grid>
  )
}

export default FormErrorList
