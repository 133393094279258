import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'
import { useGetPhocusContractDetailsQuery } from '@epix-web-apps/core'
import { IconButton, Box } from '@mui/material'
import { DetailBox, DetailBoxRow } from '@epix-web-apps/ui'
import { useFlyIn } from '@epix-web-apps/ui'
import { EditPhocusTaxes } from './edit-phocus-taxes'
import { EditPhocusContractType } from './edit-phocus-contract-type'
import { EditPhocusEmployment } from './edit-phocus-employment'

/* eslint-disable-next-line */
export interface NlPhocusProps {
  contractId: string
}

export function NlPhocus({ contractId }: NlPhocusProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()

  const { data: partnerSpecificDetails } = useGetPhocusContractDetailsQuery({
    contractId: contractId || ''
  })

  const partnerTaxes = partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailTaxesModel
  const partnerContractType =
    partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailContractTypeModel
  const partnerEmployment = partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailEmploymentModel
  return (
    <Box>
      <DetailBox
        title={t('phocusdetailpage.tax.title')}
        actionIcon={
          <IconButton
            size="small"
            onClick={() =>
              openFlyIn({
                content: <EditPhocusTaxes contractId={contractId} />
              })
            }
          >
            <EditIcon />
          </IconButton>
        }
      >
        <DetailBoxRow>
          <p>
            {partnerTaxes?.wageTaxCredit ? t('form.field.wagetaxcreditapply') : t('form.field.wagetaxcreditnotapply')}
          </p>
        </DetailBoxRow>

        {partnerTaxes?.annualWageSpecialRate && (
          <DetailBoxRow>
            <p>
              {t('form.field.annualwagespecialrate')}:
              <span className="bold"> {partnerTaxes.annualWageSpecialRate}</span>
            </p>
          </DetailBoxRow>
        )}

        {partnerTaxes?.annualWage && (
          <DetailBoxRow>
            <p>
              {t('phocusdetailpage.tax.annualwage')}:<span className="bold"> {partnerTaxes.annualWage}</span>
            </p>
          </DetailBoxRow>
        )}
      </DetailBox>

      <DetailBox
        title={t('phocusdetailpage.contracttype.title')}
        actionIcon={
          <IconButton
            size="small"
            onClick={() =>
              openFlyIn({
                content: <EditPhocusContractType contractId={contractId} />
              })
            }
          >
            <EditIcon />
          </IconButton>
        }
      >
        <DetailBoxRow>
          <p>
            {partnerContractType?.writtenContract ? t('form.field.writtencontract') : t('form.field.nowrittencontract')}
          </p>
        </DetailBoxRow>
      </DetailBox>

      <DetailBox
        title={t('phocusdetailpage.employment.title')}
        actionIcon={
          <IconButton
            size="small"
            onClick={() =>
              openFlyIn({
                content: <EditPhocusEmployment contractId={contractId} />
              })
            }
          >
            <EditIcon />
          </IconButton>
        }
      >
        <DetailBoxRow>
          <p>
            {partnerEmployment?.isEmploymentTerminated
              ? t('form.field.employmentterminated')
              : t('form.field.employmentnotterminated')}
          </p>
        </DetailBoxRow>
      </DetailBox>
    </Box>
  )
}

export default NlPhocus
