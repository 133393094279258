import { Box, Button, IconButton, MenuItem, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Configuration, useFlyIn } from '@epix-web-apps/ui'
import { DetailPageBaseQueryParams } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { HeaderTitleNavigation } from '@epix-web-apps/ui'
import AddEditPaygroup from '../../components/employer-components/add-edit-paygroup/add-edit-paygroup'
import {
  OrderDirection,
  useGetAllCountriesQuery,
  useGetAllPayGroupsByEmployerIdQuery,
  useGetEmployerByIdQuery,
  useGetIcpByIdQuery
} from '@epix-web-apps/core'
import { ScrollableDataTable, SortModel } from '../../components/data-table'
import DropdownMenu from '../../components/dropdown-menu'

/* eslint-disable-next-line */
export interface PayGroupsPageProps {}

export type PayGroupsDetailPageParams = DetailPageBaseQueryParams & {
  employerid: string
}

export function PayGroupsPage(props: PayGroupsPageProps) {
  const [tableData, setTableData] = useState<GridRowsProp>()
  const { t } = useTranslation()
  const params = useParams<PayGroupsDetailPageParams>()
  const employerId = params.employerid!
  const icpId = params.id!
  const { openFlyIn } = useFlyIn()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [payGroupId, setPayGroupId] = useState('')

  const rowActions = (rowParams: GridRenderCellParams) => (
    <>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
          setPayGroupId(rowParams.row.id)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <IconButton aria-label="row details" aria-controls="details-row"></IconButton>
    </>
  )

  const { data: getEmployerById } = useGetEmployerByIdQuery({
    employerId: employerId
  })
  const { data: getIcpById } = useGetIcpByIdQuery({
    icpId: icpId
  })
  const { data: getCountries } = useGetAllCountriesQuery()
  const code = getIcpById?.icpById.code
  const country = getCountries?.countries.find(c => c.code.includes(code ? code : ''))?.name
  const payrollProvider = getIcpById?.icpById.payrollProvider
  const company = getEmployerById?.employerById.companyName
  const filterBar = (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: [2],
          paddingLeft: [2],
          paddingRight: [2]
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERS(`${params.id}`)}
          title={t('paygroupspage.title')}
        />
        <Button
          variant="contained"
          onClick={() =>
            openFlyIn({
              content: <AddEditPaygroup employerId={employerId} />,
              callbackAfterClose: () => refetchAllPayGroups
            })
          }
        >
          <AddIcon />
          {t('paygroupspage.button.add-payrollcode')}
        </Button>
      </Box>
      <Typography color={'gray'} variant="h3" paddingLeft={2}>
        {country} - {payrollProvider} - {company}
      </Typography>
    </Box>
  )

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: t('paygroupspage.datatable.column.code'),
      flex: 1,
      sortable: true
    },
    {
      field: 'name',
      headerName: t('paygroupspage.datatable.column.description'),
      flex: 1
    },
    //  { field: 'collectivecalendar', headerName: t('paygroupspage.datatable.column.collective-calendar'), flex: 1 },
    {
      field: 'datefirstclosure',
      headerName: t('paygroupspage.datatable.column.date-first-closure'),
      flex: 1
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns?.[0].field, OrderDirection.Asc))

  const {
    data: payGroupsData,
    isLoading: isLoadingPayGroups,
    refetch: refetchAllPayGroups
  } = useGetAllPayGroupsByEmployerIdQuery(
    {
      employerId: employerId ?? '',
      sortByProperty: sortModel.field,
      orderDirection: sortModel.orderDirection
    },
    {
      suspense: false
    }
  )

  useEffect(() => {
    if (payGroupsData && payGroupsData.allPayGroupsByEmployerId) {
      const payGroups = payGroupsData.allPayGroupsByEmployerId
      setTableData(
        payGroups.map(row => {
          return {
            id: row.id,
            code: row.code,
            name: row.name,
            datefirstclosure: new Date(row.firstClosure).toLocaleDateString()
          }
        })
      )
    }
  }, [payGroupsData])

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        isLoading={isLoadingPayGroups}
        sortModel={sortModel}
        onSortChange={setSortModel}
        filterBarElement={filterBar}
        hideFooter={true}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <AddEditPaygroup payGroupId={payGroupId} employerId={employerId} />,
              callbackAfterClose: () => refetchAllPayGroups
            })
          }
        >
          {t('paygroups.list.row.menu.edit-paygroup')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default PayGroupsPage
