import { Navigate, Route } from 'react-router-dom'
import AnalyticsPage from './myepix/pages/analytics-page'
import { ContractsDetailPage } from './myepix/pages/contracts-detail-page'
import ContractsPage from './myepix/pages/contracts-page'
import IcpProvidersPage from './myepix/pages/icp-providers-page'
import LabourCostAnalyticsPage from './myepix/pages/labour-cost-analytics-page'
import PeopleDetailPage from './myepix/pages/people-detail-page'
import PeoplePage from './myepix/pages/people-page'
import UsersPage from './myepix/pages/users-page'
import PayrollCodesPage from './myepix/pages/payroll-codes-page'
import ImportDataPage from './myepix/pages/import-data-page'
import ImportStatusdataPage from './myepix/pages/import-status-page'
import DayDefinitionsPage from './myepix/pages/day-definitions-page'
import DayDefinitionEntriesPage from './myepix/pages/day-definition-entries-page'
import CollectiveCalendarPage from './myepix/pages/collective-calendar-page'
import CollectiveCalendarDaysPage from './myepix/pages/collective-calendar-days-page'
import WorkschedulePage from './myepix/pages/workschedule-page'
import PayGroupsPage from './myepix/pages/pay-groups-page/pay-groups-page'
import UserDetailPage from './myepix/pages/user-detail-page'
import BranchUnitPage from './myepix/pages/branch-unit-page'
import { FEATURE, Module, ROLE } from '@epix-web-apps/core'
import ProcessPage from './myepix/pages/process-page'
import ProcessDetailPage from './myepix/pages/process-detail-page'
import ValidationErrorsPage from './myepix/pages/validation-errors-page'
import PersonValidationErrorsPage from './myepix/pages/person-validation-errors-page'
import RolesPage from './myepix/pages/roles-page'
import TeamsPage from './myepix/pages/teams-page'
import TeamsDetailPage from './myepix/pages/teams-detail-page'
import CarCategoriesPage from './myepix/pages/car-categories-page'
import OrganisationPage from './myepix/pages/organisation-page'
import CompanyCarDetailPage from './myepix/pages/company-car-detail-page/company-car-detail-page'
import RequestPolicyPage from './myepix/pages/request-policy-page/request-policy-page'
import { RequestPolicySettings } from './myepix/components/request-policy-components'
import PeopleDetailSidebar from './myepix/components/people-detail-sidebar'
import CompanyCarsPage from './myepix/pages/company-cars-page/company-cars-page'
import ExternalCodesPage from './myepix/pages/external-codes-page/external-codes-page'
import FunctionLevelsPage from './myepix/pages/function-levels-page'
import FunctionAreasPage from './myepix/pages/function-areas-page/function-areas-page'
import EmployersPage from './myepix/pages/employers-page/employers-page'
import HomePage from './myepix/pages/home-page'
import ShiftCodesPage from './myepix/pages/shift-codes-page'
import AiCompanionPage from './myepix/pages/ai-companion-page'
import {
  AiCompanion,
  CompanyCar,
  Configuration,
  Import,
  People,
  Process,
  ValidationErrors,
  Documents,
  Documentation,
  IsAuthorized,
  useRouteDefinitions,
  GeneralRoutes
} from '@epix-web-apps/ui'
import CompanyDocumentTypesPage from './myepix/pages/company-documenttypes-page'
import ProductivityAnalyticsPage from './myepix/pages/productivity-analytics-page/productivity-analytics-page'
import { DocumentsPage } from './myepix/pages/documents-page'
import ValidationDocsPage from './myepix/pages/validation-docs-page'
import { TenantConfigurationPage } from './myepix/pages/tenant-configuration-page'
import SmartPayComponentsPage from './myepix/pages/smart-pay-components-page/smart-pay-components-page'
import { SmartPayComponentSettings } from './myepix/components/smart-pay-component-components'

export const useMyEpixRouting = () => {
  const { analyticsRoutes } = useRouteDefinitions()
  return (
    <>
      <Route path="/" element={<Navigate to={GeneralRoutes.ROOT} replace />} />
      <Route path={GeneralRoutes.ROOT} index element={<HomePage />} />
      <Route
        path={Configuration.TENANT_CONFIGURATION()}
        element={
          <IsAuthorized roles={[ROLE.TENANTADMIN]}>
            <TenantConfigurationPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Process.PROCESSES()}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGETASKSANDPROCESSES]}>
            <ProcessPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Process.PROCESSES_TYPE(':type')}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGETASKSANDPROCESSES]}>
            <ProcessDetailPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Process.PROCESSES_TYPE_ID(':type', ':id')}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGETASKSANDPROCESSES]}>
            <ProcessDetailPage />
          </IsAuthorized>
        }
      />
      <Route
        path={ValidationErrors.VALIDATION_ERROR()}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.VIEWVALIDATIONERRORS]}>
            <ValidationErrorsPage />
          </IsAuthorized>
        }
      />
      <Route
        path={People.PEOPLE()}
        element={
          <IsAuthorized roles={[ROLE.MANAGEPEOPLECONTRACTS, ROLE.ADMIN]}>
            <PeoplePage />
          </IsAuthorized>
        }
      />
      <Route
        path={People.PEOPLE_ID(':id')}
        element={
          <IsAuthorized roles={[ROLE.MANAGEPEOPLECONTRACTS, ROLE.ADMIN]}>
            <PeopleDetailSidebar />
          </IsAuthorized>
        }
      >
        <Route
          path={People.PEOPLE_ID_DETAILS(':id')}
          element={
            <IsAuthorized roles={[ROLE.MANAGEPEOPLECONTRACTS, ROLE.ADMIN]}>
              <PeopleDetailPage />
            </IsAuthorized>
          }
        />
        <Route path={People.PEOPLE_ID_VALIDATION_ERRORS(':id')} element={<PersonValidationErrorsPage />} />
        <Route
          path={People.PEOPLE_ID_CONTRACTS(':id')}
          element={
            <IsAuthorized roles={[ROLE.MANAGEPEOPLECONTRACTS, ROLE.ADMIN]}>
              <ContractsPage />
            </IsAuthorized>
          }
        />
        <Route
          path={People.PEOPLE_ID_ORGANISATION(':id')}
          element={
            <IsAuthorized roles={[ROLE.MANAGETEAMSANDROLES, ROLE.ADMIN]}>
              <OrganisationPage />
            </IsAuthorized>
          }
        />
        <Route
          path={People.PEOPLE_ID_CONTRACTS_ID(':id', ':contractid')}
          element={
            <IsAuthorized roles={[ROLE.MANAGEPEOPLECONTRACTS, ROLE.ADMIN]}>
              <ContractsDetailPage />
            </IsAuthorized>
          }
        />
        <Route
          path={People.PEOPLE_ID_CONTRACTS_ID_DOCUMENTTYPE_ID(':id', ':contractid', ':documenttypeid')}
          element={<ContractsDetailPage />}
        />
      </Route>
      <Route path={analyticsRoutes.ROOT} element={<Navigate to={analyticsRoutes.WORKFORCE} replace />} />
      <Route
        path={analyticsRoutes.WORKFORCE}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.VIEWWORKFORCEANALYTICS]}>
            <AnalyticsPage />
          </IsAuthorized>
        }
      />
      <Route
        path={analyticsRoutes.LABOUR_COST}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.VIEWLABOURCOSTANALYTICS]}>
            <LabourCostAnalyticsPage />
          </IsAuthorized>
        }
      />
      <Route
        path={analyticsRoutes.PRODUCTIVITY}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.VIEWPRODUCTIVITYANALYTICS]}>
            <ProductivityAnalyticsPage />
          </IsAuthorized>
        }
      />
      <Route
        path={CompanyCar.COMPANY_CAR}
        element={
          <IsAuthorized roles={[ROLE.MANAGECOMPANYCARS, ROLE.ADMIN]}>
            <CompanyCarsPage />
          </IsAuthorized>
        }
      />
      <Route path={CompanyCar.ID(':id')} element={<CompanyCarDetailPage />} />
      <Route
        path={Import.IMPORT}
        element={
          <IsAuthorized roles={[ROLE.IMPORTDATA, ROLE.ADMIN]}>
            <ImportStatusdataPage />
          </IsAuthorized>
        }
      />
      <Route
        path={AiCompanion.AICOMPANION}
        element={
          <IsAuthorized roles={[ROLE.AICOMPANION, ROLE.ADMIN]} feature={FEATURE.AICOMPANION}>
            <AiCompanionPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Import.DATA}
        element={
          <IsAuthorized roles={[ROLE.IMPORTDATA, ROLE.ADMIN]}>
            <ImportDataPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Documents.DOCUMENTS}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGEEMPLOYERDOCUMENTTYPES]} module={Module.Myepixdocuments}>
            <DocumentsPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Documents.DOCUMENTS_ICP_ID_EMPLOYER_ID_DOCUMENTTYPE_ID(':icpid', ':employerid', ':documenttypeid')}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGEEMPLOYERDOCUMENTTYPES]} module={Module.Myepixdocuments}>
            <DocumentsPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.ICP_PROVIDERS()}
        element={
          <IsAuthorized roles={[ROLE.MANAGECONFIGURATION, ROLE.ADMIN]}>
            <IcpProvidersPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.USERS()}
        element={
          <IsAuthorized roles={[ROLE.MANAGEUSERSANDPOLICIES, ROLE.ADMIN]}>
            <UsersPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.CAR_CATEGORIES()}
        element={
          <IsAuthorized roles={[ROLE.ADMIN]}>
            <CarCategoriesPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.FUNCTION_AREAS()}
        element={
          <IsAuthorized roles={[ROLE.MANAGELISTS, ROLE.ADMIN]}>
            <FunctionAreasPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.FUNCTION_LEVELS()}
        element={
          <IsAuthorized roles={[ROLE.MANAGELISTS, ROLE.ADMIN]}>
            <FunctionLevelsPage />
          </IsAuthorized>
        }
      />
      <Route path={Configuration.ROLES()} element={<RolesPage />} />
      <Route
        path={Configuration.TEAMS()}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGETEAMSANDROLES]}>
            <TeamsPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.TEAMS_ID(':id')}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGETEAMSANDROLES]}>
            <TeamsDetailPage />
          </IsAuthorized>
        }
      />
      <Route path={Configuration.USERS_ID(':id')} element={<UserDetailPage />} />
      <Route
        path={Configuration.ICP_PROVIDERS_ID_EMPLOYERS(':id')}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGECONFIGURATION]}>
            <EmployersPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_PAYROLLCODES(':id', ':employerid')}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGECONFIGURATION]}>
            <PayrollCodesPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_SMART_PAYCOMPONENTS(':id', ':employerid')}
        element={<SmartPayComponentsPage />}
      />
      <Route
        path={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_SMART_PAYCOMPONENTS_ID(
          ':id',
          ':employerid',
          ':smartpaycomponentid'
        )}
        element={<SmartPayComponentSettings />}
      />
      <Route
        path={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_SHIFTCODES(':id', ':employerid')}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGECONFIGURATION]}>
            <ShiftCodesPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_PAYGROUPS(':id', ':employerid')}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGECONFIGURATION]}>
            <PayGroupsPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_DAYDEFINITIONS(':id', ':employerid')}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGECONFIGURATION]}>
            <DayDefinitionsPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_COLLECTIVE_CALENDAR(':id', ':employerid')}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGECONFIGURATION]}>
            <CollectiveCalendarPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_WORKSCHEDULE(':id', ':employerid')}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGECONFIGURATION]}>
            <WorkschedulePage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_BRANCHUNIT(':id', ':employerid')}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGECONFIGURATION]}>
            <BranchUnitPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_REQUESTPOLICIES(':id', ':employerid')}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGECONFIGURATION]}>
            <RequestPolicyPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_DOCUMENTS(':id', ':employerid')}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGECONFIGURATION]}>
            <CompanyDocumentTypesPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_REQUESTPOLICIES_ID(':id', ':employerid', ':requestpolicyid')}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGECONFIGURATION]}>
            <RequestPolicySettings />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_COLLECTIVE_CALENDAR_ID(
          ':id',
          ':employerid',
          ':collectivecalendarid'
        )}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGECONFIGURATION]}>
            <CollectiveCalendarDaysPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_DAYDEFINITION_ID_ENTRIES(
          ':id',
          ':employerid',
          ':daydefinitionid'
        )}
        element={
          <IsAuthorized roles={[ROLE.ADMIN, ROLE.MANAGECONFIGURATION]}>
            <DayDefinitionEntriesPage />
          </IsAuthorized>
        }
      />
      <Route
        path={Configuration.ICP_PROVIDERS_EXTERNAL_CODES()}
        element={
          <IsAuthorized roles={[ROLE.MANAGELISTS, ROLE.ADMIN]}>
            <ExternalCodesPage />
          </IsAuthorized>
        }
      />
      <Route path={Documentation.VALIDATIONS} element={<ValidationDocsPage />} />
    </>
  )
}
