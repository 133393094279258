import { useTranslation } from 'react-i18next'
import { StepProps } from '../../generic-steps'
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { Box, Button, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  OrderDirection,
  PROCESS_PAYROLLCLOSURE_PARAMETER,
  useGetAllValidPayComponentCorrectionsByProcessIdQuery,
  useGetMeQuery,
  useGetPagedOriginalPayComponentsByProcessIdQuery,
  useGetPayrollClosureProcessQuery
} from '@epix-web-apps/core'
import { DataTable, usePaginationModel, ScrollableDataTable, SortModel } from '../../../data-table'
import DataTableEmpty from '../../../data-table/data-table-empty/data-table-empty'
import DoneIcon from '@mui/icons-material/Done'

export function SalaryData({ processId }: StepProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const [rowCount, setRowCount] = useState(0)
  const [rowCountCorrections, setRowCountCorrections] = useState(0)
  const { paginationModel, setPaginationModel, resetPaginationModel } = usePaginationModel()
  const [currencyRateHeader, setCurrencyRateHeader] = useState<string>()
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    valueRate: true,
    validFrom: false
  })

  const { data: me } = useGetMeQuery()

  const { data: getPayrollClosureProcess } = useGetPayrollClosureProcessQuery(
    {
      id: processId
    },
    {
      enabled: !!processId
    }
  )

  const {
    data: originalPayComponents,
    isLoading,
    refetch
  } = useGetPagedOriginalPayComponentsByProcessIdQuery(
    {
      processId: processId,
      offset: paginationModel.offset,
      limit: paginationModel.pageSize
    },
    {
      suspense: false
    }
  )

  const {
    data: changedPayComponentsCorrections,
    isLoading: isLoadingCorrections,
    refetch: refetchCorrections
  } = useGetAllValidPayComponentCorrectionsByProcessIdQuery(
    {
      offset: paginationModel.offset,
      limit: paginationModel.pageSize + 1,
      processId: getPayrollClosureProcess?.payrollClosureProcess.id || ''
    },
    {
      suspense: false
    }
  )

  const parameter = getPayrollClosureProcess?.payrollClosureProcess.parameterType?.key

  const [changeToCorrections, setChangeToCorrections] = useState(false)

  const valueActions = (rowParams: GridRenderCellParams) => (
    <p>
      {rowParams.row.value} {rowParams.row.sign}
    </p>
  )

  const valueRateActions = (rowParams: GridRenderCellParams) => (
    <p>
      {rowParams.row.valueRate} {me?.me.currencySymbol}
    </p>
  )

  const periodActions = (rowParams: GridRenderCellParams) => (
    <p>
      {rowParams.row.startDate} - {rowParams.row.endDate ? rowParams.row.endDate : '...'}
    </p>
  )

  const columns: GridColDef[] = [
    {
      field: 'employee',
      headerName: t('salarydata.datatable.column.employee'),
      flex: 1.5,
      sortable: false
    },
    {
      field: 'employeeNumber',
      headerName: t('salarydata.datatable.column.employeenumber'),
      flex: 1,
      sortable: false
    },
    {
      field: 'paycode',
      headerName: t('salarydata.datatable.column.paycode'),
      flex: 1,
      sortable: false
    },
    {
      field: 'payrollcodeName',
      headerName: t('salarydata.datatable.column.payrollcodename'),
      flex: 1,
      sortable: false
    },
    {
      field: 'payrollcodeUserFriendlyName',
      headerName: t('salarydata.datatable.column.payrollcodeuserfriendlyname'),
      flex: 1.5,
      sortable: false
    },
    {
      field: 'value',
      headerName: t('salarydata.datatable.column.value'),
      flex: 1,
      renderCell: valueActions,
      sortable: false,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'valueRate',
      headerName: currencyRateHeader,
      flex: 1,
      renderCell: valueRateActions,
      hideable: true,
      sortable: false,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'valueType',
      headerName: t('salarydata.datatable.column.valuetype'),
      flex: 1,
      sortable: false
    },
    {
      field: 'startDate',
      headerName: t('salarydata.datatable.column.period'),
      flex: 1.5,
      renderCell: periodActions,
      sortable: false
    }
  ]

  const correctionColumns: GridColDef[] = [
    {
      field: 'version',
      headerName: t('salarydata.datatable.column.version'),
      flex: 1,
      sortable: false
    },
    ...columns
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns?.[1]?.field, OrderDirection.Asc))
  const [tableData, setTableData] = useState<GridRowsProp>()
  const [tableDataCorrections, setTableDataCorrections] = useState<GridRowsProp>()

  useEffect(() => {
    if (changedPayComponentsCorrections && changedPayComponentsCorrections?.pagedPayComponentCorrectionsByProcessId) {
      const payComponents = changedPayComponentsCorrections.pagedPayComponentCorrectionsByProcessId
      setTableDataCorrections(
        payComponents.data.map(row => {
          return {
            id: `${row.version}-${row.id}`,
            version: row.version,
            employee: row.employeeName,
            employeeNumber: row.employeeNumber,
            paycode: row.providerCode,
            payrollcodeName: row.payrollLabel,
            payrollcodeUserFriendlyName: row.payrollFriendlyDescription,
            value: row.value,
            sign: row.sign,
            valueRate: row.valueRate,
            valueType: row.valueTypeValue,
            startDate: new Date(row.startDate).toLocaleDateString(),
            endDate: row.endDate ? new Date(row.endDate).toLocaleDateString() : null
          }
        })
      )
      setRowCountCorrections(payComponents.totalCount)
    }
  }, [changedPayComponentsCorrections])

  useEffect(() => {
    if (originalPayComponents && originalPayComponents?.pagedOriginalPayComponentsByProcessId) {
      setTableData(
        originalPayComponents?.pagedOriginalPayComponentsByProcessId.data.map(row => {
          return {
            id: row.id,
            employee: row.employeeName,
            employeeNumber: row.employeeNumber,
            paycode: row.providerCode,
            payrollcodeName: row.payrollLabel,
            payrollcodeUserFriendlyName: row.payrollFriendlyDescription,
            value: row.value,
            sign: row.sign,
            valueRate: row.valueRate,
            valueType: row.valueType,
            startDate: new Date(row.startDate).toLocaleDateString(),
            endDate: row.endDate ? new Date(row.endDate).toLocaleDateString() : null
          }
        })
      )
      setRowCount(originalPayComponents?.pagedOriginalPayComponentsByProcessId.totalCount)
    }
  }, [originalPayComponents])

  const showOriginalButton =
    parameter === PROCESS_PAYROLLCLOSURE_PARAMETER.ORIGINAL_CORRECTIONS_PERSONCONTRACT &&
    originalPayComponents?.pagedOriginalPayComponentsByProcessId.data &&
    originalPayComponents?.pagedOriginalPayComponentsByProcessId.data.length > 0
  const showCorrectionButton =
    (parameter === PROCESS_PAYROLLCLOSURE_PARAMETER.ORIGINAL_CORRECTIONS_PERSONCONTRACT ||
      parameter === PROCESS_PAYROLLCLOSURE_PARAMETER.CORRECTIONS_PERSONCONTRACT) &&
    changedPayComponentsCorrections?.pagedPayComponentCorrectionsByProcessId.data &&
    changedPayComponentsCorrections?.pagedPayComponentCorrectionsByProcessId.data.length > 0

  useEffect(() => {
    if (originalPayComponents?.pagedOriginalPayComponentsByProcessId) {
      if (originalPayComponents?.pagedOriginalPayComponentsByProcessId.data[0]?.icpCurrency === me?.me.currency) {
        setColumnVisibilityModel({ valueRate: false, validFrom: false })
        setCurrencyRateHeader('')
      } else {
        setColumnVisibilityModel({ valueRate: true, validFrom: false })
        setCurrencyRateHeader(me?.me.currencyName)
      }
    }
  }, [originalPayComponents, changedPayComponentsCorrections])

  useEffect(() => {
    refetch()
    refetchCorrections()
  }, [me?.me.currency])

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 1, marginBottom: 1 }}>
        {showOriginalButton && (
          <Button
            variant={!changeToCorrections ? 'contained' : 'outlined'}
            onClick={e => {
              resetPaginationModel()
              setChangeToCorrections(false)
            }}
          >
            {t('salarydata.datatable.button.original')}
          </Button>
        )}
        {showCorrectionButton && (
          <Button variant={changeToCorrections ? 'contained' : 'outlined'} onClick={e => {
            resetPaginationModel()
            setChangeToCorrections(true)
          }}>
            {t('salarydata.datatable.button.corrections')}
          </Button>
        )}
      </Box>

      {changeToCorrections ?
      <ScrollableDataTable
        data={tableDataCorrections}
        columns={correctionColumns}
        columnsToHide={columnVisibilityModel}
        totalRowCount={rowCountCorrections}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        isLoading={isLoadingCorrections}
        vHeightOffset="25rem"
        sortModel={sortModel}
        emptyStateElement={
          <DataTableEmpty
            title={t('emptystate.process.salary')}
            icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
          />
        }
      /> : <DataTable
      data={tableData}
      columns={columns}
      columnsToHide={columnVisibilityModel}
      totalRowCount={rowCount}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      hideFooter
      isLoading={isLoading}
      sortModel={sortModel}
      emptyStateElement={
        <DataTableEmpty
          title={t('emptystate.process.salary')}
          icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
        />
      }
    />}

    </Box>
  )
}

export default SalaryData
