import {
  getNumberOfColors,
  ToBackendFormatedDate,
  useGetPagedProductivityForPeopleQuery,
  useGetProductivityForPeopleByContractIdsQuery
} from '@epix-web-apps/core'
import { PageOptions, PagingNavigation, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { useProductivityAnalyticsPersistedStore } from '../../../stores/productivity-store'
import BarchartComponent from '../../analytics-components/barchart-component/barchart-component'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { usePaginationModel } from '../../data-table'

function ProductivityPersonTab() {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const store = useProductivityAnalyticsPersistedStore()
  const { paginationModel, setPaginationModel, resetPaginationModel } = usePaginationModel()

  useEffect(() => {
    resetPaginationModel()
  }, [icpFilters, store])

  const {
    data: getProductivityForPeople,
    isFetching,
    isFetched
  } = useGetPagedProductivityForPeopleQuery({
    viewDate: ToBackendFormatedDate(store.dateFilter ?? new Date()),
    icps: icpFilters,
    viewPeriodKey: store.viewPeriod,
    employerId: store.employerFilter,
    paygroupId: store.paygroupFilter,
    limit: paginationModel.pageSize,
    offset: paginationModel.offset
  })

  const { data: productivityForPeopleByContractId } = useGetProductivityForPeopleByContractIdsQuery(
    {
      startDate: getProductivityForPeople?.pagedProductivityForPeople.startDate,
      endDate: getProductivityForPeople?.pagedProductivityForPeople.endDate,
      contractIds: getProductivityForPeople?.pagedProductivityForPeople.data.map(d => d.contractId) ?? [],
      subgroupKeys: store.subgroupFilter
    },
    { enabled: isFetched, suspense: false, refetchOnWindowFocus: false }
  )

  const indexByKey = 'name'
  const keys = new Set(
    productivityForPeopleByContractId?.productivityForPeopleByContractIds.flatMap(p =>
      p.payrollGroupHours.flatMap(pgh => pgh.subGroupName)
    )
  )

  const data = getProductivityForPeople?.pagedProductivityForPeople.data.map(p => {
    const record: Record<string, any> = {}
    record[indexByKey] = `${p.name} - ${p.countryKey}`
    if (productivityForPeopleByContractId) {
      const prodForPerson = productivityForPeopleByContractId.productivityForPeopleByContractIds
        .filter(prodForPerson => prodForPerson.contractId === p.contractId)
        .flatMap(prod => prod.payrollGroupHours)
      prodForPerson.forEach(prod => {
        record[prod.subGroupName] = prod.amount
      })
    }
    return record
  })

  if (data?.length === 0) {
    return <Typography sx={{ p: 2, textAlign: 'center' }}>{t('analytics.nodata')}</Typography>
  }

  return (
    <>
      <BarchartComponent
        keys={Array.from(keys)}
        indexBy={indexByKey}
        // barchart has a bug that reverses the data
        data={data?.reverse()}
        groupMode="stacked"
        layoutMode="horizontal"
        colors={getNumberOfColors(keys.size)}
        marginRight={50}
        marginLeft={200}
        legendCharLength={25}
        legendWidth={175}
        showLegend={keys.size <= 5}
        axisBottom={t('barchartcomponent.component.axe.hours')}
      />

      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', mt: 1 }}>
        {getProductivityForPeople && (
          <PagingNavigation
            hasNext={getProductivityForPeople.pagedProductivityForPeople.hasNext}
            onNextClick={() => {
              paginationModel.page += 1
              setPaginationModel(paginationModel)
            }}
            hasPrevious={getProductivityForPeople.pagedProductivityForPeople.hasPrevious}
            onPreviousClick={() => {
              paginationModel.page -= 1
              setPaginationModel(paginationModel)
            }}
            isFetching={isFetching}
            pageOptions={
              new PageOptions(
                paginationModel.page,
                paginationModel.pageSize,
                getProductivityForPeople.pagedProductivityForPeople.data.length,
                getProductivityForPeople.pagedProductivityForPeople.totalCount
              )
            }
          />
        )}
      </Box>
    </>
  )
}

export default ProductivityPersonTab
