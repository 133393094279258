import {
  OrderDirection,
  useGetEmployerByIdQuery,
  useGetMeQuery,
  useGetSmartPayComponentByIdQuery,
  useGetSmartPayComponentHistoryByIdQuery,
  useNameof
} from '@epix-web-apps/core'
import {
  Configuration,
  DetailBox,
  DetailBoxRow,
  DetailPageBaseQueryParams,
  HeaderTitleNavigation,
  useFlyIn
} from '@epix-web-apps/ui'
import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AddEditSmartPayComponent } from '../add-edit-smart-pay-component'
import EditIcon from '@mui/icons-material/Edit'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useCallback, useEffect, useState } from 'react'
import AddSmartInputPayComponent from '../add-smart-input-pay-component/add-smart-input-pay-component'
import { DataTable, SortModel } from '../../data-table'
import { GridColDef } from '@mui/x-data-grid'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import React from 'react'
import DropdownMenu from '../../dropdown-menu'
import RemoveSmartInputCode from '../remove-smart-input-code/remove-smart-input-code'

type SmartPayComponentSettingsPageParams = DetailPageBaseQueryParams & {
  employerid: string
  smartpaycomponentid: string
}

type SmartPayInputCodeRow = {
  id: string
  payrollCode: string
  userFriendlyDescription: string
  defaultValue: number
}

export function SmartPayComponentSettings() {
  const { t } = useTranslation()
  const theme = useTheme()
  const { openFlyIn } = useFlyIn()
  const params = useParams<SmartPayComponentSettingsPageParams>()
  const employerId = params.employerid
  const { nameof } = useNameof<SmartPayInputCodeRow>()
  const [inputTableData, setInputTableData] = useState<SmartPayInputCodeRow[]>([])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [smartInputCodeId, setSmartInputCodeId] = useState('')


  const { data: me} = useGetMeQuery();
  const { data: getEmployerById } = useGetEmployerByIdQuery({
    employerId: employerId ?? ''
  })

  const { data: getSmartPayComponentById, refetch: refetchSmartPayComponentById } = useGetSmartPayComponentByIdQuery({
    id: params.smartpaycomponentid ?? ''
  })

  const [selectedHistory, setSelectedHistory] = useState(
    getSmartPayComponentById?.smartPayComponentById?.histories[0].id
  )

  useEffect(() => {
    if (getSmartPayComponentById) {
      setSelectedHistory(getSmartPayComponentById.smartPayComponentById?.histories[0].id)
    }
  }, [getSmartPayComponentById])

  const {
    data: getSmartPayComponentHistoryById,
    refetch: refetchSmartPayComponentHistoryById,
    isFetching: isFetchingHistory
  } = useGetSmartPayComponentHistoryByIdQuery(
    {
      id: selectedHistory ?? ''
    },
    {
      enabled: !!selectedHistory
    }
  )

  const rowActions = useCallback(
    (row: SmartPayInputCodeRow) => {
      return (
        <IconButton
          aria-label="row actions"
          aria-controls="menu-row"
          aria-haspopup="true"
          onClick={e => {
            setAnchorEl(e.currentTarget)
            setSmartInputCodeId(row.id)
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      )
    },
    [inputTableData]
  )

  useEffect(() => {
    if (getSmartPayComponentHistoryById) {
      const smartPayComponentHistoryId = getSmartPayComponentHistoryById.smartPayComponentHistoryById
      setInputTableData(
        smartPayComponentHistoryId.inputCodes.map(inputCode => {
          return {
            id: inputCode.id,
            payrollCode: inputCode.payrollCode,
            userFriendlyDescription: inputCode.userFriendlyDescription,
            defaultValue: inputCode.defaultValue
          }
        })
      )
    }
  }, [getSmartPayComponentHistoryById])

  const columns: GridColDef<SmartPayInputCodeRow>[] = [
    {
      field: nameof('payrollCode'),
      headerName: t('smartpaycomponentspage.datatable.column.code'),
      flex: 1,
      sortable: true
    },
    {
      field: nameof('userFriendlyDescription'),
      headerName: t('smartpaycomponentspage.datatable.column.description'),
      flex: 1
    },
    {
      field: nameof('defaultValue'),
      headerName: t('smartpaycomponentspage.datatable.column.inputcodes'),
      flex: 1
    },
    { field: '', sortable: false, editable: false, align: 'center', renderCell: rowParams => rowActions(rowParams.row) }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(nameof('payrollCode'), OrderDirection.Asc))

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: 3,
          paddingLeft: 3,
          paddingRight: 2
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_SMART_PAYCOMPONENTS(
            `${params.id}`,
            `${params.employerid}`
          )}
          title={t('smartpaycomponentsettingspage.title')}
        />

        <Grid container>
          <Grid item md={9}>
            <Typography color={theme.palette.text.secondary} variant="h3" paddingBottom={2}>
              {getEmployerById?.employerById.companyName} -{' '}
              {getSmartPayComponentById?.smartPayComponentById.description}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <FormControl fullWidth size="small" sx={{mt: 1, pt: 1}}>
              <InputLabel id="history-select-label" sx={{left: '-1rem', fontWeight: 'bold'}}>{t('smartpaycomponentsettingspage.history-selection')}</InputLabel>
              <Select
                value={selectedHistory}
                fullWidth
                labelId="history-select-label"
                id="history-select"
                style={{ borderRadius: 2, maxWidth: '20rem' }}
              >
                {getSmartPayComponentById?.smartPayComponentById?.histories?.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {`${new Date(item.validFrom).toLocaleDateString(me?.me.locale.locale)} - ${item.validTo ? new Date(item.validTo).toLocaleDateString(me?.me.locale.locale): '...'}` }
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid columnSpacing={'2rem'} container>
          <Grid item md={6} lg={4}>
            <DetailBox
              title={t('smartpaycomponentsettingspage.generalsettings.title')}
              actionIcon={
                <IconButton
                  size="small"
                  onClick={() =>
                    openFlyIn({
                      content: (
                        <AddEditSmartPayComponent
                          employerId={params.employerid ?? ''}
                          smartPayComponentId={params.smartpaycomponentid ?? ''}
                        />
                      ),
                      callbackAfterClose: () => refetchSmartPayComponentById
                    })
                  }
                >
                  <EditIcon />
                </IconButton>
              }
            >
              <DetailBoxRow>
                <Typography>
                  <span className="bold">
                    {' '}
                    {getSmartPayComponentById?.smartPayComponentById.description} (
                    {getSmartPayComponentById?.smartPayComponentById.code})
                  </span>
                </Typography>
              </DetailBoxRow>
            </DetailBox>
          </Grid>
        </Grid>
        <Grid container columnSpacing={'4rem'}>
          <Grid item md={6}>
            <DetailBox
              title={t('smartpaycomponentsettingspage.inputcodes.title')}
              actionIcon={
                <IconButton
                  size="small"
                  onClick={() =>
                    openFlyIn({
                      content: (
                        <AddSmartInputPayComponent
                          smartComponentHistoryId={selectedHistory ?? ''}
                          employerId={params.employerid ?? ''}
                        />
                      ),
                      callbackAfterClose: () => refetchSmartPayComponentHistoryById
                    })
                  }
                >
                  <AddCircleOutlineIcon />
                  <Typography sx={{ ml: 0.5, color: theme.palette.primary.main, fontSize: 'small' }}>
                    {t('smartpaycomponentsettingspage.inputcodes.add')}
                  </Typography>
                </IconButton>
              }
            >
              <DataTable
                sortModel={sortModel}
                data={inputTableData}
                columns={columns}
                isLoading={isFetchingHistory}
                hideFooter={true}
              />
              <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <RemoveSmartInputCode smartPayComponentHistoryId={selectedHistory ?? ''} smartInputCodeId={smartInputCodeId} description='description'  />,
              callbackAfterClose: () => refetchSmartPayComponentHistoryById
            })
          }
        >
          {t('common.delete')}
        </MenuItem>
      </DropdownMenu>
            </DetailBox>
          </Grid>
          <Grid item md={6}>
            <DetailBox
              title={t('smartpaycomponentsettingspage.outputcodes.title')}
              actionIcon={
                <IconButton
                  size="small"
                  onClick={() =>
                    openFlyIn({
                      content: (
                        <AddEditSmartPayComponent
                          employerId={params.employerid ?? ''}
                          smartPayComponentId={params.smartpaycomponentid ?? ''}
                        />
                      ),
                      callbackAfterClose: () => refetchSmartPayComponentById
                    })
                  }
                >
                  <AddCircleOutlineIcon />
                  <Typography sx={{ ml: 0.5, color: theme.palette.primary.main, fontSize: 'small' }}>
                    {t('smartpaycomponentsettingspage.outputcodes.add')}
                  </Typography>
                </IconButton>
              }
            >
              <Typography sx={{ ml: 2 }}>Test</Typography>
            </DetailBox>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default SmartPayComponentSettings
