import {
  getSearchParamNumber,
  OrderDirection,
  useGetAllContractsQuery,
  useGetPersonByIdQuery,
  useIsActivePeopleLimitReachedQuery,
  useNavigateWithParams
} from '@epix-web-apps/core'
import { Box, Button, IconButton, MenuItem, Switch, Typography, useTheme } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { DataTable, SortModel, PaginationModel, usePaginationModel } from '../../components/data-table'
import { FeatureForPurchaseIndicator, HeaderTitleNavigation, People } from '@epix-web-apps/ui'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import AddIcon from '@mui/icons-material/Add'
import { DetailPageBaseQueryParams, DetailPageContentHeader } from '@epix-web-apps/ui'
import DropdownMenu from '../../components/dropdown-menu'
import { useFlyIn } from '@epix-web-apps/ui'
import { AddContract } from '../../components/contract-components'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import FilterButton from '../../components/filter-button/filter-button'
import { EditContractArchived } from '../../components/contract-components/edit-contract-archived'
import { EditContractClosureParameters } from '../../components/contract-components/edit-contract-closure-parameters'
import NextPreviousPerson from '../../components/people-components/next-previous-person/next-previous-person'

/* eslint-disable-next-line */
export interface ContractsPageProps {}

export function ContractsPage(props: ContractsPageProps) {
  const { t } = useTranslation()
  const [filters, setFilters] = useState({
    showArchived: false
  })
  const { openFlyIn } = useFlyIn()
  const navigateWithParams = useNavigateWithParams()
  const [searchParams, _] = useSearchParams()
  const params = useParams<DetailPageBaseQueryParams>()
  const { paginationModel } = usePaginationModel()
  const theme = useTheme()
  const { data: isActivePeopleLimitReached } = useIsActivePeopleLimitReachedQuery()
  const { data: getPersonSidebarInfoById } = useGetPersonByIdQuery(
    {
      personId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )
  const person = getPersonSidebarInfoById?.personById
  const {
    data: getAllContracts,
    isLoading,
    refetch
  } = useGetAllContractsQuery(
    {
      personId: params.id || '',
      showArchived: filters.showArchived,
      includeExpired: true,
      orderDirection: OrderDirection.Desc
    },
    {
      enabled: !!params.id,
      suspense: false
    }
  )
  const contracts = getAllContracts?.allContracts
  const [rowCount, setRowCount] = useState(0)

  const rowActions = (rowParams: GridRenderCellParams) =>
    !rowParams.row.archived && (
      <>
        <IconButton
          aria-label="row actions"
          aria-controls="menu-row"
          aria-haspopup="true"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setClickedContractId(rowParams.id.toString())
            setAnchorEl(e.currentTarget)
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <IconButton aria-label="row details" aria-controls="details-row">
          <KeyboardArrowRightIcon />
        </IconButton>
      </>
    )

  const validationRowData = (rowParams: GridRenderCellParams) => {
    const errors = rowParams.value.errors
    const warnings = rowParams.value.warnings

    if (errors === 0 && warnings === 0) {
      return (
        <Typography color={theme.palette.success.main}>
          <CheckIcon />
        </Typography>
      )
    } else {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {errors > 0 && (
            <Typography color={theme.palette.error.light} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ErrorOutlineOutlinedIcon sx={{ fontSize: 18 }} />
              {errors}
            </Typography>
          )}
          {warnings > 0 && (
            <Typography color={theme.palette.warning.light} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <WarningAmberOutlinedIcon sx={{ fontSize: 18 }} />
              {warnings}
            </Typography>
          )}
        </Box>
      )
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'country',
      headerName: t('contractspage.datatable.column.country'),
      flex: 1,
      sortable: false
    },
    {
      field: 'period',
      headerName: t('contractspage.datatable.column.period'),
      flex: 1,
      sortable: false
    },
    {
      field: 'endReason',
      headerName: t('contractspage.datatable.column.endreason'),
      flex: 1,
      sortable: false
    },
    {
      field: 'validations',
      sortable: false,
      headerName: t('contractspage.datatable.column.validations'),
      renderCell: validationRowData
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel] = useState(new SortModel(columns?.[1]?.field, OrderDirection.Desc))
  const [clickedContractId, setClickedContractId] = useState<string>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [tableData, setTableData] = useState<GridRowsProp>()

  useEffect(() => {
    if (contracts) {
      setTableData(
        contracts.map(row => {
          return {
            id: row.id,
            country: row.icp?.code,
            period: `${new Date(row.startDate).toLocaleDateString()} - ${
              row.endDate ? new Date(row.endDate).toLocaleDateString() : '...'
            }`,
            endReason: row.endReasonType?.value,
            validations: {
              errors: row.validationErrorCount,
              warnings: row.validationWarningCount
            },
            archived: row.isArchived
          }
        })
      )
      setRowCount(contracts.length)
    }
  }, [contracts])

  const goToContractDetailPage = (personId: string) => {
    personId && navigateWithParams(People.PEOPLE_ID_CONTRACTS_ID(`${params.id}`, `${personId}`), { tabIndex: '0' })
  }

  const filterBar = (
    <Box sx={{ display: 'grid', p: [2] }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography m={0} variant="h2">
          {t('contractspage.title')}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <FilterButton filters={filters}>
            <p>
              <Switch
                checked={filters.showArchived}
                onChange={e => setFilters({ ...filters, showArchived: e.target.checked })}
                aria-label={t('contractspage.filters.showarchived')}
              />
              {t('contractspage.filters.showarchived')}
            </p>
          </FilterButton>

          <Button
            variant="contained"
            disabled={
              isActivePeopleLimitReached?.isActivePeopleLimitReached &&
              !getAllContracts?.allContracts.some(c => c.isActiveNowOrInTheFuture)
            }
            onClick={() =>
              openFlyIn({
                content: <AddContract />,
                callbackAfterClose: () => refetch
              })
            }
          >
            <AddIcon />
            {t('contractspage.button.addcontract')}
          </Button>
        </Box>
      </Box>
      {isActivePeopleLimitReached?.isActivePeopleLimitReached &&
        !getAllContracts?.allContracts.some(c => c.isActiveNowOrInTheFuture) && (
          <Box sx={{ justifySelf: 'right', fontStyle: 'italic' }}>
            {t('limits.peoplelimitreached')} <FeatureForPurchaseIndicator />
          </Box>
        )}
    </Box>
  )

  return (
    <>
      <DetailPageContentHeader>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <HeaderTitleNavigation
            backToLink={`${People.PEOPLE()}?page=${getSearchParamNumber('page', searchParams)}`}
            title={`${person?.firstName} ${person?.preferredLastName}`}
            showDivider={false}
          />
          <NextPreviousPerson personId={params.id || ''} />
        </Box>
      </DetailPageContentHeader>

      <DataTable
        data={tableData}
        columns={columns}
        paginationModel={paginationModel}
        totalRowCount={rowCount}
        onRowClick={params => {
          if (params.row.archived === false) {
            goToContractDetailPage(params.id.toString())
          }
        }}
        isLoading={isLoading}
        sortModel={sortModel}
        filterBarElement={filterBar}
        hideFooter
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => goToContractDetailPage(clickedContractId!)}>
          {t('contractspage.list.row.menu.viewdetails')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <EditContractArchived contractId={clickedContractId!} />,
              callbackAfterClose: () => refetch
            })
          }
        >
          {t('contractspage.list.row.menu.editcontractstate')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <EditContractClosureParameters contractId={clickedContractId!} />
            })
          }
        >
          {t('contractspage.list.row.menu.manageclosureinformation')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default ContractsPage
