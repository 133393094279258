import { Box, IconButton, MenuItem, Typography, useTheme } from '@mui/material'
import { DataTable, SortModel } from '../../../data-table'
import { DriversEndingSoonModel, OrderDirection } from '@epix-web-apps/core'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CompanyCar, People, useFlyIn } from '@epix-web-apps/ui'
import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp } from '@mui/x-data-grid'
import DropdownMenu from '../../../dropdown-menu'
import React from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

/* eslint-disable-next-line */
export interface OverviewDriversEndingSoonProps {
  data?: Array<DriversEndingSoonModel>
}

export function OverviewDriversEndingSoon(data: OverviewDriversEndingSoonProps) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [tableData, setTableData] = useState<GridRowsProp>()
  const theme = useTheme()
  const navigate = useNavigate()
  const { closeFlyIn } = useFlyIn()
  const [clickedRecord, setClickedRecord] = useState<DriversEndingSoonModel>()

  const rowActions = (rowParams: GridRenderCellParams) => {
    return (
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setClickedRecord(rowParams.row)
          setAnchorEl(e.currentTarget)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('analytics.homepage.events.name'),
      flex: 3,
      sortable: false
    },
    {
      field: 'date',
      headerName: t('analytics.homepage.events.date'),
      flex: 2,
      sortable: false
    },
    {
      field: 'licensePlate',
      headerName: t('analytics.homepage.events.licensePlate'),
      flex: 2,
      sortable: false
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns[0]?.field, OrderDirection.Asc))

  const goToContractDetailPage = (personId: GridRowId | undefined, contractId: GridRowId | undefined) => {
    personId && contractId && navigate(People.PEOPLE_ID_CONTRACTS_ID(`${personId}`, `${contractId}`))
  }

  const goToCompanyCarDetailPage = (companyCarId: GridRowId | undefined) => {
    companyCarId && navigate(CompanyCar.ID(`${companyCarId}`))
  }

  useEffect(() => {
    if (data.data) {
      setTableData(
        data.data.map((row: any) => {
          return {
            id: row.id,
            name: `${row.firstName}  ${row.lastName}`,
            date: new Date(row.upcomingDate).toLocaleDateString(),
            licensePlate: row.licensePlate,
            personId: row.personId,
            contractId: row.contractId,
            companyCarId: row.companyCarId
          }
        })
      )
    }
  }, [data])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '30rem'
      }}
    >
      <Typography variant="h4">{t('analytics.homepage.events.title.driversendingsoon')}</Typography>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <DataTable
          totalRowCount={data.data?.length}
          data={tableData}
          columns={columns}
          onSortChange={setSortModel}
          isLoading={false}
          sortModel={sortModel}
          hideFooter={true}
          onRowClick={params => {
            goToContractDetailPage(params.row.personId, params.row.contractId)
            closeFlyIn()
          }}
          emptyStateElement={
            <Typography
              sx={{
                mt: 2,
                color: theme.palette.text.secondary,
                fontStyle: 'italic'
              }}
            >
              {t('analytics.homepage.events.noresults')}
            </Typography>
          }
        />
        <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
          <MenuItem
            onClick={() => {
              goToCompanyCarDetailPage(clickedRecord?.companyCarId)
              closeFlyIn()
            }}
          >
            {t('analytics.homepage.events.menu.gotocar')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              goToContractDetailPage(clickedRecord?.personId, clickedRecord?.contractId)
              closeFlyIn()
            }}
          >
            {t('analytics.homepage.events.menu.gotocontract')}
          </MenuItem>
        </DropdownMenu>
      </Box>
    </Box>
  )
}

export default OverviewDriversEndingSoon
