import {
  DOCUMENT_ACCESSLEVELTYPE_KEYS,
  GraphqlError,
  useGetEmployerDocumentTypeByIdQuery,
  useUpdateEmployerDocumentTypeMutation
} from '@epix-web-apps/core'
import { useTranslation } from 'react-i18next'
import { boolean, object, TypeOf } from 'zod'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, useFlyIn } from '@epix-web-apps/ui'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { FormSwitch } from '../../form-components/form-switch'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface EditAccessCompanyDocumenttypeProps {
  documentTypeId?: string
}

export function EditAccessCompanyDocumenttype({ documentTypeId }: EditAccessCompanyDocumenttypeProps) {
  const { t } = useTranslation()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const editCompanyDocumentTypeSchema = object({
    employeeAccessLevel: boolean()
  })

  const updateMutation = useUpdateEmployerDocumentTypeMutation()

  const { data: getDocumentType, refetch } = useGetEmployerDocumentTypeByIdQuery({
    id: documentTypeId || ''
  })
  const { closeFlyIn } = useFlyIn()

  type EditCompanyDocumentTypeForm = TypeOf<typeof editCompanyDocumentTypeSchema>

  const form = useForm<EditCompanyDocumentTypeForm>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      employeeAccessLevel:
        getDocumentType?.employerDocumentTypeById.employeeAccessLevel.key === DOCUMENT_ACCESSLEVELTYPE_KEYS.CAN_VIEW
    },
    resolver: zodResolver(editCompanyDocumentTypeSchema)
  })

  const handleOnSubmit = async (editedCompanyDocumentType: EditCompanyDocumentTypeForm) => {
    if (getDocumentType?.employerDocumentTypeById)
      await updateMutation
        .mutateAsync({
          updateEmployerDocumentTypeCommand: {
            id: getDocumentType?.employerDocumentTypeById.id,
            active: getDocumentType?.employerDocumentTypeById.active,
            name: getDocumentType?.employerDocumentTypeById.name,
            employeeAccessLevelTypeKey: editedCompanyDocumentType.employeeAccessLevel
              ? DOCUMENT_ACCESSLEVELTYPE_KEYS.CAN_VIEW
              : DOCUMENT_ACCESSLEVELTYPE_KEYS.NO_ACCESS,
            translations: getDocumentType?.employerDocumentTypeById?.nameTranslations.map(({ locale, value }) => ({
              locale,
              value
            }))
          }
        })
        .then(() => {
          refetch()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcompanydocumenttype.editaccess')}</Typography>
      <FormGridLayout>
        <FormSwitch sx={12} name="employeeAccessLevel" label={t('form.field.employeeaccesslevel')} />
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={updateMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditAccessCompanyDocumenttype
