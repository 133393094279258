import { Box, Typography, useTheme } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, date, object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  DetailPageBaseQueryParams,
  FormGridLayout,
  FormRadioGroup,
  IFormRadioOption,
  People,
  useFlyIn
} from '@epix-web-apps/ui'
import { FormSelect } from '@epix-web-apps/ui'
import {
  OrderDirection,
  useCreateContractMutation,
  useGetAllEmployersByIcpIdQuery,
  useGetAllIcpsQuery,
  useGetEmployerByIdQuery,
  useGetSubContractDurationTypesQuery,
  useGetSubEmployeeTypesQuery,
  useGetSubPopulationTypesQuery,
  useGetIcpByIdQuery,
  useGetPayGroupByIdQuery,
  BOOLEAN_STRING_KEYS,
  FormSelectOption,
  formTypeSelectOptions,
  GraphqlError
} from '@epix-web-apps/core'
import { FormActionButtons, FormDatepicker, FormErrorList } from '@epix-web-apps/ui'
import { useNavigate, useParams } from 'react-router-dom'
import { FormContainer } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import { useEffect, useState } from 'react'

/* eslint-disable-next-line */
export interface AddContractProps {}

export function AddContract(props: AddContractProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const addContractSchema = object({
    icpId: string({
      required_error: t('form.validation.contractproviderrequired'),
      invalid_type_error: t('form.validation.contractproviderrequired')
    }).min(1, t('form.validation.contractproviderrequired')),
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired')),
    payGroupId: string().optional().nullable(),
    durationId: string().optional().nullable(),
    subDurationId: string().optional().nullable(),
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date().optional().nullable(),
    probationEndDate: date().optional().nullable(),
    fixedTermEndDate: date().optional().nullable(),
    employeeTypeId: string().optional().nullable(),
    subEmployeeTypeId: string().optional().nullable(),
    populationTypeId: string().optional().nullable(),
    subPopulationTypeId: string().optional().nullable(),
    calculatedByEpix: boolean(),
    countedAsStarter: boolean(),
    countedAsLeaver: boolean(),
    contractEndInitiatedByCompany: string().optional().nullable()
  })
    .refine(data => (data.endDate ? data.endDate >= data.startDate : !data.endDate), {
      message: t('form.validation.effectiveenddateafterstartdate'),
      path: ['endDate']
    })
    .refine(data => (data.fixedTermEndDate ? data.fixedTermEndDate >= data.startDate : !data.fixedTermEndDate), {
      message: t('form.validation.fixedtermenddateafterstartdate'),
      path: ['fixedTermEndDate']
    })

  const contractEndInitiatedByOptions: Array<IFormRadioOption> = [
    {
      id: BOOLEAN_STRING_KEYS.FALSE,
      label: t('form.label.employee')
    },
    {
      id: BOOLEAN_STRING_KEYS.TRUE,
      label: t('form.label.company')
    }
  ]

  type CreateContractForm = TypeOf<typeof addContractSchema>
  const params = useParams<DetailPageBaseQueryParams>()
  const { closeFlyIn } = useFlyIn()
  const { data: getAllIcps } = useGetAllIcpsQuery({
    offset: 0,
    limit: -1,
    sortByProperty: 'Code',
    orderDirection: OrderDirection.Asc
  })
  const { employeeOptions, contractDurationOptions, populationOptions } = formTypeSelectOptions
  const mutation = useCreateContractMutation()

  const form = useForm<CreateContractForm>({
    resolver: zodResolver(addContractSchema),
    defaultValues: {
      startDate: undefined,
      endDate: null,
      probationEndDate: null,
      fixedTermEndDate: null,
      calculatedByEpix: false,
      countedAsStarter: true,
      countedAsLeaver: true,
      contractEndInitiatedByCompany: BOOLEAN_STRING_KEYS.FALSE
    }
  })

  const { control } = form
  const watchedIcpId = useWatch({ control, name: `icpId` })
  const watchedEmployerId = useWatch({ control, name: `employerId` })
  const watchedPayGroupId = useWatch({ control, name: `payGroupId` })
  const watchedDurationId = useWatch({ control, name: `durationId` })
  const watchedEmployeeTypeId = useWatch({ control, name: `employeeTypeId` })
  const watchedPopulationTypeId = useWatch({
    control,
    name: `populationTypeId`
  })

  const navigate = useNavigate()
  const { data: getIcpById } = useGetIcpByIdQuery(
    {
      icpId: watchedIcpId || ''
    },
    {
      enabled: !!watchedIcpId,
      suspense: false
    }
  )
  const { data: getAllEmployersByIcpId } = useGetAllEmployersByIcpIdQuery(
    {
      icpId: watchedIcpId || '',
      limit: -1,
      offset: 0,
      orderDirection: OrderDirection.Asc,
      sortByProperty: 'companyName'
    },
    {
      enabled: !!watchedIcpId,
      suspense: false
    }
  )
  const { data: getEmployerById } = useGetEmployerByIdQuery(
    {
      employerId: watchedEmployerId || ''
    },
    {
      enabled: !!watchedEmployerId,
      suspense: false
    }
  )

  const { data: getPayGroupById } = useGetPayGroupByIdQuery(
    {
      employerId: watchedEmployerId || '',
      payGroupId: watchedPayGroupId || ''
    },
    {
      enabled: !!watchedEmployerId && !!watchedPayGroupId,
      suspense: false
    }
  )

  const { data: getSubContractDurationTypes, isFetching: isFetchingSubContractDurationTypes } =
    useGetSubContractDurationTypesQuery(
      {
        configurationKey: getIcpById?.icpById?.configurationKey || '',
        contractDurationTypeKey: watchedDurationId
      },
      {
        enabled: !!watchedDurationId && !!getIcpById?.icpById?.id,
        suspense: false,
        onSuccess: data => {
          if (data.subContractDurations.length === 1) {
            form.setValue('subDurationId', data.subContractDurations[0].id)
          }
        }
      }
    )
  const { data: getSubEmployeeTypes, isFetching: isFetchingSubEmployeeTypes } = useGetSubEmployeeTypesQuery(
    {
      configurationKey: getIcpById?.icpById?.configurationKey || '',
      employeeTypeKey: watchedEmployeeTypeId
    },
    {
      enabled: !!watchedEmployeeTypeId && !!getIcpById?.icpById?.id,
      suspense: false,
      onSuccess: data => {
        if (data.subEmployeeTypes.length === 1) {
          form.setValue('subEmployeeTypeId', data.subEmployeeTypes[0].id)
        }
      }
    }
  )
  const { data: getSubPopulationTypes, isFetching: isFetchingSubPopulationTypes } = useGetSubPopulationTypesQuery(
    {
      configurationKey: getIcpById?.icpById?.configurationKey || '',
      populationTypeKey: watchedPopulationTypeId
    },
    {
      enabled: !!watchedPopulationTypeId && !!getIcpById?.icpById?.id,
      suspense: false,
      onSuccess: data => {
        if (data.subPopulationTypes.length === 1) {
          form.setValue('subPopulationTypeId', data.subPopulationTypes[0].id)
        }
      }
    }
  )

  useEffect(() => {
    form.resetField('calculatedByEpix', {
      defaultValue: getPayGroupById?.payGroupById?.calculatedByEpix ?? false
    })
  }, [getPayGroupById?.payGroupById?.calculatedByEpix])

  useEffect(() => {
    form.resetField('calculatedByEpix', {
      defaultValue: getEmployerById?.employerById?.calculatedByEpix ?? false
    })
  }, [getEmployerById?.employerById?.calculatedByEpix])

  const goToContractDetailPage = (contractId: string | undefined) => {
    contractId && navigate(People.PEOPLE_ID_CONTRACTS_ID(`${params.id}`, contractId))
  }

  const handleOnSubmit = async (newContract: CreateContractForm) => {
    await mutation
      .mutateAsync({
        createContractCommand: {
          personId: params.id || '',
          icpId: newContract.icpId,
          employerId: newContract.employerId && newContract.employerId,
          payGroupId: (newContract.payGroupId && newContract.payGroupId) || undefined,
          contractDurationTypeKey: newContract.durationId && newContract.durationId,
          icpSubContractDurationId:
            getSubContractDurationTypes?.subContractDurations.length === 0 ? null : newContract.subDurationId,
          startDate: newContract.startDate,
          endDate: newContract.endDate,
          probationEndDate: newContract.probationEndDate,
          fixedTermEndDate: newContract.fixedTermEndDate,
          employeeTypeKey: newContract.employeeTypeId && newContract.employeeTypeId,
          icpSubEmployeeTypeId:
            getSubEmployeeTypes?.subEmployeeTypes.length === 0 ? null : newContract.subEmployeeTypeId,
          populationTypeKey: newContract.populationTypeId && newContract.populationTypeId,
          icpSubPopulationTypeId:
            getSubPopulationTypes?.subPopulationTypes.length === 0 ? null : newContract.subPopulationTypeId,
          calculatedByEpix: newContract.calculatedByEpix,
          countedAsStarter: newContract.countedAsStarter,
          countedAsLeaver: newContract.countedAsLeaver,
          contractEndInitiatedByCompany: newContract.countedAsLeaver
            ? newContract.contractEndInitiatedByCompany === BOOLEAN_STRING_KEYS.TRUE
            : null
        }
      })
      .then(data => {
        closeFlyIn()
        goToContractDetailPage(data.createContract)
      })
      .catch(e => setBackendErrors([e]))
  }

  const watchedCountedAsLeaver = useWatch({ control, name: 'countedAsLeaver' })

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addcontract.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="icpId"
          label={`${t('form.field.provider')} *`}
          options={getAllIcps?.icps.data.map(x => new FormSelectOption(x.id, `${x.payrollProvider} (${x.code})`))}
          onChange={e => {
            form.resetField('employerId', { defaultValue: null })
            form.resetField('payGroupId', { defaultValue: null })
            form.resetField('subDurationId', { defaultValue: null })
            form.resetField('subEmployeeTypeId', { defaultValue: null })
            form.resetField('subPopulationTypeId', { defaultValue: null })
          }}
        />

        <FormSelect
          sx={12}
          name="employerId"
          label={`${t('form.field.employer')} *`}
          options={getAllEmployersByIcpId?.employersByIcpId?.data.map(
            x => new FormSelectOption(x.id, `${x.companyName} (${x.number})`)
          )}
          onChange={() => form.resetField('payGroupId', { defaultValue: null })}
        />

        {getEmployerById?.employerById && getEmployerById?.employerById?.payGroups.length > 0 && (
          <>
            <FormSelect
              sx={12}
              name="payGroupId"
              label={t('form.field.paygroup')}
              options={getEmployerById?.employerById?.payGroups.map(
                x => new FormSelectOption(x.id, `${x.name} (${x.code})`)
              )}
            />
            {!watchedPayGroupId && (
              <Typography
                sx={{
                  pl: '1.25rem',
                  pt: '0.25rem',
                  fontSize: 'small',
                  color: theme.palette.text.secondary
                }}
              >
                {t('flyin.contract.info')}
              </Typography>
            )}
          </>
        )}

        <FormSelect
          sx={12}
          name="durationId"
          label={t('form.field.durationtype')}
          options={contractDurationOptions}
          onChange={() => form.resetField('subDurationId', { defaultValue: null })}
        />

        {getSubContractDurationTypes && getSubContractDurationTypes?.subContractDurations.length > 1 && (
          <FormSelect
            sx={12}
            name="subDurationId"
            label={t('form.field.subdurationtype')}
            options={getSubContractDurationTypes?.subContractDurations.map(x => new FormSelectOption(x.id, x.name))}
          />
        )}

        <FormDatepicker sx={6} name="startDate" label={`${t('form.field.startdate')} *`} />

        <FormDatepicker sx={6} name="probationEndDate" label={t('form.field.probationenddate')} />

        <FormDatepicker sx={6} name="fixedTermEndDate" label={t('form.field.fixedtermenddate')} />

        <FormDatepicker sx={6} name="endDate" label={t('form.field.effectiveenddate')} />

        <FormSelect
          sx={12}
          name="employeeTypeId"
          label={t('form.field.employeetype')}
          options={employeeOptions}
          onChange={e => form.resetField('subEmployeeTypeId', { defaultValue: null })}
        />

        {getSubEmployeeTypes && getSubEmployeeTypes?.subEmployeeTypes.length > 1 && (
          <FormSelect
            sx={12}
            name="subEmployeeTypeId"
            label={t('form.field.subemployeetype')}
            options={getSubEmployeeTypes?.subEmployeeTypes.map(x => new FormSelectOption(x.id, x.name))}
          />
        )}

        <FormSelect
          sx={12}
          name="populationTypeId"
          label={t('form.field.populationtype')}
          options={populationOptions}
          onChange={e => form.resetField('subPopulationTypeId', { defaultValue: null })}
        />

        {getSubPopulationTypes && getSubPopulationTypes?.subPopulationTypes.length > 1 && (
          <FormSelect
            sx={12}
            name="subPopulationTypeId"
            label={t('form.field.subpopulationtype')}
            options={getSubPopulationTypes?.subPopulationTypes.map(x => new FormSelectOption(x.id, x.name))}
          />
        )}

        <FormSwitch sx={12} name="calculatedByEpix" label={t('form.field.payrollviaepix')} />

        <FormSwitch sx={12} name="countedAsStarter" label={t('form.field.countasstarter')} />

        <FormSwitch sx={12} name="countedAsLeaver" label={t('form.field.countasleaver')} />

        {watchedCountedAsLeaver && (
          <Box sx={{ mt: 2, mx: 3 }}>
            <FormRadioGroup
              sx={12}
              name="contractEndInitiatedByCompany"
              label={t('form.field.leaveinitiatedby')}
              options={contractEndInitiatedByOptions}
            />
          </Box>
        )}
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={mutation.isLoading}
        disabled={isFetchingSubContractDurationTypes || isFetchingSubEmployeeTypes || isFetchingSubPopulationTypes}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddContract
