import { useTranslation } from 'react-i18next'
import { DataTable, SortModel } from '../../data-table'
import { Box, IconButton, MenuItem, Switch, Tooltip, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { ERRORTYPE, OrderDirection, useGetAllValidationErrorsByContractIdQuery } from '@epix-web-apps/core'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import DoneIcon from '@mui/icons-material/Done'
import FilterButton from '../../filter-button'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import DropdownMenu from '../../dropdown-menu'
import EditViewedValidationError from '../../validation-components/edit-viewed-validation-error'
import { Documentation, useFlyIn } from '@epix-web-apps/ui'
import DataTableEmpty from '../../data-table/data-table-empty'
import { InfoOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface ContractValidationerrorsTabProps {
  contractId: string | undefined
}

export function ContractValidationerrorsTab({ contractId }: ContractValidationerrorsTabProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { openFlyIn } = useFlyIn()
  const [rowCount, setRowCount] = useState(0)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [validationErrorId, setValidationErrorId] = useState('')
  const [filters, setFilters] = useState({
    showViewed: false
  })
  const theme = useTheme()

  const errorTypeActions = (rowParams: GridRenderCellParams) =>
    rowParams.value.toString().toUpperCase() === ERRORTYPE.ERROR ? (
      <Tooltip title={t('contractvalidationerrorspage.tooltip.error')}>
        <ErrorOutlineOutlinedIcon sx={{ color: theme.palette.error.light }} />
      </Tooltip>
    ) : (
      <Tooltip title={t('contractvalidationerrorspage.tooltip.warning')}>
        <WarningAmberOutlinedIcon sx={{ color: theme.palette.warning.light }} />
      </Tooltip>
    )

  const hasSeenActions = (rowParams: GridRenderCellParams) => {
    return rowParams.value && <VisibilityOffIcon />
  }

  const rowActions = (rowParams: GridRenderCellParams) =>
    rowParams.row.errorType.toString().toUpperCase() === ERRORTYPE.WARNING ? (
      <>
        <IconButton
          aria-label="row actions"
          aria-controls="menu-row"
          aria-haspopup="true"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setAnchorEl(e.currentTarget)
            setValidationErrorId(rowParams.row.id)
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <IconButton aria-label="row details" aria-controls="details-row" />
      </>
    ) : (
      ''
    )

  const columns: GridColDef[] = [
    {
      field: 'errorType',
      headerName: t('contractvalidationerrorspage.datatable.column.errortype'),
      flex: 0.25,
      renderCell: errorTypeActions
    },
    {
      field: 'errorCode',
      headerName: t('contractvalidationerrorspage.datatable.column.errorcode'),
      flex: 0.25
    },
    {
      field: 'entity',
      headerName: t('contractvalidationerrorspage.datatable.column.entity'),
      flex: 0.5
    },
    {
      field: 'validationErrorMessage',
      headerName: t('contractvalidationerrorspage.datatable.column.validationerrormessage'),
      flex: 1,
      sortable: false
    },
    {
      field: 'hasSeen',
      headerName: '',
      renderCell: hasSeenActions
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns[0]?.field, OrderDirection.Asc))
  const [tableData, setTableData] = useState<GridRowsProp>()

  const {
    data: validations,
    isLoading,
    refetch: refetchValidations
  } = useGetAllValidationErrorsByContractIdQuery({
    contractId: contractId || '',
    showHasSeen: filters.showViewed,
    offset: 0,
    limit: -1,
    sortByProperty: sortModel.field,
    orderDirection: sortModel.orderDirection
  })

  useEffect(() => {
    if (validations && validations.allValidationErrorsByContractId) {
      const validationData = validations.allValidationErrorsByContractId
      setTableData(
        validationData.data.map(row => {
          return {
            id: row.id,
            errorCode: row.errorCode,
            entity: row.errorCodeEntityName,
            errorType: row.errorType,
            validationErrorMessage: row.validationErrorMessage,
            hasSeen: row.hasSeen
          }
        })
      )
      setRowCount(validationData.totalCount)
    }
  }, [validations])

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right'
      }}
    >
      <Tooltip title={t('contractvalidationerrorspage.tooltip.docs')}>
        <IconButton size="small" onClick={() => navigate(Documentation.VALIDATIONS)}>
          <InfoOutlined fontSize="medium" />
        </IconButton>
      </Tooltip>
      <FilterButton filters={filters}>
        <p>
          <Switch
            checked={filters.showViewed}
            onChange={e => setFilters({ ...filters, showViewed: e.target.checked })}
            aria-label={t('contractvalidationerrorspage.filters.showViewed')}
          />
          {t('contractvalidationerrorspage.filters.showViewed')}
        </p>
      </FilterButton>
    </Box>
  )

  return (
    <>
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        onSortChange={setSortModel}
        isLoading={isLoading}
        sortModel={sortModel}
        filterBarElement={filterBar}
        hideFooter
        emptyStateElement={
          <DataTableEmpty
            title={t('emptystate.validations')}
            icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.success.light }} />}
          />
        }
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={e =>
            openFlyIn({
              content: <EditViewedValidationError validationErrorId={validationErrorId!} />,
              callbackAfterClose: () => refetchValidations
            })
          }
        >
          {t('contractvalidationerrorspage.list.row.menu.editvalidationerrorhasseen')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default ContractValidationerrorsTab
