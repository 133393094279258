import {
  DEFAULT_LASTNAMETYPE_KEY,
  DEFAULT_UNDEFINED_KEY,
  FormSelectOption,
  formTypeSelectOptions,
  GraphqlError,
  OrderDirection,
  useCreatePersonMutation,
  useGetAllEmployersByIcpIdQuery,
  useGetEmployerByIdQuery
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormSelect,
  People,
  useFlyIn,
  useGlobalStore
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Button, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { FieldError, FieldErrors, useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { boolean, date, literal, object, string, TypeOf } from 'zod'

/* eslint-disable-next-line */
export interface AddPersonProps {}

export interface EmployerDropdownProps {
  index: number
}

function getCustomErrors(
  contractError: FieldErrors<object> | undefined,
  backendErrors: GraphqlError[]
): Array<FieldError | GraphqlError | undefined> {
  if (!contractError) return backendErrors
  return [...Object.values(contractError), ...backendErrors]
}

export function AddPerson(props: AddPersonProps) {
  const { t } = useTranslation()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const addPersonContractSchema = object({
    icpId: string({
      required_error: t('form.validation.contractproviderrequired'),
      invalid_type_error: t('form.validation.contractproviderrequired')
    }).min(1, t('form.validation.contractproviderrequired')),
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired')),
    calculatedByEpix: boolean(),
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date().optional().nullable()
  }).refine(data => (data.endDate ? data.endDate > data.startDate : !data.endDate), {
    message: t('form.validation.enddateafterstartdate'),
    path: ['endDate']
  })
  const addPersonSchema = object({
    titleType: string().nullable(),
    firstName: string({
      required_error: t('form.validation.firstnamerequired'),
      invalid_type_error: t('form.validation.firstnamerequired')
    }).min(1, t('form.validation.firstnamerequired')),
    lastName: string({
      required_error: t('form.validation.lastnamerequired'),
      invalid_type_error: t('form.validation.lastnamerequired')
    }).min(1, t('form.validation.lastnamerequired')),
    birthDate: date().optional().nullable(),
    lastNamePrefix: string(),
    otherNames: string(),
    partnerName: string(),
    partnerLastNamePrefix: string(),
    personalEmail: string().email(t('form.validation.invalidemail')).or(literal('')),
    preferredLastNameType: string().nullable(),
    preferredLocale: string().optional().nullable(),
    contracts: addPersonContractSchema.array().min(1)
  })
  type CreatePersonForm = TypeOf<typeof addPersonSchema>
  const { closeFlyIn } = useFlyIn()
  const { me } = useGlobalStore()
  const { titleOptions, lastNameOptions, icpOptions, localeOptions } = formTypeSelectOptions
  const mutation = useCreatePersonMutation()
  const navigate = useNavigate()
  const form = useForm<CreatePersonForm>({
    resolver: zodResolver(addPersonSchema),
    defaultValues: {
      titleType: DEFAULT_UNDEFINED_KEY,
      firstName: '',
      otherNames: '',
      lastName: '',
      lastNamePrefix: '',
      partnerName: '',
      partnerLastNamePrefix: '',
      personalEmail: '',
      preferredLastNameType: DEFAULT_LASTNAMETYPE_KEY,
      preferredLocale: me?.locale.locale,
      contracts: [
        {
          icpId: undefined,
          startDate: undefined,
          endDate: null
        }
      ]
    }
  })
  const { control } = form
  const contractsFieldArray = useFieldArray({ control, name: 'contracts' })

  const goToPersonDetailPage = (personId: string | undefined) => {
    personId && navigate(People.PEOPLE_ID_DETAILS(`${personId}`))
  }

  const handleOnSubmit = async (newPerson: CreatePersonForm) => {
    await mutation
      .mutateAsync({
        createPersonCommand: {
          titleTypeKey: newPerson.titleType || DEFAULT_UNDEFINED_KEY,
          firstName: newPerson.firstName,
          birthDate: newPerson.birthDate,
          otherNames: newPerson.otherNames,
          lastName: newPerson.lastName,
          lastNamePrefix: newPerson.lastNamePrefix,
          partnerName: newPerson.partnerName,
          partnerNamePrefix: newPerson.partnerLastNamePrefix,
          personalEmail: newPerson.personalEmail ?? '',
          preferredLastNameTypeKey: newPerson.preferredLastNameType || DEFAULT_LASTNAMETYPE_KEY,
          preferredLocale: newPerson.preferredLocale || '',
          contracts: newPerson.contracts.map(x => ({
            startDate: x.startDate,
            endDate: x.endDate && x.endDate,
            icpId: x.icpId,
            employerId: x.employerId,
            calculatedByEpix: x.calculatedByEpix,
            countedAsStarter: true,
            countedAsLeaver: true,
            contractEndInitiatedByCompany: false
          }))
        }
      })
      .then(data => {
        closeFlyIn()
        goToPersonDetailPage(data.createPerson)
      })
      .catch(e => setBackendErrors([e]))
  }

  const EmployerDropdown = ({ index }: EmployerDropdownProps) => {
    const { t } = useTranslation()
    const { setValue } = useFormContext()
    const watchIcpIdDropdown = useWatch({
      control,
      name: `contracts.${index}.icpId`
    })
    const watchEmployerIdDropdown = useWatch({
      control,
      name: `contracts.${index}.employerId`
    })
    const { data: getAllEmployersByIcpId } = useGetAllEmployersByIcpIdQuery(
      {
        icpId: watchIcpIdDropdown,
        limit: -1,
        offset: 0,
        orderDirection: OrderDirection.Asc,
        sortByProperty: 'companyName'
      },
      {
        enabled: !!watchIcpIdDropdown,
        suspense: false
      }
    )
    const { data: getEmployerById } = useGetEmployerByIdQuery(
      {
        employerId: watchEmployerIdDropdown
      },
      {
        enabled: !!watchEmployerIdDropdown,
        suspense: false
      }
    )

    useMemo(
      () => setValue(`contracts.${index}.calculatedByEpix`, getEmployerById?.employerById.calculatedByEpix ?? false),
      [getEmployerById?.employerById, index, setValue]
    )

    return (
      <FormSelect
        sx={12}
        name={`contracts.${index}.employerId`}
        label={`${t('form.field.employer')} *`}
        options={getAllEmployersByIcpId?.employersByIcpId?.data.map(
          x => new FormSelectOption(x.id, `${x.companyName} (${x.number})`)
        )}
      />
    )
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addperson.title')}</Typography>

      <FormGridLayout>
        <FormSelect sx={6} name="titleType" label={t('form.field.title')} options={titleOptions} />

        <FormInput sx={6} name="firstName" label={`${t('form.field.firstname')} *`} />

        <FormInput sx={6} name="otherNames" label={t('form.field.othernames')} />

        <FormSelect
          sx={6}
          name="preferredLastNameType"
          label={t('form.field.lastnametype')}
          options={lastNameOptions}
        />

        <FormInput sx={6} name="lastNamePrefix" label={t('form.field.lastnameprefix')} />

        <FormInput sx={6} name="lastName" label={`${t('form.field.lastname')} *`} />

        <FormInput sx={6} name="partnerLastNamePrefix" label={t('form.field.partnernameprefix')} />

        <FormInput sx={6} name="partnerName" label={t('form.field.partnername')} />

        <FormSelect sx={6} name="preferredLocale" label={t('form.field.language')} options={localeOptions} />

        <FormDatepicker sx={6} name="birthDate" label={`${t('form.field.birthdate')}`} />

        <FormInput sx={12} name="personalEmail" label={t('form.field.personalemail')} />
      </FormGridLayout>

      <Typography variant="h4">{t('flyin.addperson.contract.title')}</Typography>

      {contractsFieldArray.fields.map((item, index) => (
        <FormGridLayout key={index}>
          <FormSelect
            sx={12}
            name={`contracts.${index}.icpId`}
            label={`${t('form.field.provider')} *`}
            options={icpOptions}
          />

          <EmployerDropdown index={index} />

          <FormDatepicker sx={6} name={`contracts.${index}.startDate`} label={`${t('form.field.startdate')} *`} />

          <FormDatepicker sx={6} name={`contracts.${index}.endDate`} label={t('form.field.enddate')} />
        </FormGridLayout>
      ))}

      <FormGridLayout>
        <Button
          onClick={() =>
            contractsFieldArray.append({
              icpId: undefined,
              startDate: undefined,
              endDate: null
            })
          }
        >
          <AddIcon />
          {t('flyin.addperson.contract.add')}
        </Button>
        {contractsFieldArray.fields.length > 1 && (
          <Button onClick={() => contractsFieldArray.remove(contractsFieldArray.fields.length - 1)}>
            <RemoveIcon />
            {t('flyin.addperson.contract.remove')}
          </Button>
        )}
      </FormGridLayout>

      <FormErrorList customErrors={getCustomErrors(form.formState.errors.contracts?.[0], backendErrors)} />
      <FormActionButtons isMutating={mutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddPerson
