import {
  GraphqlError,
  useGetCegedimContractDetailByContractIdQuery,
  useUpdateCegedimLabourAgreementMutation
} from '@epix-web-apps/core'
import {
  useFlyIn,
  FormContainer,
  FormGridLayout,
  FormErrorList,
  FormActionButtons,
  FormInput,
  FormDatepicker
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { date, object, string, TypeOf } from 'zod'

/* eslint-disable-next-line */
export interface EditCegedimLabourAgreementProps {
  contractId: string
}

export function EditCegedimLabourAgreement({ contractId }: EditCegedimLabourAgreementProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const theme = useTheme()

  const ccnWebsite = 'https://www.service-public.fr/particuliers/vosdroits/F78'

  const { data: cegedimContractDetail, refetch: refetchPartnerSpecificDetails } =
    useGetCegedimContractDetailByContractIdQuery({
      contractId: contractId
    })
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const updateMutation = useUpdateCegedimLabourAgreementMutation()

  const editCegedimContractTypeSchema = object({
    labourAgreementRank: string().nullable(),
    applicableSince: date().optional().nullable()
  })

  type EditCegedimLabourAgreementForm = TypeOf<typeof editCegedimContractTypeSchema>

  const form = useForm<EditCegedimLabourAgreementForm>({
    resolver: zodResolver(editCegedimContractTypeSchema),
    defaultValues: {
      labourAgreementRank: cegedimContractDetail?.cegedimContractDetailByContractId.labourAgreementRank,
      applicableSince: cegedimContractDetail?.cegedimContractDetailByContractId.applicableSince
      ? new Date(cegedimContractDetail.cegedimContractDetailByContractId.applicableSince)
      : null
    }
  })

  const handleOnSubmit = async (cegedimLabourAgreement: EditCegedimLabourAgreementForm) => {
    await updateMutation
      .mutateAsync({
        updateCegedimLabourAgreementCommand: {
          id: cegedimContractDetail?.cegedimContractDetailByContractId.id || '',
          labourAgreementRank: cegedimLabourAgreement.labourAgreementRank,
          applicableSince: cegedimLabourAgreement.applicableSince
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcegedimlabouragreement.title')}</Typography>
      <FormGridLayout>
        <FormInput sx={12} name="labourAgreementRank" label={t('form.field.labouragreementrank')} />

        <FormDatepicker sx={12} name="applicableSince" label={t('form.field.applicablesince')} />
        <Typography
          sx={{
            pl: '1.25rem',
            pt: '0.25rem',
            fontSize: 'small',
            color: theme.palette.text.secondary
          }}
        >
          {t('flyin.editcegedimlabouragreement.moreinfo')}: {' '}
          <Link href={ccnWebsite} target="_blank" rel="noopener">
            {ccnWebsite}
          </Link>
        </Typography>
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons onCancel={() => closeFlyIn()} isMutating={updateMutation.isLoading} />
    </FormContainer>
  )
}

export default EditCegedimLabourAgreement
