import {
  formTypeSelectOptions,
  ROLE,
  useCanAccess,
  useGetPayrollCodeByIdQuery,
  useUpdatePayrollCodeMutation,
  VALUE_TYPE_KEYS
} from '@epix-web-apps/core'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CanView, FormGridLayout, FormRadioGroup, useFlyIn } from '@epix-web-apps/ui'
import { FormActionButtons, FormErrorList } from '@epix-web-apps/ui'
import { FormContainer } from '@epix-web-apps/ui'
import { TypeOf, object, string } from 'zod'

/* eslint-disable-next-line */
export interface AddEditCalculatedDataSettingsProps {
  employerId: string
  payrollCodeId?: string
}

export function AddEditCalculatedDataSettings({ employerId, payrollCodeId }: AddEditCalculatedDataSettingsProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const addEditPayrollCodeSchema = object({
    valueType: string({
      required_error: t('form.validation.valuetyperequired'),
      invalid_type_error: t('form.validation.valuetyperequired')
    }).min(1, t('form.validation.valuetyperequired')),
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired'))
  })

  type CreateEditPayrollCodeForm = TypeOf<typeof addEditPayrollCodeSchema>
  const { allValueOptions } = formTypeSelectOptions

  const canAccessPayrollCodes = useCanAccess([ROLE.ADMIN, ROLE.MANAGEPAYROLLCODES])

  const { data: getPayrollCodeById, refetch: refetchPayrollCodeById } = useGetPayrollCodeByIdQuery(
    {
      payrollCodeId: payrollCodeId || ''
    },
    {
      enabled: !!payrollCodeId
    }
  )

  const form = useForm<CreateEditPayrollCodeForm>({
    resolver: zodResolver(addEditPayrollCodeSchema),
    defaultValues: {
      valueType: getPayrollCodeById?.payrollCodeById.valueType.key || VALUE_TYPE_KEYS.DEFAULT,
      employerId: employerId
    }
  })

  const updatePayrollCodeCalculatedDataMutationmutation = useUpdatePayrollCodeMutation()

  const handleOnSubmit = async (newPayrollCode: CreateEditPayrollCodeForm) => {
    if (!canAccessPayrollCodes) return
    await updatePayrollCodeCalculatedDataMutationmutation
      .mutateAsync({
        updatePayrollCodeCommand: {
          id: payrollCodeId || '',
          code: getPayrollCodeById?.payrollCodeById.code || '',
          providerCode: getPayrollCodeById?.payrollCodeById.providerCode,
          colourHexCode: getPayrollCodeById?.payrollCodeById.colourCodeHex || '',
          description: getPayrollCodeById?.payrollCodeById.description || '',
          groupTypeKey: getPayrollCodeById?.payrollCodeById.group.key || '',
          subGroupTypeKey: getPayrollCodeById?.payrollCodeById.subGroup?.key || '',
          valueTypeKey: newPayrollCode.valueType,
          employerId: employerId || '',
          reverseSignImport: getPayrollCodeById?.payrollCodeById.reverseSignImport ?? false,
          reverseSignExport: getPayrollCodeById?.payrollCodeById.reverseSignExport ?? false,
          isCalendarEntry: getPayrollCodeById?.payrollCodeById.isCalendarEntry ?? false,
          isSalaryEntry: getPayrollCodeById?.payrollCodeById.isSalaryEntry ?? false,
          isUsableInSelfService: getPayrollCodeById?.payrollCodeById.isUsableInSelfService ?? false,
          needsDocument: getPayrollCodeById?.payrollCodeById.needsDocument ?? false,
          useForBradfordCalculation: getPayrollCodeById?.payrollCodeById.useForBradfordCalculation ?? false,
          userFriendlyDescription: getPayrollCodeById?.payrollCodeById.userFriendlyDescription,
          valueTypePayComponents: (getPayrollCodeById?.payrollCodeById.valueTypePayComponents || []).map(
            item => item.key || item.value
          ),
          exportToProvider: getPayrollCodeById?.payrollCodeById.exportToProvider ?? true,
          salaryEntryTypeKey: getPayrollCodeById?.payrollCodeById.salaryEntryType?.key
        }
      })
      .then(() => {
        refetchPayrollCodeById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditcalculateddata.title')}</Typography>
      <Typography variant="h5">
        {getPayrollCodeById?.payrollCodeById.code} {getPayrollCodeById?.payrollCodeById.userFriendlyDescription}
      </Typography>

      <FormGridLayout>
        <FormRadioGroup
          disabled={!canAccessPayrollCodes}
          sx={12}
          name="valueType"
          label={`${t('form.field.valuetypecalculateddata')}`}
          options={allValueOptions}
        />
      </FormGridLayout>
      <FormErrorList />
      <CanView roles={[ROLE.ADMIN, ROLE.MANAGEPAYROLLCODES]}>
        <FormActionButtons
          isMutating={updatePayrollCodeCalculatedDataMutationmutation.isLoading}
          onCancel={() => closeFlyIn()}
        />
      </CanView>
    </FormContainer>
  )
}

export default AddEditCalculatedDataSettings
