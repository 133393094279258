import { ThemeProvider, CssBaseline } from '@mui/material'
import {
  isOnlySelfServiceUser,
  isSelfServiceUser,
  loadTranslations,
  setFetchHeader,
  useGetMeQuery
} from '@epix-web-apps/core'
import {
  EpixLoadingPage,
  NotMobileOptimized,
  GeneralRoutes,
  SelfService,
  epixTheme,
  useGlobalPersistedStore
} from '@epix-web-apps/ui'
import { environment } from '../environments/environment'
import { useMyEpixRouting } from './myepix-routing'
import { useSelfServiceRouting } from './selfservice-routing'
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { useGeneralRoutes } from './general-routing'
import { DrawerFlyinLayout, DrawerMobileLayout } from './myepix/layouts'
import { ReactNode, useEffect, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorBoundryHandler } from './myepix/components/error-boundry-handler'
import { MsalAuthenticationTemplate, MsalProvider, useMsal } from '@azure/msal-react'
import {
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
  PublicClientApplication
} from '@azure/msal-browser'
import { loginRequest } from '../msal-config'

loadTranslations(environment.i18n)

const AppLayout = () => {
  const { setSelfServiceMode } = useGlobalPersistedStore()
  const { data: me, isLoading: meIsLoading } = useGetMeQuery({}, { suspense: false })
  const matchedSelfServiceRoute = /\/selfservice/.test(window.location.href)
  const [locale, setLocale] = useState<Locale>()

  // remove loading div from index.html when app is loaded
  document.getElementById('loadingpage')?.remove()

  useEffect(() => {
    setSelfServiceMode(false)
    if (me?.me) {
      if (isOnlySelfServiceUser(me.me.roles)) {
        setSelfServiceMode(true)
      }
      const importLocaleFile = async () => {
        // Import only needed translations and fallback if not found.
        await import(`date-fns/locale/${me?.me.locale.locale.substring(0, 2)}/index.js`)
          .then(localeToSet => setLocale(localeToSet.default))
          .catch(async () => {
            await import(`date-fns/locale/${me?.me.locale.locale}/index.js`).then(localeToSet =>
              setLocale(localeToSet.default)
            )
          })
      }
      // If the locale has not yet been loaded.
      if (locale?.code !== me?.me.locale.locale.substring(0, 2)) {
        importLocaleFile()
      }
    }
  }, [locale?.code, me?.me])

  if (!me || meIsLoading) {
    return <EpixLoadingPage message="Performing authorization check..." />
  }

  if (!isSelfServiceUser(me.me.roles) && matchedSelfServiceRoute) {
    return <Navigate to={GeneralRoutes.NOTAUTHORIZED} />
  }

  if (isOnlySelfServiceUser(me.me.roles) && !matchedSelfServiceRoute) {
    return <Navigate to={SelfService.ROOT()} />
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {matchedSelfServiceRoute ? <DrawerMobileLayout /> : <DrawerFlyinLayout />}
      {!matchedSelfServiceRoute && <NotMobileOptimized />}
    </LocalizationProvider>
  )
}

const useAppRoutes = () => {
  const { selfServiceMode } = useGlobalPersistedStore()
  const myEpixRouting = useMyEpixRouting()
  const selfServiceRouting = useSelfServiceRouting()
  const matchedSelfServiceRoute = /\/selfservice/.test(window.location.href)

  if (selfServiceMode || matchedSelfServiceRoute) {
    return selfServiceRouting
  }
  return myEpixRouting
}

const IsAuthenticated = ({ children }: { children: ReactNode }) => {
  const { instance, inProgress, accounts } = useMsal()
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(loginRequest)
        .then(accessTokenResponse => {
          setFetchHeader('Authorization', `Bearer ${accessTokenResponse.accessToken}`)
          setIsAuthenticated(true)
        })
        .catch(error => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect(loginRequest)
          }
          return null
        })
    }
  }, [instance, accounts, inProgress])

  return isAuthenticated && children
}

export function App({ msalInstance }: { msalInstance: PublicClientApplication }) {
  const generalRoutes = useGeneralRoutes()
  const appRoutes = useAppRoutes()

  return (
    <ThemeProvider theme={epixTheme}>
      <CssBaseline />
      <ErrorBoundary FallbackComponent={ErrorBoundryHandler}>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <MsalProvider instance={msalInstance}>
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginRequest}
                    loadingComponent={() => <EpixLoadingPage message="Checking authentication..." />}
                    errorComponent={() => <EpixLoadingPage message="Reinitializing application..." />}
                  >
                    <IsAuthenticated>
                      <AppLayout />
                    </IsAuthenticated>
                  </MsalAuthenticationTemplate>
                </MsalProvider>
              }
            >
              {appRoutes}
            </Route>
            {generalRoutes}
          </Routes>
        </Router>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
