import { Box, Tab, Tabs } from '@mui/material'
import { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CanView, DetailPageBaseQueryParams, LoadingOverlay } from '@epix-web-apps/ui'
import { TabPanel, a11yProps } from '@epix-web-apps/ui'
import SelfserviceAccess from '../selfservice-access'
import { useGlobalStore } from '@epix-web-apps/ui'
import { useParams } from 'react-router-dom'
import { UserDetailConfiguration } from '../user-details-configuration'
import { UserCustomConfiguration } from '../user-custom-configuration'
import { ROLE, useGetTenantFeaturesQuery, useGetUserByIdQuery } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface UserTabGeneralProps {
  userId: string
}

export function UserTabGeneral({ userId }: UserTabGeneralProps) {
  const { t } = useTranslation()
  const { me } = useGlobalStore()
  const params = useParams<DetailPageBaseQueryParams>()
  const [tabIndex, setTabIndex] = useState(0)

  const { data: tenantFeatures } = useGetTenantFeaturesQuery()

  const { data: getUserById } = useGetUserByIdQuery(
    {
      userId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )

  const hasRoleCustom = getUserById?.userById?.roles?.some(role => role.toUpperCase() === ROLE.CUSTOM)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={(e, tabIndex) => setTabIndex(tabIndex)}>
          <Tab label={t('userdetailpage.tab.userinfo')} {...a11yProps(0)} />
          {me?.id !== params.id && hasRoleCustom && (
            <Tab label={t('userdetailpage.tab.usercustom')} {...a11yProps(1)} />
          )}
          {tenantFeatures?.tenantFeatures.hasSelfService && me?.id !== params.id && (
            <Tab label={t('userdetailpage.tab.selfservice')} {...a11yProps(hasRoleCustom ? 2 : 1)} />
          )}
        </Tabs>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <TabPanel value={tabIndex} index={0}>
          <Suspense fallback={<LoadingOverlay />}>
            <UserDetailConfiguration />
          </Suspense>
        </TabPanel>
        {hasRoleCustom && (
          <CanView hideOnOwnUser={params.id}>
            <TabPanel value={tabIndex} index={1}>
              <Suspense fallback={<LoadingOverlay />}>
                <UserCustomConfiguration />
              </Suspense>
            </TabPanel>
          </CanView>
        )}
        {tenantFeatures?.tenantFeatures.hasSelfService && (
          <CanView hideOnOwnUser={params.id}>
            <TabPanel value={tabIndex} index={hasRoleCustom ? 2 : 1}>
              <Suspense fallback={<LoadingOverlay />}>
                <SelfserviceAccess />
              </Suspense>
            </TabPanel>
          </CanView>
        )}
      </Box>
    </Box>
  )
}

export default UserTabGeneral
