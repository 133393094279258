import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, ScrollableDataTable, SortModel } from '../../data-table'
import {
  DocumentModel,
  DocumentTypeWithAllTranslationsModel,
  downloadFile,
  OrderByAddedOnFolders,
  OrderDirection,
  useGetContractDocumentTypeByIdQuery,
  useGetContractDocumentTypeWithDocumentsByIdQuery,
  useGetDocumentBlobByIdQuery,
  useGetEmployerDocumentTypeByIdQuery,
  useGetEmployerDocumentTypeWithDocumentsByIdQuery
} from '@epix-web-apps/core'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, CircularProgress, Divider, IconButton, Link, MenuItem, Typography } from '@mui/material'
import DropdownMenu from '../../dropdown-menu'
import { useFlyIn } from '@epix-web-apps/ui'
import DataTableEmpty from '../../data-table/data-table-empty'
import AddDocument from '../add-document'
import RemoveDocument from '../remove-document'
import EditDocument from '../edit-document'

export enum DocumentColumns {
  NAME = 'name',
  CREATEDON = 'addedOn',
  ROWACTIONS = 'rowactions'
}

export const DefineSortModel = (documentType: DocumentTypeWithAllTranslationsModel | undefined) => {
  const defaultSorting = OrderByAddedOnFolders.includes(documentType?.key || '')
    ? DocumentColumns.CREATEDON
    : DocumentColumns.NAME
  const defaultOrderDirection = OrderByAddedOnFolders.includes(documentType?.key || '')
    ? OrderDirection.Desc
    : OrderDirection.Asc

  return new SortModel(defaultSorting, defaultOrderDirection)
}

export interface BaseFolderDocumentListProps {
  documentTypeId: string
  title: string
  subTitle?: string
  onTitleClick?: () => void
  isScrollable?: boolean
}

export interface ContractFolderDocumentListProps extends BaseFolderDocumentListProps {
  contractId?: string
}

export type EmployerFolderDocumentListProps = BaseFolderDocumentListProps

export interface FolderDocumentListProps extends BaseFolderDocumentListProps {
  sendSortModel: (model: SortModel) => void
  documentTypeWithDocuments: DocumentModel[]
  sortModel: SortModel
  refetch: any
  contractId?: string
}

export function ContractFolderDocumentList({
  documentTypeId,
  title,
  subTitle,
  onTitleClick,
  contractId,
  isScrollable
}: ContractFolderDocumentListProps) {
  const { data: documentType } = useGetContractDocumentTypeByIdQuery({
    id: documentTypeId
  })

  const [sortModelFromChild, setSortModelFromChild] = useState(DefineSortModel(documentType?.contractDocumentTypeById))

  const handleSortModel = (model: SortModel) => {
    setSortModelFromChild(model)
  }
  const sortModel = DefineSortModel(documentType?.contractDocumentTypeById)

  const { data: getDocumentTypeWithDocuments, refetch: refetchDocumentTypeWithDocuments } =
    useGetContractDocumentTypeWithDocumentsByIdQuery(
      {
        documentTypeId: documentTypeId,
        contractId: contractId || '',
        sortByProperty: sortModelFromChild.field,
        orderDirection: sortModelFromChild.orderDirection
      },
      {
        enabled: !!documentTypeId && !!documentType
      }
    )

  return (
    <FolderDocumentList
      sendSortModel={handleSortModel}
      refetch={refetchDocumentTypeWithDocuments}
      documentTypeId={documentTypeId}
      contractId={contractId}
      sortModel={sortModel}
      documentTypeWithDocuments={getDocumentTypeWithDocuments?.contractDocumentTypeWithDocumentsById.documents ?? []}
      title={title}
      subTitle={subTitle}
      onTitleClick={onTitleClick}
      isScrollable={isScrollable}
    />
  )
}

export function EmployerFolderDocumentList({
  documentTypeId,
  title,
  subTitle,
  onTitleClick,
  isScrollable
}: EmployerFolderDocumentListProps) {
  const { data: documentType } = useGetEmployerDocumentTypeByIdQuery({
    id: documentTypeId
  })

  const [sortModelFromChild, setSortModelFromChild] = useState(DefineSortModel(documentType?.employerDocumentTypeById))

  function handleSortModel(model: SortModel) {
    setSortModelFromChild(model)
  }
  const sortModel = DefineSortModel(documentType?.employerDocumentTypeById)

  const { data: getDocumentTypeWithDocuments, refetch: refetchDocumentTypeWithDocuments } =
    useGetEmployerDocumentTypeWithDocumentsByIdQuery(
      {
        documentTypeId: documentTypeId,
        sortByProperty: sortModelFromChild.field,
        orderDirection: sortModelFromChild.orderDirection
      },
      {
        enabled: !!documentTypeId && !!documentType
      }
    )

  return (
    <FolderDocumentList
      sendSortModel={handleSortModel}
      refetch={refetchDocumentTypeWithDocuments}
      sortModel={sortModel}
      documentTypeId={documentTypeId}
      documentTypeWithDocuments={getDocumentTypeWithDocuments?.employerDocumentTypeWithDocumentsById.documents ?? []}
      title={title}
      subTitle={subTitle}
      onTitleClick={onTitleClick}
      isScrollable={isScrollable}
    />
  )
}

export function FolderDocumentList({
  documentTypeWithDocuments,
  documentTypeId,
  sortModel,
  contractId,
  title,
  subTitle,
  refetch,
  sendSortModel,
  onTitleClick,
  isScrollable
}: FolderDocumentListProps) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [clickedDocumentId, setClickedDocumentId] = useState<string | null>(null)
  const { openFlyIn } = useFlyIn()

  function RowActions(rowParams: GridRenderCellParams) {
    const { refetch, isFetching } = useGetDocumentBlobByIdQuery(
      { id: rowParams.row.id },
      { enabled: false, suspense: false }
    )
    return (
      <>
        <IconButton
          aria-label="row actions"
          aria-controls="menu-row"
          aria-haspopup="true"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setAnchorEl(e.currentTarget)
            if (rowParams.row.id) {
              setClickedDocumentId(rowParams.row.id)
            }
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            refetch().then(r => {
              if (r.data) {
                downloadFile(
                  r.data.documentBlobById.blob,
                  r.data.documentBlobById.name,
                  r.data.documentBlobById.contentType
                )
              }
            })
          }}
          aria-label="row details"
          aria-controls="details-row"
        >
          {isFetching ? <CircularProgress size={25} /> : <CloudDownloadOutlinedIcon />}
        </IconButton>
      </>
    )
  }

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: DocumentColumns.NAME,
        headerName: t('document.datatable.column.name'),
        flex: 1,
        sortable: true
      },
      {
        field: DocumentColumns.CREATEDON,
        headerName: t('document.datatable.column.createdon'),
        flex: 1,
        sortable: true
      },
      {
        field: DocumentColumns.ROWACTIONS,
        headerName: '',
        sortable: false,
        editable: false,
        renderCell: RowActions
      }
    ],
    []
  )

  const handleSortModel = (model: SortModel) => {
    sendSortModel(model)
  }

  const filterBar = (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1
          }}
        >
          <Link onClick={onTitleClick}>
            <Typography m={0} variant="h4">
              {title}
            </Typography>
          </Link>
          {subTitle && (
            <Typography m={0} variant="h4">
              {subTitle}
            </Typography>
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, paddingBottom: '0.5rem' }}>
          <Button
            variant="contained"
            onClick={() =>
              openFlyIn({
                content: <AddDocument documentTypeId={documentTypeId} contractId={contractId} />,
                callbackAfterClose: () => refetch
              })
            }
          >
            <AddIcon />
            {t('documentlist.button.adddocument')}
          </Button>
        </Box>
      </Box>
      <Divider />
    </>
  )

  const tableProps = {
    data: documentTypeWithDocuments ?? [],
    onRowClick: (params: { row: { id: SetStateAction<string | null> } }) =>
      params.row.id && setClickedDocumentId(params.row.id),
    columns: columns,
    onSortChange: (model: SortModel) => handleSortModel(model),
    hideFooter: true,
    isLoading: false,
    filterBarElement: filterBar,
    sortModel: sortModel,
    emptyStateElement: (
      <Box sx={{ py: '2rem' }}>
        <DataTableEmpty title={t('emptystate.folderdocuments')} />
      </Box>
    )
  }

  return (
    <>
      {isScrollable ? <ScrollableDataTable {...tableProps} /> : <DataTable {...tableProps} />}
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <EditDocument documentId={clickedDocumentId || ''} />,
              callbackAfterClose: () => refetch
            })
          }
        >
          {t('common.edit')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <RemoveDocument documentId={clickedDocumentId || ''} />,
              callbackAfterClose: () => refetch
            })
          }
        >
          {t('common.remove')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default FolderDocumentList
