import {
  FormSelectOption,
  GraphqlError,
  PARTNER_LIST_IDENTIFIERS,
  useGetAllPartnerContractDetailTypesByListIdentifierQuery,
  useGetSecurexContractDetailByContractIdQuery,
  useUpdateSecurexContractTypeMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { date, object, string, TypeOf } from 'zod'

/* eslint-disable-next-line */
export interface EditSecurexContractTypeProps {
  contractId: string
}

export function EditSecurexContractType({ contractId }: EditSecurexContractTypeProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: securexContractDetail, refetch: refetchPartnerSpecificDetails } =
    useGetSecurexContractDetailByContractIdQuery({
      contractId: contractId
    })

  const { data: iscoCodeTypesData } = useGetAllPartnerContractDetailTypesByListIdentifierQuery({
    listIdentifier: PARTNER_LIST_IDENTIFIERS.LU_ISCO_CODE,
    limit: -1,
    offset: 0
  })

  const { data: absenceReasonTypesData } = useGetAllPartnerContractDetailTypesByListIdentifierQuery({
    listIdentifier: PARTNER_LIST_IDENTIFIERS.LU_ABSENCE_REASON,
    limit: -1,
    offset: 0
  })

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const updateMutation = useUpdateSecurexContractTypeMutation()

  const iscoCodeTypes = iscoCodeTypesData?.allPartnerContractDetailTypesByListIdentifier.data.map(
    i => new FormSelectOption(i.id, i.value)
  )
  const absenceReasonTypes = absenceReasonTypesData?.allPartnerContractDetailTypesByListIdentifier.data.map(
    a => new FormSelectOption(a.id, a.value)
  )

  const editSecurexContractTypeSchema = object({
    iscoCodeTypeId: string().nullable().optional(),
    absenceReasonTypeId: string().nullable().optional(),
    absenceStartDate: date().optional().nullable(),
    resumptionDate: date().optional().nullable()
  })

  type EditSecurexContractTypeForm = TypeOf<typeof editSecurexContractTypeSchema>

  const form = useForm<EditSecurexContractTypeForm>({
    resolver: zodResolver(editSecurexContractTypeSchema),
    defaultValues: {
      iscoCodeTypeId: securexContractDetail?.securexContractDetailByContractId.iscoCodeType?.id,
      absenceReasonTypeId: securexContractDetail?.securexContractDetailByContractId.absenceReasonType?.id,
      absenceStartDate: securexContractDetail?.securexContractDetailByContractId.absenceStartDate
        ? new Date(securexContractDetail.securexContractDetailByContractId.absenceStartDate)
        : null,
      resumptionDate: securexContractDetail?.securexContractDetailByContractId.resumptionDate
        ? new Date(securexContractDetail.securexContractDetailByContractId.resumptionDate)
        : null
    }
  })

  const handleOnSubmit = (securexContractType: EditSecurexContractTypeForm) => {
    updateMutation
      .mutateAsync({
        updateSecurexContractTypeCommand: {
          id: securexContractDetail?.securexContractDetailByContractId.id || '',
          iscoCodeTypeId: securexContractType.iscoCodeTypeId,
          absenceReasonTypeId: securexContractType.absenceReasonTypeId,
          absenceStartDate: securexContractType.absenceStartDate,
          resumptionDate: securexContractType.resumptionDate
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editsecurexcontracttype.title')}</Typography>
      <FormGridLayout>
        <Grid xs={12} item>
          <FormSelect sx={12} name="iscoCodeTypeId" label={t('form.field.securex.iscocode')} options={iscoCodeTypes} />
          <Typography variant="description" sx={{ ml: 1 }}>
            {t('flyin.editsecurexcontracttype.iscocodeinfo')}
          </Typography>
        </Grid>
        <FormSelect
          sx={12}
          name="absenceReasonTypeId"
          label={t('form.field.securex.absencereason')}
          options={absenceReasonTypes}
        />
        <FormDatepicker sx={12} name="absenceStartDate" label={t('form.field.securex.absencestartdate')} />
        <FormDatepicker sx={12} name="resumptionDate" label={t('form.field.securex.resumptiondate')} />
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons onCancel={() => closeFlyIn()} isMutating={updateMutation.isLoading} />
    </FormContainer>
  )
}

export default EditSecurexContractType
