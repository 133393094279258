import { Box, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import {
  DataGrid,
  GridCallbackDetails,
  GridRowsProp,
  GridRowParams,
  GridColumnVisibilityModel,
  GridRowSelectionModel,
  GridDensity,
  GridColDef,
  GridPaginationModel,
  GridSortItem,
  GridSortDirection
} from '@mui/x-data-grid'
import { OrderDirection } from '@epix-web-apps/core'
import DataTableEmpty from './data-table-empty/data-table-empty'

export const usePaginationModel = (page = 0, pageSize = 25) => {
  const [_paginationModel, _setPaginationModel] = useState<PaginationModel>({ page, pageSize, offset: page * pageSize })

  const setPaginationModel = (pageModel: GridPaginationModel) =>
    _setPaginationModel({
      page: pageModel.page,
      pageSize: pageModel.pageSize,
      offset: pageModel.page * pageModel.pageSize
    })
  const resetPaginationModel = () => _setPaginationModel({ page, pageSize, offset: page * pageSize })

  return { paginationModel: _paginationModel, setPaginationModel, resetPaginationModel }
}

export class PaginationModel implements GridPaginationModel {
  page: number
  pageSize: number
  offset: number

  constructor(page: number, pageSize = 25) {
    this.page = page
    this.pageSize = pageSize
    this.offset = page * pageSize
  }
}

export class SortModel implements GridSortItem {
  field: string
  orderDirection: OrderDirection
  sort: GridSortDirection

  constructor(field: string, orderDirection: OrderDirection) {
    this.field = field
    this.orderDirection = orderDirection
    this.sort = orderDirection === OrderDirection.Asc ? 'asc' : 'desc'
  }
}

/* eslint-disable-next-line */
export interface DataTableProps {
  data?: GridRowsProp
  totalRowCount?: number
  columns: GridColDef[]
  density?: GridDensity
  sortModel: SortModel
  onSortChange?: (model: SortModel) => void
  paginationModel?: GridPaginationModel
  onPaginationModelChange?: (model: PaginationModel) => void
  onRowClick?: (params: GridRowParams) => void
  isLoading: boolean
  checkboxSelection?: boolean
  onSelectionModelChange?: (selectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => void
  selectionModel?: GridRowSelectionModel
  filterBarElement?: React.ReactNode | null
  hideFooter?: boolean
  columnsToHide?: GridColumnVisibilityModel
  emptyStateElement?: React.ReactNode
  isRowSelectable?: (params: GridRowParams) => boolean
  disableRowSelectionOnClick?: boolean
}

export function DataTable({ filterBarElement, ...props }: DataTableProps) {
  return (
    <Box sx={{ height: '100%' }}>
      {filterBarElement}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <BaseDataTable {...props} />
      </div>
    </Box>
  )
}

export function ScrollableDataTable({
  vHeightOffset = '8rem',
  filterBarElement,
  ...props
}: DataTableProps & {
  vHeightOffset?: number | string
}) {
  const theme = useTheme()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [containerHeight, setContainerHeight] = useState<string | number>('100vh')

  useEffect(() => {
    const target = containerRef.current
    const observer = new ResizeObserver(entries => {
      setContainerHeight(entries[0].contentRect.height)
    })
    if (target) {
      observer.observe(target)
    }
    return () => {
      observer.disconnect()
    }
  }, [containerRef])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${vHeightOffset})`,
        p: 1
      }}
    >
      {filterBarElement}
      <div
        style={{
          color: theme.palette.primary.main,
          flex: 1,
          position: 'relative',
          minHeight: 0
        }}
        ref={containerRef}
      >
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 0, maxHeight: containerHeight }}>
          <BaseDataTable {...props} />
        </div>
      </div>
    </Box>
  )
}

export function BaseDataTable({
  data,
  columns,
  totalRowCount,
  sortModel,
  density = 'standard',
  onSortChange,
  onPaginationModelChange,
  paginationModel,
  onRowClick,
  isLoading,
  checkboxSelection = false,
  onSelectionModelChange,
  selectionModel,
  hideFooter = false,
  columnsToHide,
  emptyStateElement,
  isRowSelectable,
  disableRowSelectionOnClick = false
}: DataTableProps) {
  return data && data.length === 0 ? (
    emptyStateElement ? (
      emptyStateElement
    ) : (
      <DataTableEmpty />
    )
  ) : (
    isLoading ||
      (data && (
        <DataGrid
          density={density}
          isRowSelectable={params => (isRowSelectable ? isRowSelectable(params) : true)}
          disableRowSelectionOnClick={disableRowSelectionOnClick}
          rows={data}
          columns={columns}
          sortingOrder={['desc', 'asc']}
          sortingMode="server"
          sortModel={[sortModel]}
          onSortModelChange={model => {
            onSortChange &&
              onSortChange(
                new SortModel(model[0]?.field, model[0]?.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc)
              )
          }}
          paginationModel={paginationModel}
          paginationMode={totalRowCount ? 'server' : 'client'}
          rowCount={totalRowCount}
          onPaginationModelChange={model => {
            onPaginationModelChange && onPaginationModelChange(new PaginationModel(model.page, model.pageSize))
          }}
          onRowClick={(params, event) => {
            event.defaultMuiPrevented = true
            onRowClick && onRowClick(params)
          }}
          checkboxSelection={checkboxSelection}
          onRowSelectionModelChange={onSelectionModelChange}
          rowSelectionModel={selectionModel}
          disableColumnMenu={true}
          loading={isLoading}
          sx={{
            '& .MuiDataGrid-columnHeadersInner': {
              color: 'primary.main'
            },
            '& .MuiDataGrid-cell': {
              minHeight: '100%',
              display: 'flex',
              alignItems: 'center',
              '& :first-of-type': {
                display: 'flex',
                alignItems: 'center'
              }
            },
            border: 'none'
          }}
          hideFooter={hideFooter}
          columnVisibilityModel={columnsToHide}
        />
      ))
  )
}
