import {
  FormSelectOption,
  numericString,
  OrderDirection,
  useCreateSmartInputCodeMutation,
  useGetActiveValueTypesPayComponentByPayrollCodeIdQuery,
  useGetAllPayrollCodesByEmployerIdQuery,
  useGetSmartInputDefaultValueTypesQuery,
  useGetSmartInputManagedByTypesQuery
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormRadioGroup,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { number, object, string, TypeOf } from 'zod'

export interface AddSmartInputPayComponentProps {
  smartComponentHistoryId: string
  employerId: string
}

function AddSmartInputPayComponent({ smartComponentHistoryId, employerId }: AddSmartInputPayComponentProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: getPayrollcodes } = useGetAllPayrollCodesByEmployerIdQuery({
    employerId: employerId,
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc,
    payrollCodeFilterModel: {
      showSalaryCodes: true
    }
  })

  const { data: getInputCodeDefaultValueTypes } = useGetSmartInputDefaultValueTypesQuery()
  const { data: getInputCodeManagedByTypes } = useGetSmartInputManagedByTypesQuery()

  const addSmartInputPayComponentSchema = object({
    payrollCodeId: string(),
    valueTypePayComponentKey: string(),
    managedByTypeKey: string(),
    defaultValueTypeKey: string(),
    defaultValue: numericString(
        number({
          required_error: t('form.validation.valuerequired'),
          invalid_type_error: t('form.validation.valuemustbenumeric')
        }).positive({ message: t('form.validation.valuegreaterthen0') }))
  })

  type CreateSmartInputPayComponent = TypeOf<typeof addSmartInputPayComponentSchema>

  const form = useForm<CreateSmartInputPayComponent>({
    resolver: zodResolver(addSmartInputPayComponentSchema)
  })

  const { control } = form

  const watchedPayrollCodeId = useWatch({
    control,
    name: 'payrollCodeId'
  })

  const { data: allActiveValueTypePayComponents } = useGetActiveValueTypesPayComponentByPayrollCodeIdQuery(
    {
      payrollCodeId: watchedPayrollCodeId || ''
    },
    {
      enabled: !!watchedPayrollCodeId,
      suspense: false,
      onSuccess: data => {
        form.resetField('valueTypePayComponentKey', {
          defaultValue: data.activeValueTypePayComponentByPayrollCodeId[0]?.key
        })
      }
    }
  )

  const createMutation = useCreateSmartInputCodeMutation()

  const handleOnSubmit = (newSmartInputPayComponent: CreateSmartInputPayComponent) => {
    createMutation
      .mutateAsync({
        createSmartInputCodeCommand: {
          smartPayComponentHistoryId: smartComponentHistoryId,
          payrollCodeId: newSmartInputPayComponent.payrollCodeId,
          valueTypePayComponentKey: newSmartInputPayComponent.valueTypePayComponentKey,
          managedByTypeKey: newSmartInputPayComponent.managedByTypeKey,
          defaultValueTypeKey: newSmartInputPayComponent.defaultValueTypeKey,
          defaultValue: newSmartInputPayComponent.defaultValue
        }
      })
      .then(closeFlyIn)
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addsmartinputpaycomponent.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="payrollCodeId"
          label={`${t('form.field.payrollcode')} *`}
          options={getPayrollcodes?.allPayrollCodesByEmployerId.data.map(
            p => new FormSelectOption(p.id, `${p.code} -  ${p.userFriendlyDescription ?? p.description}`)
          )}
        />

        <FormSelect
          sx={12}
          name="valueTypePayComponentKey"
          label={`${t('form.field.valuetype')} *`}
          options={allActiveValueTypePayComponents?.activeValueTypePayComponentByPayrollCodeId.map(
            v => new FormSelectOption(v.key, v.value)
          )}
        />

        <FormRadioGroup
          sx={12}
          name="managedByTypeKey"
          label={`${t('flyin.addsmartinputpaycomponent.managedby')} *`}
          options={getInputCodeManagedByTypes?.allSmartInputCodeManagedByTypes.map(
            m => new FormSelectOption(m.key, m.value)
          )}
        />

        <FormInput sx={12} name="defaultValue" label={t('flyin.addsmartinputpaycomponent.defaultvalue')} />

        <FormRadioGroup
          sx={12}
          name="defaultValueTypeKey"
          label={`${t('flyin.addsmartinputpaycomponent.defaultvaluetype')} *`}
          options={getInputCodeDefaultValueTypes?.allSmartInputCodeDefaultValueTypes.map(
            m => new FormSelectOption(m.key, m.value)
          )}
        />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons isMutating={createMutation.isLoading} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddSmartInputPayComponent
