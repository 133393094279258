import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, IconButton, MenuItem } from '@mui/material'
import { Configuration, HeaderTitleNavigation } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import { SortModel, usePaginationModel, ScrollableDataTable } from '../../components/data-table'
import { OrderDirection, useGetAllFunctionsQuery } from '@epix-web-apps/core'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useFlyIn } from '@epix-web-apps/ui'
import AddEditRole from '../../components/organisational-structure-components/add-edit-role/add-edit-role'
import DropdownMenu from '../../components/dropdown-menu/dropdown-menu'
import { useSearchParams } from 'react-router-dom'

/* eslint-disable-next-line */
export interface RolesPageProps {}

export function RolesPage(props: RolesPageProps) {
  const [tableData, setTableData] = useState<GridRowsProp>()
  const { t } = useTranslation()
  const [rowCount, setRowCount] = useState(0)
  const { paginationModel, setPaginationModel } = usePaginationModel()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [roleId, setRoleId] = useState('')
  const { openFlyIn } = useFlyIn()
  const [searchParams] = useSearchParams()
  const teamId = searchParams.get('teamId')

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2]
      }}
    >
      <HeaderTitleNavigation backToLink={Configuration.TEAM_ID_TAB(teamId || '', 1)} title={t('rolespage.title')} />
      <Button
        variant="contained"
        onClick={() =>
          openFlyIn({
            content: <AddEditRole />,
            callbackAfterClose: () => refetchAllFunctions
          })
        }
      >
        <AddIcon />
        {t('userspage.button.addrole')}
      </Button>
    </Box>
  )

  const rowActions = (rowParams: GridRenderCellParams) => (
    <>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
          setRoleId(rowParams.row.id)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <IconButton aria-label="row details" aria-controls="details-row"></IconButton>
    </>
  )

  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName: t('rolespage.datatable.column.description'),
      flex: 1,
      sortable: true
    },
    {
      field: 'code',
      headerName: t('rolespage.datatable.column.code'),
      flex: 1,
      sortable: true
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns[0]?.field, OrderDirection.Asc))

  const {
    data: functions,
    isLoading: isLoadingFunctions,
    refetch: refetchAllFunctions
  } = useGetAllFunctionsQuery(
    {
      offset: paginationModel.offset,
      limit: paginationModel.pageSize,
      sortByProperty: sortModel.field,
      orderDirection: sortModel.orderDirection
    },
    {
      suspense: false
    }
  )

  useEffect(() => {
    if (functions && functions.allFunctions) {
      setTableData(
        functions.allFunctions.data.map(row => {
          return {
            id: row.id,
            code: row.code,
            description: row.description
          }
        })
      )
      setRowCount(functions.allFunctions.data.length)
    }
  }, [functions])

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        isLoading={isLoadingFunctions}
        sortModel={sortModel}
        onSortChange={setSortModel}
        filterBarElement={filterBar}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <AddEditRole roleId={roleId} />,
              callbackAfterClose: () => refetchAllFunctions
            })
          }
        >
          {t('functionlevelspage.list.row.menu.edit-role')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default RolesPage
