import {
    useDeleteSmartPayComponentMutation
  } from '@epix-web-apps/core'
  import { FormActionButtons, FormContainer, useFlyIn } from '@epix-web-apps/ui'
  import { Box, Divider, Typography } from '@mui/material'
  import { useForm } from 'react-hook-form'
  import { useTranslation } from 'react-i18next'
  
  interface RemoveSmartPayComponentProps {
    smartPayComponentId: string
    description: string
  }
  
  function RemoveSmartPayComponent({ smartPayComponentId, description }: RemoveSmartPayComponentProps) {
    const { t } = useTranslation()
    const { closeFlyIn } = useFlyIn();
  
    const form = useForm()
    const deleteMutation = useDeleteSmartPayComponentMutation()
  
    function handleDelete() {
      deleteMutation
        .mutateAsync({
          deleteSmartPayComponentCommand: {
            id: smartPayComponentId
          }
        })
        .then(closeFlyIn)
    }
  
    return (
      <FormContainer form={form} onSubmit={form.handleSubmit(handleDelete)}>
        <Typography variant="h4">{t('removesmartpaycomponent.delete.title')}</Typography>
  
        <Box sx={{ pb: '1rem' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <Typography>{description}</Typography>
          </Box>
          <Divider sx={{ my: '0.5rem' }} />
        </Box>
  
        <FormActionButtons
          isMutating={deleteMutation.isLoading}
          buttonText={t('common.delete')}
          onCancel={() => closeFlyIn()}
        />
      </FormContainer>
    )
  }
  export default RemoveSmartPayComponent
  