import { OrderDirection, useGetAllCountryPoliciesQuery, useGetAllIcpsQuery } from '@epix-web-apps/core'
import { Box, Button, IconButton, MenuItem } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DetailPageBaseQueryParams, useFlyIn } from '@epix-web-apps/ui'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { DataTable, SortModel } from '../../data-table'
import AddIcon from '@mui/icons-material/Add'
import DropdownMenu from '../../dropdown-menu'
import DeleteCountryPolicy from '../../country-policy-components/delete-country-policy'
import AddCountryPolicy from '../../country-policy-components/add-country-policy'

/* eslint-disable-next-line */
export interface UserCountryPoliciesProps {}

export function UserCountryPolicies(props: UserCountryPoliciesProps) {
  const [tableData, setTableData] = useState<GridRowsProp>()
  const params = useParams<DetailPageBaseQueryParams>()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [countryPolicyId, setCountryPolicyId] = useState('')
  const [icpId, setIcpId] = useState('')

  const rowActions = (rowParams: GridRenderCellParams) => (
    <>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
          setCountryPolicyId(rowParams.row.id)
          setIcpId(rowParams.row.icpId)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <IconButton aria-label="row details" aria-controls="details-row"></IconButton>
    </>
  )

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right'
      }}
    >
      <Button
        variant="contained"
        onClick={() =>
          openFlyIn({
            content: <AddCountryPolicy />,
            callbackAfterClose: () => refetchAllCountryPolicies
          })
        }
      >
        <AddIcon />
        {t('usercountrypolicy.button.add-countrypolicy')}
      </Button>
    </Box>
  )

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: t('usercountrypolicies.datatable.column.code'),
      flex: 1,
      sortable: true
    },
    {
      field: 'payrollprovider',
      headerName: t('usercountrypolicies.datatable.column.name'),
      flex: 1
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns?.[0].field, OrderDirection.Asc))

  const { data: icpsdata } = useGetAllIcpsQuery(
    {
      activeOnly: false,
      offset: 0,
      limit: -1,
      sortByProperty: sortModel.field,
      orderDirection: sortModel.orderDirection
    },
    {
      suspense: false
    }
  )
  const {
    data: countryPoliciesData,
    isLoading: isLoadingCountryPolicies,
    refetch: refetchAllCountryPolicies
  } = useGetAllCountryPoliciesQuery(
    {
      userId: params.id || '',
      offset: 0,
      limit: -1,
      sortByProperty: sortModel.field,
      orderDirection: sortModel.orderDirection
    },
    {
      suspense: false
    }
  )

  useEffect(() => {
    if (icpsdata && icpsdata?.icps.data) {
      const icps = icpsdata?.icps.data
      if (countryPoliciesData && countryPoliciesData?.allCountryPolicies) {
        const countryPolicies = countryPoliciesData?.allCountryPolicies
        setTableData(
          countryPolicies.data
            .map(row => {
              let fullData: {
                code: string | undefined
                payrollProvider: string | undefined
              }[] = icps
                .filter(i => i.id === row.icpId)
                .map(i => {
                  return { code: i.code, payrollProvider: i.payrollProvider }
                })

              fullData = fullData.length > 0 ? fullData : [{ code: row.icpId, payrollProvider: row.icpId }]
              return fullData.map(d => {
                return {
                  id: row.id,
                  code: d.code,
                  payrollprovider: d.payrollProvider,
                  icpId: row.icpId
                }
              })
            })
            .reduce((acc, n) => [...acc, ...n], [])
        )
      }
    }
  }, [countryPoliciesData, icpsdata])

  return (
    <>
      <DataTable
        data={tableData}
        columns={columns}
        isLoading={isLoadingCountryPolicies}
        sortModel={sortModel}
        onSortChange={setSortModel}
        filterBarElement={filterBar}
        hideFooter={true}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <DeleteCountryPolicy countryPolicyId={countryPolicyId} icpId={icpId} />,
              callbackAfterClose: () => refetchAllCountryPolicies
            })
          }
        >
          {t('usercountrypolicies.list.row.menu.delete-countrypolicy')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default UserCountryPolicies
