import {
  EditTranslationZodArray,
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormTranslations,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'
import {
  useCreateEmployerDocumentTypeMutation,
  useGetEmployerDocumentTypeByIdQuery,
  useUpdateEmployerDocumentTypeMutation
} from '@epix-web-apps/core'
import { FormSwitch } from '../../form-components/form-switch'
import { useParams } from 'react-router-dom'
import { CompanyDocumentTypesDetailPageParams } from '../../../pages/company-documenttypes-page/company-documenttypes-page'

/* eslint-disable-next-line */
export interface EditCompanyDocumenttypeProps {
  documentTypeId?: string
}

export function AddEditCompanyDocumenttype({ documentTypeId }: EditCompanyDocumenttypeProps) {
  const { t } = useTranslation()

  const params = useParams<CompanyDocumentTypesDetailPageParams>()

  const addEditCompanyDocumentTypeSchema = object({
    active: boolean(),
    name: string({
      required_error: t('form.validation.namerequired'),
      invalid_type_error: t('form.validation.namerequired')
    }).min(1, t('form.validation.namerequired')),
    translations: EditTranslationZodArray()
  })

  const updateMutation = useUpdateEmployerDocumentTypeMutation()
  const createMutation = useCreateEmployerDocumentTypeMutation()

  const { data: getDocumentType, refetch } = useGetEmployerDocumentTypeByIdQuery(
    {
      id: documentTypeId || ''
    },
    {
      enabled: !!documentTypeId
    }
  )
  const { closeFlyIn } = useFlyIn()

  type AddEditCompanyDocumentTypeForm = TypeOf<typeof addEditCompanyDocumentTypeSchema>

  const form = useForm<AddEditCompanyDocumentTypeForm>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: getDocumentType?.employerDocumentTypeById.nameDefaultValue,
      active: getDocumentType?.employerDocumentTypeById?.active,
      translations: getDocumentType?.employerDocumentTypeById?.nameTranslations
    },
    resolver: zodResolver(addEditCompanyDocumentTypeSchema)
  })

  const handleOnSubmit = async (addedEditedCompanyDocumentType: AddEditCompanyDocumentTypeForm) => {
    if (documentTypeId) {
      await updateMutation
        .mutateAsync({
          updateEmployerDocumentTypeCommand: {
            id: documentTypeId,
            active: addedEditedCompanyDocumentType.active,
            name: addedEditedCompanyDocumentType.name,
            employeeAccessLevelTypeKey: getDocumentType?.employerDocumentTypeById?.employeeAccessLevel.key || '',
            translations: addedEditedCompanyDocumentType.translations
          }
        })
        .finally(() => {
          refetch()
          closeFlyIn()
        })
    } else {
      await createMutation
        .mutateAsync({
          createEmployerDocumentTypeCommand: {
            employerId: params.employerid || '',
            name: addedEditedCompanyDocumentType.name || '',
            active: addedEditedCompanyDocumentType.active,
            translations: addedEditedCompanyDocumentType.translations
          }
        })
        .finally(() => {
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      {documentTypeId ? (
        <Typography variant="h4">{t('flyin.editcompanydocumenttype.edittitle')}</Typography>
      ) : (
        <Typography variant="h4">{t('flyin.editcompanydocumenttype.addtitle')}</Typography>
      )}
      <FormGridLayout>
        <FormSwitch sx={12} name="active" label={t('form.field.active')} />
        <FormInput sx={12} name="name" label={`${t('form.field.name')} *`} />
      </FormGridLayout>
      <FormTranslations label={t('flyin.editcompanydocumenttype.foldername')} name={'translations'} />
      <FormErrorList />
      <FormActionButtons
        isMutating={updateMutation.isLoading || createMutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditCompanyDocumenttype
