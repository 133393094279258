import { Box, Typography, Paper } from '@mui/material'
import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfiedOutlined'
import { AuthError, GraphqlError } from '@epix-web-apps/core'
import { environment } from '../../../../../../environments/environment'
import { useTranslation } from 'react-i18next'

export interface PayrollClosureErrorBoundryHandlerProps {
  error: Error | AuthError | GraphqlError
}

export function PayrollClosureErrorBoundaryHandler({ error }: PayrollClosureErrorBoundryHandlerProps) {
  const { t } = useTranslation()

  const errorMessage = error?.message || t('error.unknown')

  if (error instanceof AuthError || error instanceof GraphqlError) {
    throw error
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      role="alert"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2
          }}
          variant="h2"
        >
          <SentimentVeryDissatisfiedOutlinedIcon sx={{ fontSize: '6rem' }} />
        </Typography>
        <Typography variant="h2" align="center">
          {t('error.somethingwentwrong')}
        </Typography>
        {!environment.production && (
          <Paper sx={{ px: 3 }}>
            <pre>{errorMessage}</pre>
          </Paper>
        )}
      </Box>
    </Box>
  )
}
