import { useTranslation } from 'react-i18next'
import { useFlyIn } from '@epix-web-apps/ui'
import { useGetLdpContractDetailsQuery } from '@epix-web-apps/core'
import { Box, IconButton } from '@mui/material'
import { DetailBox, DetailBoxRow } from '@epix-web-apps/ui'
import EditIcon from '@mui/icons-material/Edit'
import { EditLdpPayScale } from './edit-ldp-pay-scale'

/* eslint-disable-next-line */
export interface ItLdpProps {
  contractId: string
}

export function ItLdp({ contractId }: ItLdpProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()

  const { data: partnerSpecificDetails } = useGetLdpContractDetailsQuery({
    contractId: contractId || ''
  })

  const partnerPayScale = partnerSpecificDetails?.ldpContractDetailByContractId.ldpContractDetailPayScaleModel?.payScale

  return (
    <Box>
      <DetailBox
        title={t('ldpdetailpage.payscale.title')}
        actionIcon={
          <IconButton
            size="small"
            onClick={() =>
              openFlyIn({
                content: <EditLdpPayScale contractId={contractId} />
              })
            }
          >
            <EditIcon />
          </IconButton>
        }
      >
        {partnerPayScale && (
          <DetailBoxRow>
            <p>
              {t('form.field.payscale')}:<span className="bold"> {partnerPayScale}</span>
            </p>
          </DetailBoxRow>
        )}
      </DetailBox>
    </Box>
  )
}

export default ItLdp
