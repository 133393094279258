import {
  OrderDirection,
  PayrollCodeContractBankDetailModel,
  useGetAllContractsQuery,
  useGetCompanyCarHistoriesByContractIdQuery,
  useGetContractByIdQuery,
  useGetPayrollCodeContractBankDetailsByContractIdQuery,
  useGetPersonByIdQuery,
  useNameof
} from '@epix-web-apps/core'
import { Box, Button, Divider, IconButton, Link, MenuItem, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'
import { CompanyCar, DetailBox, DetailBoxRow, HeaderTitleNavigation } from '@epix-web-apps/ui'
import {
  EditContractAdditionalInformation,
  EditContractMobility,
  EditContractPaymentInformation,
  EditContractPeriod,
  EditContractType
} from '../../contract-components'
import { useFlyIn } from '@epix-web-apps/ui'
import { useLocation, useNavigate, Location } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { DataTable, SortModel } from '../../data-table'
import { GridColDef } from '@mui/x-data-grid'
import { Add, MoreHoriz } from '@mui/icons-material'
import DropdownMenu from '../../dropdown-menu'
import { AddEditBankDetails } from '../add-edit-bank-details'

interface LocationState extends Location {
  state: { pageContent?: ContractOverviewPageContent }
}

export enum ContractOverviewPageContent {
  Overview,
  BankDetails
}

export interface ContractsOverviewTabProps {
  personId: string
  contractId: string
}

export function ContractsOverviewTab({ personId, contractId }: ContractsOverviewTabProps) {
  const navigate = useNavigate()
  const location = useLocation() as LocationState
  const [pageContent, setPageContent] = useState(location.state?.pageContent ?? ContractOverviewPageContent.Overview)

  // clear state from window.history
  useEffect(() => {
    navigate(location.pathname, { replace: true })
  }, [])

  switch (pageContent) {
    case ContractOverviewPageContent.Overview:
      return <OverviewContent personId={personId} contractId={contractId} setPageContent={setPageContent} />
    case ContractOverviewPageContent.BankDetails:
      return <BankDetailsContent contractId={contractId} setPageContent={setPageContent} />
  }
}

interface OverviewContentProps {
  personId: string
  contractId: string
  setPageContent: (value: ContractOverviewPageContent) => void
}

function OverviewContent({ personId, contractId, setPageContent }: OverviewContentProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { openFlyIn } = useFlyIn()
  const navigate = useNavigate()

  const { data: getPersonById } = useGetPersonByIdQuery({ personId: personId })
  const person = getPersonById?.personById

  const { data: getContractById } = useGetContractByIdQuery({ contractId: contractId })
  const contract = getContractById?.contractById

  const { refetch: refetchAllContracts } = useGetAllContractsQuery({ personId: personId }, { enabled: false })

  const { data: getCompanyCarHistories, refetch: refetchAllCompanyCarHistories } =
    useGetCompanyCarHistoriesByContractIdQuery({ contractId: contractId })

  const contractPaymentInfoOverride =
    !!contract?.iban ||
    !!contract?.bic ||
    !!contract?.bankSortCode ||
    !!contract?.bankAccount ||
    !!contract?.accountHolder

  const companyCarHistories = getCompanyCarHistories?.companyCarHistories.filter(
    c => new Date(c.endDate) > new Date() || c.endDate === null
  )

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        <Box sx={{ flex: 1 }}>
          <DetailBox
            title={t('contractdetailpage.contractperiod.title')}
            actionIcon={
              <IconButton
                size="small"
                onClick={() =>
                  openFlyIn({
                    content: <EditContractPeriod />,
                    callbackAfterClose: () => refetchAllContracts
                  })
                }
              >
                <EditIcon />
              </IconButton>
            }
          >
            {contract?.durationType?.value && (
              <DetailBoxRow>
                <Typography>
                  {t('form.field.duration')}:<span className="bold"> {contract?.durationType?.value}</span>{' '}
                  {contract?.icpSubContractDuration?.name && `(${contract?.icpSubContractDuration?.name})`}
                </Typography>
              </DetailBoxRow>
            )}
            <DetailBoxRow>
              <Typography>
                {t('form.field.period')}:
                <span className="bold">
                  {' '}
                  {new Date(contract?.startDate).toLocaleDateString()} -{' '}
                  {contract?.endDate ? new Date(contract?.endDate).toLocaleDateString() : '...'}
                </span>
              </Typography>
            </DetailBoxRow>
            {contract?.fixedTermEndDate && (
              <DetailBoxRow>
                <Typography>
                  {t('form.field.fixedtermenddate')}:
                  <span className="bold"> {new Date(contract.fixedTermEndDate).toLocaleDateString()}</span>
                </Typography>
              </DetailBoxRow>
            )}
            {contract?.probationEndDate && (
              <DetailBoxRow>
                <Typography>
                  {t('form.field.probationaryperiodenddate')}:
                  <span className="bold"> {new Date(contract.probationEndDate).toLocaleDateString()}</span>
                </Typography>
              </DetailBoxRow>
            )}
            {contract?.endReasonType?.value && (
              <DetailBoxRow>
                <Typography>
                  {t('form.field.endreason')}:<span className="bold"> {contract?.endReasonType.value}</span>{' '}
                  {contract?.icpSubContractEndReason?.name && `(${contract?.icpSubContractEndReason?.name})`}
                </Typography>
              </DetailBoxRow>
            )}
          </DetailBox>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '2rem'
        }}
      >
        <Box sx={{ flex: 1 }}>
          <DetailBox
            title={t('contractdetailpage.contracttype.title')}
            actionIcon={
              <IconButton
                size="small"
                onClick={() =>
                  openFlyIn({
                    content: <EditContractType />
                  })
                }
              >
                <EditIcon />
              </IconButton>
            }
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <DetailBoxRow>
                  <Typography>
                    {t('form.field.employer')}: <span className="bold">{contract?.employer?.companyName}</span> (
                    {contract?.employer?.number})
                  </Typography>
                </DetailBoxRow>
                {contract?.employeeNumber && (
                  <DetailBoxRow>
                    <Typography>
                      {t('form.field.employeenumber')}: {contract?.employeeNumber}
                    </Typography>
                  </DetailBoxRow>
                )}
                {contract?.payGroup?.id && (
                  <DetailBoxRow>
                    <Typography>
                      {t('form.field.paygroup')}: {contract?.payGroup?.name} ({contract?.payGroup?.code})
                    </Typography>
                  </DetailBoxRow>
                )}
                {contract?.icp.payrollProvider && (
                  <DetailBoxRow>
                    <Typography>
                      {t('form.field.payrollprovider')}: <span className="bold">{contract?.icp.payrollProvider}</span> (
                      {contract?.icp.code})
                    </Typography>
                  </DetailBoxRow>
                )}
                {contract?.employeeType && (
                  <DetailBoxRow>
                    <Typography>
                      {t('contractdetailpage.employeepopulationtype')}:
                      <span className="bold"> {contract?.employeeType?.value}</span>{' '}
                      {contract?.icpSubEmployeeType?.name && `(${contract?.icpSubEmployeeType?.name})`}
                      {contract?.employeeType?.value && contract?.populationType?.value && <span> / </span>}
                      <span className="bold"> {contract?.populationType?.value}</span>{' '}
                      {contract?.icpSubPopulationType?.name && `(${contract?.icpSubPopulationType?.name})`}
                    </Typography>
                  </DetailBoxRow>
                )}
                {contract?.subjectedToTaxesAbroad && (
                  <DetailBoxRow>
                    <Typography>
                      {t('contractdetailpage.subjectedtotaxesabroad')}: {contract?.countryOfTaxation?.name}
                    </Typography>
                  </DetailBoxRow>
                )}
                {contract?.subjectedToSocialSecurityAbroad && (
                  <DetailBoxRow>
                    <Typography>
                      {t('contractdetailpage.subjectedtosocialsecurityabroad')}:{' '}
                      {contract?.countryForSocialSecurityCalculation?.name}
                    </Typography>
                  </DetailBoxRow>
                )}
              </Box>
            </Box>
          </DetailBox>

          <DetailBox
            title={t('contractdetailpage.additionalinfo.title')}
            actionIcon={
              <IconButton
                size="small"
                onClick={() =>
                  openFlyIn({
                    content: <EditContractAdditionalInformation />
                  })
                }
              >
                <EditIcon />
              </IconButton>
            }
          >
            {contract?.department && (
              <DetailBoxRow>
                <Typography>
                  {t('form.field.department')}: {contract.department}
                </Typography>
              </DetailBoxRow>
            )}
            {contract?.costCenter && (
              <DetailBoxRow>
                <Typography>
                  {t('form.field.costcenter')}: {contract.costCenter}
                </Typography>
              </DetailBoxRow>
            )}
            {(contract?.fte || contract?.fte === 0) && (
              <DetailBoxRow>
                <Typography>
                  {t('form.field.fte')}: {contract.fteWorkSchedule ?? contract.fte}
                </Typography>
              </DetailBoxRow>
            )}
            {contract?.branchUnit && (
              <DetailBoxRow>
                <Typography>
                  {t('form.field.branchunit')}: {contract.branchUnit.description}
                </Typography>
              </DetailBoxRow>
            )}
            {contract?.workPermitType && (
              <DetailBoxRow>
                <Typography>
                  {t('form.field.workpermittype')}: {contract.workPermitType.value}
                </Typography>
              </DetailBoxRow>
            )}
            {contract?.workPermitStartDate && (
              <DetailBoxRow>
                <Typography>
                  {t('form.field.workpermitstartdate')}: {new Date(contract?.workPermitStartDate).toLocaleDateString()}
                </Typography>
              </DetailBoxRow>
            )}
            {contract?.workPermitEndDate && (
              <DetailBoxRow>
                <Typography>
                  {t('form.field.workpermitenddate')}: {new Date(contract?.workPermitEndDate).toLocaleDateString()}
                </Typography>
              </DetailBoxRow>
            )}
            {contract?.notes && (
              <DetailBoxRow>
                <Typography>
                  {t('form.field.notes')}: {contract.notes}
                </Typography>
              </DetailBoxRow>
            )}
          </DetailBox>
        </Box>

        <Box sx={{ flex: 1 }}>
          <DetailBox
            title={t('contractdetailpage.paymentinfo.title')}
            actionIcon={
              <IconButton
                size="small"
                onClick={() =>
                  openFlyIn({
                    content: <EditContractPaymentInformation />
                  })
                }
              >
                <EditIcon />
              </IconButton>
            }
          >
            {contractPaymentInfoOverride && (
              <>
                {contract?.accountHolder && (
                  <DetailBoxRow>
                    <Typography>
                      {t('form.field.accountholder')}: {contract.accountHolder}
                    </Typography>
                  </DetailBoxRow>
                )}
                {contract?.bankAccount && (
                  <DetailBoxRow>
                    <Typography>
                      {t('form.field.bankaccount')}: {contract.bankAccount}
                    </Typography>
                  </DetailBoxRow>
                )}
                {contract?.iban && (
                  <DetailBoxRow>
                    <Typography>
                      {t('form.field.iban')}: {contract.iban}
                    </Typography>
                  </DetailBoxRow>
                )}
                {contract?.bic && (
                  <DetailBoxRow>
                    <Typography>
                      {t('form.field.bic')}: {contract.bic}
                    </Typography>
                  </DetailBoxRow>
                )}
                {contract?.bankSortCode && (
                  <DetailBoxRow>
                    <Typography>
                      {t('form.field.sortcode')}: {contract.bankSortCode}
                    </Typography>
                  </DetailBoxRow>
                )}
              </>
            )}

            {!contractPaymentInfoOverride && (
              <>
                {person?.accountHolder && (
                  <DetailBoxRow>
                    <Typography>
                      {t('form.field.accountholder')}: {person.accountHolder}
                    </Typography>
                  </DetailBoxRow>
                )}
                {person?.bankAccount && (
                  <DetailBoxRow>
                    <Typography>
                      {t('form.field.bankaccount')}: {person.bankAccount}
                    </Typography>
                  </DetailBoxRow>
                )}
                {person?.iban && (
                  <DetailBoxRow>
                    <Typography>
                      {t('form.field.iban')}: {person.iban}
                    </Typography>
                  </DetailBoxRow>
                )}
                {person?.bic && (
                  <DetailBoxRow>
                    <Typography>
                      {t('form.field.bic')}: {person.bic}
                    </Typography>
                  </DetailBoxRow>
                )}
                {person?.bankSortCode && (
                  <DetailBoxRow>
                    <Typography>
                      {t('form.field.sortcode')}: {person.bankSortCode}
                    </Typography>
                  </DetailBoxRow>
                )}
              </>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Link onClick={() => setPageContent(ContractOverviewPageContent.BankDetails)}>
                {t('contractdetailpage.bankmanagement.info')}
              </Link>
            </Box>
          </DetailBox>

          {contract?.employer?.hasCompanyCars && (
            <DetailBox
              title={t('contractdetailpage.mobility.title')}
              actionIcon={
                <IconButton
                  size="small"
                  onClick={() =>
                    openFlyIn({
                      content: <EditContractMobility />,
                      callbackAfterClose: () => refetchAllCompanyCarHistories
                    })
                  }
                >
                  <EditIcon />
                </IconButton>
              }
            >
              {companyCarHistories?.length === 0 && (
                <DetailBoxRow>
                  <Typography>
                    {person?.firstName} {t('contractdetailpage.nocar')}{' '}
                  </Typography>
                </DetailBoxRow>
              )}

              {companyCarHistories?.map((companyCarHistory, index) => (
                <Box key={index}>
                  <DetailBoxRow>
                    <Typography>
                      {`${person?.firstName} ${t('contractdetailpage.usesa')} `}
                      <Link
                        sx={{
                          color: theme.palette.common.black,
                          fontWeight: 'bold'
                        }}
                        onClick={() => navigate(CompanyCar.ID(`${companyCarHistory.companyCar.id}`))}
                      >
                        <span>
                          {`${companyCarHistory.companyCar.carBrandType} `}
                          {companyCarHistory.companyCar.model && (
                            <span>{`${companyCarHistory.companyCar.model} `}</span>
                          )}
                          {`(${companyCarHistory.companyCar.licensePlate})`}
                        </span>
                      </Link>
                      {` ${t('contractdetailpage.since')} `}
                      {new Date(companyCarHistory.startDate).toLocaleDateString()}
                    </Typography>
                  </DetailBoxRow>

                  <DetailBoxRow>
                    <Typography>
                      <Trans
                        i18nKey="contractdetailpage.cartype"
                        values={{ cartype: companyCarHistory.carType.value }}
                        components={{ bold: <span className="bold" /> }}
                      />
                      {companyCarHistory.companyCar.endDate && (
                        <span>
                          {` ${t('contractdetailpage.leaseendson')} ${new Date(
                            companyCarHistory.companyCar.endDate
                          ).toLocaleDateString()}`}
                        </span>
                      )}
                    </Typography>
                  </DetailBoxRow>

                  {(companyCarHistory.companyCar.co2 || companyCarHistory.companyCar.co2 === 0) && (
                    <Typography>
                      {t('contractdetailpage.co2')}{' '}
                      <span className="bold">{` ${companyCarHistory.companyCar.co2}`}</span>
                    </Typography>
                  )}

                  {companyCarHistory.companyCar.carCategory && (
                    <DetailBoxRow>
                      <Typography>
                        <Trans
                          i18nKey="contractdetailpage.carcategory"
                          values={{
                            carcategorydescription: companyCarHistory.companyCar.carCategoryDescription
                          }}
                          components={{ bold: <span className="bold" /> }}
                        />
                      </Typography>
                    </DetailBoxRow>
                  )}

                  {companyCarHistory.distanceHomeOffice && (
                    <DetailBoxRow>
                      <Typography>
                        <Trans
                          i18nKey="contractdetailpage.distancehomeoffice"
                          values={{
                            distancehomeoffice: companyCarHistory.distanceHomeOffice
                          }}
                          components={{ bold: <span className="bold" /> }}
                        />
                      </Typography>
                    </DetailBoxRow>
                  )}

                  {companyCarHistory.notes && (
                    <>
                      <span>&nbsp;</span>
                      <DetailBoxRow>
                        <Typography>
                          <span className="bold">{t('contractdetailpage.notes')}: </span>
                          {companyCarHistory.notes}
                        </Typography>
                      </DetailBoxRow>
                    </>
                  )}
                  <Divider sx={{ my: '0.5rem' }} />
                </Box>
              ))}
            </DetailBox>
          )}
        </Box>
      </Box>
    </>
  )
}

interface BankDetailsContentProps {
  contractId: string
  setPageContent: (value: ContractOverviewPageContent) => void
}

type BankDetailRow = {
  id: string
  payrollCode: string
  description: string
  bankAccount: string
  iban: string
}

function BankDetailsContent({ contractId, setPageContent }: BankDetailsContentProps) {
  const [tableData, setTableData] = useState<BankDetailRow[]>([])
  const [clickedRow, setClickedRow] = useState<PayrollCodeContractBankDetailModel | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const theme = useTheme()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const { nameof } = useNameof<BankDetailRow>()

  const { data, isLoading, refetch } = useGetPayrollCodeContractBankDetailsByContractIdQuery(
    { contractId: contractId },
    { suspense: false }
  )

  useEffect(() => {
    if (data) {
      setTableData(
        data.payrollCodeContractBankDetailsByContractId.map(p => {
          return {
            id: p.id,
            payrollCode: p.payrollCodeCode,
            description: p.payrollCodeDescription,
            bankAccount: p.bankAccountNumber ?? '',
            iban: p.iban ?? ''
          }
        })
      )
    }
  }, [data])

  const rowActions = useCallback(
    (row: BankDetailRow) => {
      return (
        <IconButton
          aria-label="row actions"
          aria-controls="menu-row"
          aria-haspopup="true"
          onClick={e => {
            setAnchorEl(e.currentTarget)
            if (row.id) {
              setClickedRow(data?.payrollCodeContractBankDetailsByContractId.find(p => p.id === row.id) ?? null)
            }
          }}
        >
          <MoreHoriz />
        </IconButton>
      )
    },
    [tableData]
  )

  const columns: GridColDef<BankDetailRow>[] = [
    {
      field: nameof('payrollCode'),
      headerName: t('contractdetailpage.bankmanagement.payrollcode'),
      sortable: false
    },
    {
      field: nameof('description'),
      flex: 1,
      headerName: t('contractdetailpage.bankmanagement.description'),
      sortable: false
    },
    {
      field: nameof('bankAccount'),
      flex: 1,
      headerName: t('contractdetailpage.bankmanagement.bankaccount'),
      sortable: false
    },
    {
      field: nameof('iban'),
      flex: 1,
      headerName: t('contractdetailpage.bankmanagement.iban'),
      sortable: false
    },
    {
      field: ' ',
      sortable: false,
      editable: false,
      align: 'center',
      renderCell: rowParams => rowActions(rowParams.row)
    }
  ]

  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: `1px solid ${theme.palette.divider}` }}
      >
        <HeaderTitleNavigation
          title={t('contractdetailpage.bankmanagement.title')}
          mobileSizeTitle
          onBackClick={() => setPageContent(ContractOverviewPageContent.Overview)}
        />
        <Button
          onClick={() =>
            openFlyIn({
              content: <AddEditBankDetails contractId={contractId} payrollCodeContract={null} />,
              callbackAfterClose: () => refetch
            })
          }
          startIcon={<Add />}
        >
          {t('contractdetailpage.bankmanagement.add')}
        </Button>
      </Box>
      <DataTable
        data={tableData}
        columns={columns}
        sortModel={new SortModel('', OrderDirection.Asc)}
        isLoading={isLoading}
        hideFooter
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            if (clickedRow !== null) {
              openFlyIn({
                content: <AddEditBankDetails contractId={contractId} payrollCodeContract={clickedRow} />,
                callbackAfterClose: () => refetch
              })
            }
          }}
        >
          {t('common.edit')}
        </MenuItem>
        <MenuItem disabled onClick={() => null}>
          {t('common.delete')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}
