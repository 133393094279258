import { Box, Grid, Paper } from '@mui/material'
import { SuspensePaper } from '../../components/suspense-paper'
import {
  DriversEndingSoonComponent,
  UpcomingAnniversaries,
  UpcomingBirthdays,
  UpcomingEndProbationPeriod,
  UpcomingLeaversComponent,
  UpcomingStartersComponent
} from '../../components/event-components'
import { AnalyticsOverview } from '../../components/general-analytics-components'
import { TaskProcessOverview } from '../../components/task-process-summary'
import ValidationsToSolve from '../../components/validation-components/validations-to-solve'
import { CanView, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { ROLE, useCanAccess, useGetValidationErrorsAndWarningsCountQuery } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface HomePageWidgetsProps {}

export function HomePageWidgets(props: HomePageWidgetsProps) {
  const { icpFilters } = useGlobalPersistedStore()

  const { data: errorsAndWarnings } = useGetValidationErrorsAndWarningsCountQuery(
    { icps: icpFilters },
    { suspense: false }
  )

  const errors = errorsAndWarnings?.validationErrorsAndWarningsCount.errors
  const warnings = errorsAndWarnings?.validationErrorsAndWarningsCount.warnings

  const canAccesValidationErrors = useCanAccess([ROLE.ADMIN, ROLE.VIEWVALIDATIONERRORS])

  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
      <Grid
        item
        md={canAccesValidationErrors ? 6 : 12}
        lg={canAccesValidationErrors ? 8 : 12}
        container
        rowSpacing={2}
        columnSpacing={2}
      >
        <Grid item md={12}>
          <Paper sx={{ p: 1 }}>
            <AnalyticsOverview />
          </Paper>
        </Grid>

        <CanView roles={[ROLE.ADMIN, ROLE.MANAGEPEOPLECONTRACTS]}>
          <Grid item md={12}>
            <Grid container rowSpacing={2} columnSpacing={2}>
              <UpcomingStartersComponent />
              <UpcomingLeaversComponent />
              <UpcomingAnniversaries />
              <UpcomingBirthdays />
              <UpcomingEndProbationPeriod />
              <DriversEndingSoonComponent />
            </Grid>
          </Grid>
        </CanView>
      </Grid>

      <CanView roles={[ROLE.ADMIN, ROLE.VIEWVALIDATIONERRORS]}>
        <Grid item container md={6} lg={4} rowSpacing={2}>
          <Grid item md={12}>
            <SuspensePaper>
              <ValidationsToSolve errors={errors} warnings={warnings} />
            </SuspensePaper>
          </Grid>

          <Grid item md={12}>
            <TaskProcessOverview />
          </Grid>
        </Grid>
      </CanView>
    </Box>
  )
}

export default HomePageWidgets
