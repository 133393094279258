import {
  AsbplContractDetailModel,
  FormSelectOption,
  GraphqlError,
  PARTNER_LIST_IDENTIFIERS,
  useGetAllPartnerContractDetailTypesByListIdentifierQuery,
  useUpdateAsbplTaxesMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf, z } from 'zod'

interface EditASBPLTaxesProps {
  contractDetail: AsbplContractDetailModel
}

export function EditASBPLTaxes({ contractDetail }: EditASBPLTaxesProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<GraphqlError[]>([])

  const { data: plTaxLevels } = useGetAllPartnerContractDetailTypesByListIdentifierQuery({
    listIdentifier: PARTNER_LIST_IDENTIFIERS.PL_TAXES_TAX_LEVEL,
    limit: -1,
    offset: 0
  })

  const taxLevelOptions = plTaxLevels?.allPartnerContractDetailTypesByListIdentifier.data.map(
    p => new FormSelectOption(p.id, p.value)
  )

  const editASBPLTaxesSchema = object({
    taxIdentificationNumber: string().nullable().optional(),
    taxLevelId: string().nullable().optional()
  })

  type EditASBPLTaxesForm = TypeOf<typeof editASBPLTaxesSchema>

  const form = useForm<EditASBPLTaxesForm>({
    resolver: zodResolver(editASBPLTaxesSchema),
    defaultValues: {
      taxIdentificationNumber: contractDetail.taxesTaxIdentificationNumber,
      taxLevelId: contractDetail.taxesTaxLevelType?.id
    }
  })

  const mutation = useUpdateAsbplTaxesMutation()

  function handleOnSubmit(form: EditASBPLTaxesForm) {
    mutation
      .mutateAsync({
        updateASBPLTaxesCommand: {
          id: contractDetail.id,
          taxIdentificationNumber: form.taxIdentificationNumber,
          taxLevelTypeId: form.taxLevelId
        }
      })
      .then(() => closeFlyIn())
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('asbpldetailpage.taxes.flyin.title')}</Typography>
      <FormGridLayout>
        <FormInput sx={12} name="taxIdentificationNumber" label={t('asbpldetailpage.taxes.flyin.taxidnumber')} />
        <FormSelect
          sx={12}
          name={'taxLevelId'}
          label={t('asbpldetailpage.taxes.flyin.taxlevel')}
          options={taxLevelOptions}
        />
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons onCancel={() => closeFlyIn()} isMutating={mutation.isLoading} />
    </FormContainer>
  )
}
