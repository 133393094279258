import { ROLE, partners } from '../services'

export const isAdmin = (roles: Array<string> | undefined) => {
  if (!roles || roles.length === 0) return false
  const upperCaseUserRoles = roles.map(role => role.toUpperCase())
  if (upperCaseUserRoles?.includes(ROLE.ADMIN)) return true
  return false
}

export const isSelfServiceUser = (roles: Array<string> | undefined) => {
  if (!roles || roles.length === 0) return false
  const upperCaseUserRoles = roles.map(role => role.toUpperCase())
  if (upperCaseUserRoles?.includes(ROLE.SELFSERVICEUSER)) {
    return true
  }
  return false
}

export const isOnlySelfServiceUser = (roles: Array<string> | undefined) => {
  if (!roles || roles.length === 0) return false
  const upperCaseUserRoles = roles.map(role => role.toUpperCase())
  if (upperCaseUserRoles?.includes(ROLE.SELFSERVICEUSER) && roles.length === 1) {
    return true
  }
  return false
}

export const isAuthorized = (roles: string[] = [], requiredRoles: ROLE[]) => {
  if (roles.length === 0 || requiredRoles.length === 0) {
    return false
  }

  const upperCaseUserRoles = roles.map(role => role.toUpperCase())
  if (upperCaseUserRoles.some(role => requiredRoles.includes(ROLE[role as keyof typeof ROLE]))) {
    return true
  }
  return false
}

export const isManagedByEpixAndIcpUniqueCodeMatches = (contract: any) => {
  if (contract?.calculatedByEpix && partners.includes(contract?.icp?.configurationKey || '')) return true
  return false
}
