import { Box, Button, Grid, IconButton, LinearProgress, MenuItem, Switch, Typography, useTheme } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { SortModel, usePaginationModel, ScrollableDataTable } from '../../components/data-table'
import {
  IsDateBeforeToday,
  OrderDirection,
  PROCESS_TYPE,
  ToBackendFormatedDate,
  useGetPagedProcessesQuery,
} from '@epix-web-apps/core'
import AddIcon from '@mui/icons-material/Add'
import DropdownMenu from '../../components/dropdown-menu'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ClearIcon from '@mui/icons-material/Clear'
import FilterButton from '../../components/filter-button/filter-button'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { AddPayrollClosure } from '../../components/process-components/payroll-closure/add-payroll-closure'
import { Process, useFlyIn } from '@epix-web-apps/ui'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import { IcpCodeBadge } from '../../components/icp-components'
import WarningIcon from '@mui/icons-material/Warning'
import CancelProcessFlyin from '../../components/process-components/cancel-process-flyin/cancel-process-flyin'
import { AddPayComponentUpdate } from '../../components/process-components/paycomponent-update/add-paycomponent-update'

/* eslint-disable-next-line */
export interface ProcessPageProps {}

export function ProcessPage(props: ProcessPageProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme = useTheme()
  const { openFlyIn } = useFlyIn()
  const [filters, setFilters] = useState({
    showDueDateClose: false,
    showCompletedProcesses: false,
    showCancelledProcesses: false
  })
  const { paginationModel, setPaginationModel, resetPaginationModel } = usePaginationModel()
  const [rowCount, setRowCount] = useState(0)
  const [rowAnchorEl, setRowAnchorEl] = useState<null | HTMLElement>(null)
  const [clickedProcessId, setClickedProcessId] = useState<GridRowId>()

  const { icpFilters } = useGlobalPersistedStore()

  const progressBar = (rowParams: GridRenderCellParams) => {
    if (rowParams.row.cancelled) {
      return (
        <Typography sx={{ display: 'flex', gap: 1, color: theme.palette.error.light }}>
          <CancelOutlinedIcon />
          <span>{t('processpage.progress.cancelled')}</span>
        </Typography>
      )
    }
    if (rowParams.row.hasErrors) {
      return (
        <Typography sx={{ display: 'flex', gap: 1, color: theme.palette.error.light }}>
          <ClearIcon />
          <span>{t('processpage.progress.failed')}</span>
        </Typography>
      )
    }
    if (rowParams.row.completed) {
      return (
        <Typography sx={{ display: 'flex', gap: 1, color: theme.palette.success.light }}>
          <CheckOutlinedIcon />
          <span>{t('processpage.progress.completed')}</span>
        </Typography>
      )
    }
    return (
      <Grid spacing={1} container>
        <Grid xs item sx={{ display: 'block !important' }}>
          <LinearProgress variant="determinate" value={rowParams.value} />
        </Grid>
      </Grid>
    )
  }

  const rowActions = (rowParams: GridRenderCellParams) => (
    <>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setClickedProcessId(rowParams.id)
          setRowAnchorEl(e.currentTarget)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <IconButton aria-label="row details" aria-controls="details-row">
        <KeyboardArrowRightIcon />
      </IconButton>
    </>
  )

  const countryRowData = (rowParams: GridRenderCellParams) => {
    const countryKey = rowParams.value
    return (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <IcpCodeBadge icpCode={countryKey} />
      </Box>
    )
  }

  const dueDate = (rowParams: GridRenderCellParams) => {
    const formatedDueDate = new Date(rowParams.row.duedate).toLocaleDateString()
    if (!rowParams.row.completed && !rowParams.row.cancelled && IsDateBeforeToday(rowParams.row.duedate)) {
      return (
        <p style={{ color: theme.palette.error.light }}>
          <WarningIcon fontSize="small" sx={{ marginLeft: -2.75, position: 'absolute' }} />
          {formatedDueDate}
        </p>
      )
    }
    return <p>{formatedDueDate}</p>
  }

  const columns: GridColDef[] = [
    {
      field: 'country',
      sortable: false,
      editable: false,
      headerName: t('processpage.datatable.column.country'),
      renderCell: countryRowData
    },
    {
      field: 'processType',
      sortable: false,
      headerName: t('processpage.datatable.column.processtype'),
      width: 200
    },
    {
      field: 'processName',
      sortable: false,
      headerName: t('processpage.datatable.column.processname'),
      flex: 1
    },
    {
      field: 'lastmodified',
      sortable: false,
      headerName: t('processpage.datatable.column.lastmodified'),
      width: 200
    },
    {
      field: 'duedate',
      sortable: true,
      headerName: t('processpage.datatable.column.duedate'),
      renderCell: dueDate,
      width: 200
    },
    {
      field: 'progress',
      sortable: false,
      headerName: t('processpage.datatable.column.progress'),
      renderCell: progressBar,
      width: 300
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns?.[5]?.field, OrderDirection.Desc))
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [tableData, setTableData] = useState<GridRowsProp>()

  useEffect(() => {
    resetPaginationModel()
  }, [icpFilters, filters])

  const today = new Date()
  const { data, isLoading, refetch } = useGetPagedProcessesQuery(
    {
      icps: icpFilters,
      includeCompleted: filters.showCompletedProcesses,
      includeCancelled: filters.showCancelledProcesses,
      startDate: filters.showDueDateClose ? ToBackendFormatedDate(today) : null,
      endDate: filters.showDueDateClose
        ? ToBackendFormatedDate(new Date(today.getFullYear(), today.getMonth() + 1, today.getDate()))
        : null,
      offset: paginationModel.offset,
      limit: paginationModel.pageSize,
      sortByProperty: sortModel.field,
      orderDirection: sortModel.orderDirection
    },
    { suspense: false }
  )

  useEffect(() => {
    if (data && data?.pagedProcesses) {
      const processesData = data.pagedProcesses
      setTableData(
        processesData.data.map(row => {
          return {
            id: row.id,
            processTypeKey: row.type.key,
            country: row.countryKey,
            processType: row.type.value,
            processName: row.name,
            lastmodified: new Date(row.lastChangedOn).toLocaleDateString(),
            duedate: row.dueDate,
            progress: (row.currentStep / row.totalSteps) * 100,
            completed: row.completed,
            cancelled: row.cancelled,
            hasErrors: row.hasErrors
          }
        })
      )
      setRowCount(processesData.totalCount)
    }
  }, [data])

  const goToProcessDetailPage = (processType: PROCESS_TYPE, processId?: GridRowId) => {
    if (processId) {
      navigate(Process.PROCESSES_TYPE_ID(processType, `${[processId]}`))
    } else {
      navigate(Process.PROCESSES_TYPE(processType))
    }
  }

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2]
      }}
    >
      <Typography m={0} variant="h2">
        {t('processpage.title')}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <FilterButton filters={filters}>
          <p>
            <Switch
              checked={filters.showDueDateClose}
              onChange={e => setFilters({ ...filters, showDueDateClose: e.target.checked })}
              aria-label={t('processpage.filters.showduedatewithinonemonth')}
            />
            {t('processpage.filters.showduedatewithinonemonth')}
          </p>
          <p>
            <Switch
              checked={filters.showCompletedProcesses}
              onChange={e =>
                setFilters({
                  ...filters,
                  showCompletedProcesses: e.target.checked
                })
              }
              aria-label={t('processpage.filters.showcompleteprocesses')}
            />
            {t('processpage.filters.showcompleteprocesses')}
          </p>
          <p>
            <Switch
              checked={filters.showCancelledProcesses}
              onChange={e =>
                setFilters({
                  ...filters,
                  showCancelledProcesses: e.target.checked
                })
              }
              aria-label={t('processpage.filters.showcancelledprocesses')}
            />
            {t('processpage.filters.showcancelledprocesses')}
          </p>
        </FilterButton>
        <Button variant="contained" onClick={e => setAnchorEl(e.currentTarget)}>
          <AddIcon />
          {t('processpage.button.newprocess')}
        </Button>
      </Box>
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <AddPayrollClosure />,
              callbackAfterClose: () => refetch
            })
          }
        >
          {t('processpage.button.newpayrollclosure')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <AddPayComponentUpdate />,
              callbackAfterClose: () => refetch
            })
          }
        >
          {t('processpage.button.newpaycomponentupdate')}
        </MenuItem>
      </DropdownMenu>
    </Box>
  )

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        onSortChange={setSortModel}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowClick={params =>
          goToProcessDetailPage(PROCESS_TYPE[params.row.processTypeKey as keyof typeof PROCESS_TYPE], params.id)
        }
        isLoading={isLoading}
        sortModel={sortModel}
        filterBarElement={filterBar}
      />
      <DropdownMenu anchorEl={rowAnchorEl} onClose={() => setRowAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <CancelProcessFlyin processId={clickedProcessId} />,
              callbackAfterClose: () => refetch
            })
          }
        >
          {t('processpage.datatable.row.editprocess')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default ProcessPage
