import { Suspense, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Tabs } from '@mui/material'
import {
  getSearchParamNumber,
  OrderDirection,
  useGetAllContractsQuery,
  useGetPersonByIdQuery
} from '@epix-web-apps/core'
import { LoadingOverlay, People } from '@epix-web-apps/ui'
import { HeaderTitleNavigation } from '@epix-web-apps/ui'
import { DetailPageBaseQueryParams, DetailPageContentHeader } from '@epix-web-apps/ui'
import { TabPanel } from '@epix-web-apps/ui'
import IdentificationTab from './identification-tab/identification-tab'
import InformationTab from './information-tab/information-tab'
import SelfServiceTab from './self-service-tab/self-service-tab'
import NextPreviousPerson from '../../components/people-components/next-previous-person/next-previous-person'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined'
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined'
import { IconTab } from '../../components/tab-components/tab/icon-tab'
import InjuryTab from './injury-tab/injury-tab'

/* eslint-disable-next-line */
export interface PeopleDetailPageProps {}

export function PeopleDetailPage(props: PeopleDetailPageProps) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const params = useParams<DetailPageBaseQueryParams>()
  const { data: getPersonById } = useGetPersonByIdQuery(
    {
      personId: params.id || ''
    },
    {
      enabled: !!params.id
    }
  )
  const person = getPersonById?.personById

  const { data: getAllContracts } = useGetAllContractsQuery(
    {
      personId: params.id ?? '',
      orderDirection: OrderDirection.Desc
    },
    {
      enabled: !!params.id
    }
  )
  const anyContractHasSelfService = (getAllContracts?.allContracts ?? []).some(c => c.payGroup?.hasSelfService)

  const [tabIndex, setTabIndex] = useState(0)

  return (
    <>
      <DetailPageContentHeader>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <HeaderTitleNavigation
            backToLink={`${People.PEOPLE()}?page=${getSearchParamNumber('page', searchParams)}`}
            title={`${person?.firstName} ${person?.preferredLastName}`}
            showDivider={false}
          />
          <NextPreviousPerson personId={params.id || ''} />
        </Box>
      </DetailPageContentHeader>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={(_, tabIndex) => setTabIndex(tabIndex)}>
          <IconTab
            label={t('peopledetailpage.identification.tab')}
            icon={<PersonOutlineOutlinedIcon fontSize="small" />}
          />
          <IconTab label={t('peopledetailpage.companyinfo.tab')} icon={<InfoOutlinedIcon fontSize="small" />} />
          {anyContractHasSelfService && (
            <IconTab
              label={t('peopledetailpage.selfserviceaccess.tab')}
              icon={<PhoneAndroidOutlinedIcon fontSize="small" />}
            />
          )}
          <IconTab label={t('peopledetailpage.injury.tab')} icon={<LocalHospitalOutlinedIcon fontSize="small" />} />
        </Tabs>
      </Box>

      <TabPanel value={tabIndex} index={0}>
        <Suspense fallback={<LoadingOverlay />}>
          <IdentificationTab />
        </Suspense>
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <Suspense fallback={<LoadingOverlay />}>
          <InformationTab />
        </Suspense>
      </TabPanel>

      {anyContractHasSelfService && (
        <TabPanel value={tabIndex} index={2}>
          <Suspense fallback={<LoadingOverlay />}>
            <SelfServiceTab />
          </Suspense>
        </TabPanel>
      )}

      <TabPanel value={tabIndex} index={3}>
        <Suspense fallback={<LoadingOverlay />}>
          <InjuryTab />
        </Suspense>
      </TabPanel>
    </>
  )
}

export default PeopleDetailPage
