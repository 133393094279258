import { DataTable, SortModel } from '../../../data-table'
import { GridColDef } from '@mui/x-data-grid'
import DataTableEmpty from '../../../data-table/data-table-empty'
import DoneIcon from '@mui/icons-material/Done'
import { Box, Theme, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { StepParams } from '../../generic-steps'
import {
  OrderDirection,
  PayrollCodeComparisonModel,
  useGetPayComponentSummaryForClosureProcessQuery,
  useNameof
} from '@epix-web-apps/core'
import { FinalCheckSalaryDownloadButton } from '../final-check-salary-download-button'

function getColor(theme: Theme, value: number) {
  if (value > 0) return theme.palette.error.main
  if (value < 0) return theme.palette.success.main
  return undefined
}

export function FinalCheckSalaryComponent() {
  const { t } = useTranslation()
  const { nameof } = useNameof<PayrollCodeComparisonModel>()
  const theme = useTheme()
  const params = useParams<StepParams>()

  const rowActions = (row: PayrollCodeComparisonModel) => {
    return (
      <FinalCheckSalaryDownloadButton
        processId={params.id ?? ''}
        payrollCodeId={row.payrollCodeId}
        payrollCode={row.payrollCode}
        valueType={row.valueType}
      />
    )
  }

  const { data: payComponentData, isLoading } = useGetPayComponentSummaryForClosureProcessQuery({
    payrollClosureProcessId: params.id ?? ''
  })

  const columns: GridColDef<PayrollCodeComparisonModel>[] = [
    {
      field: nameof('payrollCode'),
      headerName: t('processpage.finalcheck.salarycomponentsdatatable.column.payrollcode'),
      flex: 1.5,
      sortable: false
    },
    {
      field: nameof('payrollLabel'),
      headerName: t('processpage.finalcheck.salarycomponentsdatatable.column.payrolllabel'),
      flex: 1,
      sortable: false
    },
    {
      field: nameof('epixDescription'),
      headerName: t('processpage.finalcheck.salarycomponentsdatatable.column.epixdescription'),
      flex: 1,
      sortable: false
    },
    {
      field: nameof('previousPeriodAmount'),
      headerName: t('processpage.finalcheck.salarycomponentsdatatable.column.previousperiodamount'),
      flex: 1,
      sortable: false
    },
    {
      field: nameof('currentPeriodAmount'),
      headerName: t('processpage.finalcheck.salarycomponentsdatatable.column.currentperiodamount'),
      flex: 1,
      sortable: false
    },
    {
      field: nameof('difference'),
      headerName: t('processpage.finalcheck.salarycomponentsdatatable.column.difference'),
      flex: 1,
      sortable: false,
      renderCell: params => {
        return (
          <Box
            sx={{
              color: getColor(theme, params.value)
            }}
          >
            {params.value}
          </Box>
        )
      }
    },
    {
      field: nameof('valueType'),
      headerName: t('processpage.finalcheck.salarycomponentsdatatable.column.valuetype'),
      flex: 1,
      sortable: false
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      align: 'center',
      renderCell: rowParams => rowActions(rowParams.row)
    }
  ]

  const data = (payComponentData?.payComponentSummaryForClosureProcess ?? []).map(p => ({
    id: p.payrollCodeId,
    ...p
  }))

  return (
    <DataTable
      data={data}
      sortModel={new SortModel(columns?.[1]?.field, OrderDirection.Asc)}
      columns={columns}
      totalRowCount={payComponentData?.payComponentSummaryForClosureProcess.length}
      isLoading={isLoading}
      hideFooter={true}
      emptyStateElement={
        <DataTableEmpty
          title={t('emptystate.process.finalcheck.paycomponents')}
          icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
        />
      }
    />
  )
}
