import {
  ClickAwayListener,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  SxProps,
  Theme,
  Tooltip
} from '@mui/material'
import { forwardRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

export interface ListItemProps {
  to: string
  icon: any
  text: string
  sx?: SxProps<Theme> | undefined
}

/* eslint-disable-next-line */
export interface ListItemTooltipComponentProps extends ListItemProps {
  open?: boolean
  subItems?: { to: string; text: string; icon: React.ReactNode }[] // Add subItems prop
}

export const ListItem = forwardRef<HTMLAnchorElement, ListItemProps>(({ to, icon, text, sx, ...props }, ref) => {
  const location = useLocation()

  const isSelected = new RegExp(`^${to}(/|$)`).test(location.pathname)

  return (
    <ListItemButton
      sx={sx ? sx : { mx: 2, padding: 0.5 }}
      selected={isSelected}
      component={Link}
      to={to ?? '#'}
      ref={ref}
      {...props}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  )
})

export function ListItemTooltipComponent({
  to,
  icon,
  text,
  open = undefined,
  subItems
}: ListItemTooltipComponentProps) {
  const [expand, setExpand] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isHoveringPopper, setIsHoveringPopper] = useState(false)

  const handleToggleExpand = () => {
    setExpand(prev => !prev)
  }

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setIsHoveringPopper(true)
  }

  const handleMouseLeave = () => {
    setIsHoveringPopper(false)
  }

  const handlePopperMouseEnter = () => {
    setIsHoveringPopper(true)
  }

  const openSubMenu = Boolean(isHoveringPopper)

  return (
    <>
      <div onMouseEnter={subItems ? handleMouseEnter : undefined} onMouseLeave={handleMouseLeave}>
        {!open && subItems ? (
          <ListItem to={to} icon={icon} text={text} sx={{}} />
        ) : !open ? (
          <Tooltip title={text} placement="right">
            <ListItem to={to} icon={icon} text={text} sx={{}} />
          </Tooltip>
        ) : open && !subItems ? (
          <ListItem to={to} icon={icon} text={text} sx={{}} />
        ) : (
          <ListItemButton onClick={handleToggleExpand} sx={{}}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
            {subItems && (expand ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        )}
      </div>

      {subItems &&
        (open ? (
          <Collapse in={expand}>
            <List component="div" disablePadding>
              {subItems.map((subItem, index) => (
                <ListItem key={index} to={subItem.to} icon={subItem.icon} text={subItem.text} sx={{ pl: 4 }} />
              ))}
            </List>
          </Collapse>
        ) : (
          <Popper
            open={openSubMenu}
            anchorEl={anchorEl}
            placement="left-start"
            onMouseEnter={handlePopperMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <ClickAwayListener onClickAway={handleMouseLeave}>
              <Paper>
                <List disablePadding component="div">
                  {subItems.map((subItem, index) => (
                    <ListItem key={index} to={subItem.to} icon={subItem.icon} text={subItem.text} sx={{}} />
                  ))}
                </List>
              </Paper>
            </ClickAwayListener>
          </Popper>
        ))}
    </>
  )
}

export default ListItemTooltipComponent
