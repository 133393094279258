import {
  useCreateSmartPayComponentMutation,
  useGetSmartPayComponentByIdQuery,
  useUpdateSmartPayComponentMutation
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, FormInput, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

export interface AddEditSmartPayComponentProps {
  employerId: string
  smartPayComponentId?: string
}

export function AddEditSmartPayComponent({ employerId, smartPayComponentId }: AddEditSmartPayComponentProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: smartPayComponentByIdData, refetch: refetchSmartPayComponentById } = useGetSmartPayComponentByIdQuery(
    {
      id: smartPayComponentId || ''
    },
    {
      enabled: !!smartPayComponentId
    }
  )

  const addEditSmartPayComponentSchema = object({
    code: string({
      required_error: t('form.validation.coderequired'),
      invalid_type_error: t('form.validation.coderequired')
    }).min(1, t('form.validation.coderequired')),
    description: string({
      required_error: t('form.validation.descriptionrequired'),
      invalid_type_error: t('form.validation.descriptionrequired')
    }).min(1, t('form.validation.descriptionrequired'))
  })

  type CreateEditSmartPayComponentForm = TypeOf<typeof addEditSmartPayComponentSchema>
  const form = useForm<CreateEditSmartPayComponentForm>({
    resolver: zodResolver(addEditSmartPayComponentSchema),
    defaultValues: {
      code: smartPayComponentByIdData?.smartPayComponentById.code,
      description: smartPayComponentByIdData?.smartPayComponentById.description
    }
  })

  const createMutation = useCreateSmartPayComponentMutation()
  const updateMutation = useUpdateSmartPayComponentMutation()

  const handleOnSubmit = (newSmartPayComponent: CreateEditSmartPayComponentForm) => {
    if (!smartPayComponentId) {
      createMutation
        .mutateAsync({
          createSmartPayComponentCommand: {
            code: newSmartPayComponent.code,
            description: newSmartPayComponent.description,
            startDate: new Date(), //TODO 2722 set this date in BE?
            employerId: employerId
          }
        })
        .then(closeFlyIn)
    } else {
      updateMutation
        .mutateAsync({
          updateSmartPayComponentCommand: {
            id: smartPayComponentId,
            code: newSmartPayComponent.code,
            description: newSmartPayComponent.description
          }
        })
        .then(() => {
          refetchSmartPayComponentById()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditsmartpaycomponent.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="code" label={`${t('form.field.code')}`} />

        <FormInput sx={12} name="description" label={`${t('form.field.description')}`} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={createMutation.isLoading || updateMutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditSmartPayComponent
