import { OrderDirection, useGetAllRequestPoliciesByEmployerIdQuery, useGetEmployerByIdQuery } from '@epix-web-apps/core'
import { SortModel, usePaginationModel, ScrollableDataTable } from '../../components/data-table'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, Typography, MenuItem } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp } from '@mui/x-data-grid'
import { Configuration, DetailPageBaseQueryParams, HeaderTitleNavigation } from '@epix-web-apps/ui'
import { useNavigate, useParams } from 'react-router-dom'
import DoneIcon from '@mui/icons-material/Done'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import AddIcon from '@mui/icons-material/Add'
import { useFlyIn } from '@epix-web-apps/ui'
import DropdownMenu from '../../components/dropdown-menu/dropdown-menu'
import { AddRequestPolicy } from '../../components/request-policy-components'
import RemoveRequestPolicy from '../../components/request-policy-components/remove-request-policy'

/* eslint-disable-next-line */
export interface RequestPolicyPageProps {}

export type RequestPolicyDetailPageParams = DetailPageBaseQueryParams & {
  employerid: string
}

export function RequestPolicyPage(props: RequestPolicyPageProps) {
  const params = useParams<RequestPolicyDetailPageParams>()
  const { t } = useTranslation()
  const { paginationModel, setPaginationModel } = usePaginationModel()
  const [rowCount, setRowCount] = useState(0)
  const { openFlyIn, closeFlyIn } = useFlyIn()
  const [requestPolicyId, setRequestPolicyId] = useState('')
  const [internalName, setInternalName] = useState('')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const navigate = useNavigate()
  const employerId = params.employerid

  const { data: getEmployerById } = useGetEmployerByIdQuery({
    employerId: employerId || ''
  })

  const company = getEmployerById?.employerById.companyName

  const activeActions = (rowParams: GridRenderCellParams) =>
    rowParams.value ? <DoneIcon /> : <HighlightOffOutlinedIcon />

  const rowActions = (rowParams: GridRenderCellParams) => (
    <>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
          setRequestPolicyId(rowParams.row.id)
          setInternalName(rowParams.row.internalName)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <IconButton aria-label="row details" aria-controls="details-row"></IconButton>
    </>
  )

  const columns: GridColDef[] = [
    {
      field: 'internalName',
      headerName: t('requestpolicypage.datatable.column.internalname'),
      flex: 1,
      sortable: false
    },
    {
      field: 'absenceType',
      headerName: t('requestpolicypage.datatable.column.absencetype'),
      flex: 1,
      sortable: false
    },
    {
      field: 'canBeRequested',
      headerName: t('requestpolicypage.datatable.column.canberequest'),
      flex: 1,
      sortable: false,
      renderCell: activeActions
    },
    {
      field: 'needsApproval',
      headerName: t('requestpolicypage.datatable.column.needsapproval'),
      flex: 1,
      sortable: false,
      renderCell: activeActions
    },
    {
      field: 'unlimited',
      headerName: t('requestpolicypage.datatable.column.unlimited'),
      flex: 1,
      sortable: false,
      renderCell: activeActions
    },
    {
      field: 'obfuscated',
      headerName: t('requestpolicypage.datatable.column.obfuscated'),
      flex: 1,
      sortable: false,
      renderCell: activeActions
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns?.[1]?.field, OrderDirection.Asc))
  const [tableData, setTableData] = useState<GridRowsProp>()

  const { data, isLoading, refetch } = useGetAllRequestPoliciesByEmployerIdQuery(
    {
      employerId: employerId || '',
      offset: paginationModel.offset,
      limit: paginationModel.pageSize
    },
    {
      suspense: false
    }
  )

  const filterBar = (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: [2],
          paddingLeft: [2],
          paddingRight: [2]
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERS(`${params.id}`)}
          title={t('requestpolicypage.title')}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Button
            variant="contained"
            onClick={() =>
              openFlyIn({
                content: <AddRequestPolicy employerId={employerId || ''} />,
                callbackAfterClose: () => refetch
              })
            }
          >
            <AddIcon />
            {t('requestpolicypage.button.addrequestpolicy')}
          </Button>
        </Box>
      </Box>
      <Typography color={'gray'} variant="h3" paddingLeft={2}>
        {company}
      </Typography>
    </Box>
  )

  useEffect(() => {
    if (data && data?.allRequestPoliciesByEmployer) {
      const requestPolicyData = data.allRequestPoliciesByEmployer
      setTableData(
        requestPolicyData.data.map(row => {
          return {
            id: row.id,
            internalName: row.internalName,
            userFriendlyName: row.userFriendlyName,
            absenceType: row.absenceType.value,
            canBeRequested: row.canBeRequested,
            needsApproval: row.needsApproval,
            unlimited: row.unlimited,
            obfuscated: row.obfuscated
          }
        })
      )
      setRowCount(requestPolicyData.totalCount)
    }
  }, [data])

  const goToRequestPolicySettingsPage = (clickedRow: GridRowId | undefined) => {
    if (clickedRow) {
      navigate(`${requestPolicyId}`, {
        state: {
          requestPolicyId: clickedRow
        }
      })
    }
  }

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        onSortChange={setSortModel}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        isLoading={isLoading}
        sortModel={sortModel}
        filterBarElement={filterBar}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => goToRequestPolicySettingsPage(requestPolicyId)}>
          {t('requestpolicies.list.row.menu.managerequestpolicy')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <RemoveRequestPolicy
                  requestPolicyId={requestPolicyId ?? ''}
                  internalName={internalName ?? ''}
                  closeFlyIn={closeFlyIn}
                />
              ),
              callbackAfterClose: () => refetch
            })
          }
        >
          {t('common.delete')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default RequestPolicyPage
