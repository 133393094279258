import { Box, Grid, Typography, useTheme, Table, TableBody } from '@mui/material'
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import HomePageTableRow from '../../components/onboarding-components/home-page-table-row/home-page-table-row'
import { ONBOARDING_ENTITIES, ROLE, useCanAccess, useGetOnboardingStatusQuery } from '@epix-web-apps/core'
import { Configuration, People } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface HomePageOnboardingProps {}

export const HeaderRow = styled(Box)(() => {
  const theme = useTheme()
  return {
    '.MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      width: '2rem',
      height: '2rem'
    }
  }
})

export function HomePageOnboarding(props: HomePageOnboardingProps) {
  const { t } = useTranslation()

  const { data: onboardingStatus } = useGetOnboardingStatusQuery()

  const entityHasData: Record<string, boolean> = {}

  onboardingStatus?.onboardingStatus.forEach(entity => {
    const key = entity.entityName
    entityHasData[key] = !!entity.entityId
  })

  const allusers = onboardingStatus?.onboardingStatus.find(u => u.entityName === ONBOARDING_ENTITIES.USER)
  const paybixUsers = onboardingStatus?.onboardingStatus.find(u => u.entityName === ONBOARDING_ENTITIES.PAYBIXUSERS)
  const onboardingTeams = onboardingStatus?.onboardingStatus.find(u => u.entityName === ONBOARDING_ENTITIES.TEAM)

  const canAccessUsers = useCanAccess([ROLE.ADMIN, ROLE.MANAGEUSERSANDPOLICIES])
  const canAccessTeams = useCanAccess([ROLE.ADMIN, ROLE.MANAGETEAMSANDROLES])
  const canAccessPeople = useCanAccess([ROLE.ADMIN, ROLE.MANAGEPEOPLECONTRACTS])
  const canAccesConfiguration = useCanAccess([ROLE.ADMIN, ROLE.MANAGECONFIGURATION])
  const canAccesPayrollCodes = useCanAccess([ROLE.ADMIN, ROLE.MANAGEPAYROLLCODES])

  return (
    <Box sx={{ p: 3 }}>
      <Grid container>
        <Grid p={0.5} item xs={0.8}>
          <HeaderRow>
            <ConstructionOutlinedIcon />
          </HeaderRow>
        </Grid>
        <Grid item xs={11.2}>
          <Typography m={0} variant="h2">
            {t('homepage-onboarding.title')}
          </Typography>
          <Typography paddingBottom={4} m={0}>
            {t('homepage-onboarding.subtitle')}
          </Typography>
          <Grid container>
            <Grid item xs={3}>
              <Typography m={0} variant="h4">
                {t('homepage-onboarding.configuationsetup')}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Table
                sx={{
                  border: '1px solid lightgrey',
                  borderRadius: '30px',
                  mb: 1
                }}
              >
                <TableBody>
                  <HomePageTableRow
                    title={t('homepage.onboarding.addprovider.title')}
                    subTitle={t('homepage.onboarding.addprovider.subtitle')}
                    check={entityHasData[ONBOARDING_ENTITIES.ICP]}
                    navigateUrl={Configuration.ICP_PROVIDERS()}
                    disabled={!canAccesConfiguration}
                  />
                </TableBody>
              </Table>
              {!entityHasData[ONBOARDING_ENTITIES.ICP] && (
                <Box mt={2}>
                  <Typography>{t('homepage-onboarding.prerequisites.icp')}</Typography>
                </Box>
              )}
              <Table
                sx={{
                  border: '1px solid lightgrey',
                  borderRadius: '30px',
                  mb: 1
                }}
              >
                <TableBody>
                  <HomePageTableRow
                    title={t('homepage.onboarding.addemployer.title')}
                    subTitle={t('homepage.onboarding.addemployer.subtitle')}
                    check={entityHasData[ONBOARDING_ENTITIES.EMPLOYER]}
                    navigateUrl={Configuration.ICP_PROVIDERS()}
                    disabled={!entityHasData[ONBOARDING_ENTITIES.ICP] || !canAccesConfiguration}
                  />
                </TableBody>
              </Table>
              {!entityHasData[ONBOARDING_ENTITIES.EMPLOYER] && (
                <Box mt={2}>
                  <Typography>{t('homepage-onboarding.prerequisites.employer')}</Typography>
                </Box>
              )}
              <Table
                sx={{
                  border: '1px solid lightgrey',
                  borderRadius: '30px',
                  mb: 1
                }}
              >
                <TableBody>
                  <HomePageTableRow
                    title={t('homepage.onboarding.addpaygroup.title')}
                    subTitle={t('homepage.onboarding.addpaygroup.subtitle')}
                    check={entityHasData[ONBOARDING_ENTITIES.PAYGROUP]}
                    navigateUrl={Configuration.ICP_PROVIDERS()}
                    disabled={!entityHasData[ONBOARDING_ENTITIES.EMPLOYER] || !canAccesConfiguration}
                  />
                  <HomePageTableRow
                    title={t('homepage.onboarding.addpayrollcode.title')}
                    subTitle={t('homepage.onboarding.addpayrollcode.subtitle')}
                    check={entityHasData[ONBOARDING_ENTITIES.PAYROLLCODE]}
                    navigateUrl={Configuration.ICP_PROVIDERS()}
                    disabled={!entityHasData[ONBOARDING_ENTITIES.EMPLOYER] || !canAccesPayrollCodes}
                  />
                  <HomePageTableRow
                    title={t('homepage.onboarding.addworkschedule.title')}
                    subTitle={t('homepage.onboarding.addworkschedule.subtitle')}
                    check={entityHasData[ONBOARDING_ENTITIES.WORKSCHEDULE]}
                    navigateUrl={Configuration.ICP_PROVIDERS()}
                    disabled={!entityHasData[ONBOARDING_ENTITIES.EMPLOYER] || !canAccesConfiguration}
                  />
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={3}>
              <Typography m={0} variant="h4">
                {t('homepage-onboarding.teamandusers')}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Table sx={{ border: '1px solid lightgrey', borderRadius: '30px' }}>
                <TableBody>
                  <HomePageTableRow
                    title={t('homepage.onboarding.addcolleague.title')}
                    subTitle={t('homepage.onboarding.addcolleague.subtitle')}
                    check={
                      allusers?.totalCount != null &&
                      paybixUsers?.totalCount != null &&
                      allusers?.totalCount - paybixUsers?.totalCount > 1
                    }
                    navigateUrl={Configuration.USERS()}
                    disabled={!canAccessUsers}
                  />
                  <HomePageTableRow
                    title={t('homepage.onboarding.addteam.title')}
                    subTitle={t('homepage.onboarding.addteam.subtitle')}
                    check={onboardingTeams?.totalCount != null && onboardingTeams?.totalCount > 1}
                    navigateUrl={Configuration.TEAMS_ID(`${onboardingTeams?.entityId}`)}
                    disabled={!canAccessTeams}
                  />
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={3}>
              <Typography m={0} variant="h4">
                {t('homepage-onboarding.person')}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Table sx={{ border: '1px solid lightgrey', borderRadius: '30px' }}>
                <TableBody>
                  <HomePageTableRow
                    title={t('homepage.onboarding.addpopulation.title')}
                    subTitle={t('homepage.onboarding.addpopulation.subtitle')}
                    check={entityHasData[ONBOARDING_ENTITIES.PERSON]}
                    navigateUrl={People.PEOPLE()}
                    disabled={
                      !entityHasData[ONBOARDING_ENTITIES.ICP] ||
                      !entityHasData[ONBOARDING_ENTITIES.EMPLOYER] ||
                      !canAccessPeople
                    }
                  />
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default HomePageOnboarding
