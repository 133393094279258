import { Box, Button, List, ListItem, ListItemButton, ListItemText, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { People, ValidationErrors, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { useNavigate } from 'react-router-dom'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import MenuIcon from '@mui/icons-material/Menu'
import { IcpCodeBadge } from '../../icp-components'
import { useGetTopValidationsPerPersonQuery } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface ValidationsToSolveProps {
  errors: number | undefined
  warnings: number | undefined
}

export function ValidationsToSolve({ errors, warnings }: ValidationsToSolveProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme = useTheme()
  const { icpFilters } = useGlobalPersistedStore()
  const { data: getValidations } = useGetTopValidationsPerPersonQuery(
    {
      icps: icpFilters,
      limit: 3,
      showHasSeen: false
    },
    { suspense: false }
  )

  function goToPersonDetailsPage(personId: string) {
    navigate(People.PEOPLE_ID_DETAILS(personId))
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          mx: 1,
          gap: 2,
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2
          }}
        >
          <Typography sx={{ justifyContent: 'center' }} variant="h4">
            {t('homepage.validationstosolve')}
          </Typography>

          <Box sx={{ display: 'flex', gap: 2 }}>
            {warnings !== undefined && errors !== undefined && (
              <>
                {warnings !== 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 0.5,
                      color: theme.palette.warning.main,
                      alignItems: 'center',
                      height: '1.4rem'
                    }}
                  >
                    <WarningIcon fontSize="small" />
                    <Typography sx={{ fontSize: '0.8rem', color: theme.palette.warning.main }}>{warnings}</Typography>
                  </Box>
                )}
                {errors !== 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 0.5,
                      color: theme.palette.error.main,
                      alignItems: 'center',
                      height: '1.4rem'
                    }}
                  >
                    <ErrorIcon fontSize="small" />
                    <Typography sx={{ fontSize: '0.8rem', color: theme.palette.error.main }}>{errors}</Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
        <Box>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              navigate(ValidationErrors.VALIDATION_ERROR())
            }}
          >
            <MenuIcon />
            {t('homepage.validations.viewdetails')}
          </Button>
        </Box>
      </Box>
      <Box>
        <List>
          {getValidations?.topValidationsPerPerson.map((value, index) => (
            <ListItem
              key={value.personId}
              sx={{
                m: 0,
                p: 0,
                color: theme.palette.text.primary,
                borderBottom: '1px solid',
                borderTop: index === 0 ? '1px solid' : '',
                borderColor: theme.palette.primary.light
              }}
            >
              <ListItemButton
                sx={{ p: 0.5 }}
                onClick={() => {
                  goToPersonDetailsPage(value.personId)
                }}
              >
                <ListItemText sx={{ flex: 1 }} primary={` ${value.displayName}`} />
                <ListItemText sx={{ flex: 1 }}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {value.errorCount > 0 && (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          color: theme.palette.error.light
                        }}
                      >
                        <ErrorOutlineOutlinedIcon fontSize="small" />
                        <Typography>{value.errorCount}</Typography>
                      </Box>
                    )}
                    {value.warningCount > 0 && (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          color: theme.palette.warning.light
                        }}
                      >
                        <WarningAmberOutlinedIcon fontSize="small" />
                        <Typography>{value.warningCount}</Typography>{' '}
                      </Box>
                    )}
                  </Box>
                </ListItemText>
                <ListItemText sx={{ flex: 0.25 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'end', gap: 1 }}>
                    {value.countryCodeKeys &&
                      value.countryCodeKeys.map((c, index) => c !== null && <IcpCodeBadge key={index} icpCode={c} />)}
                  </Box>
                </ListItemText>
                <KeyboardArrowRightIcon sx={{ flex: 0.1, color: theme.palette.text.secondary }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  )
}

export default ValidationsToSolve
