import {
  addDays,
  OrderDirection,
  ToBackendFormatedDate,
  useGetPagedStartersQuery,
  useNameof
} from '@epix-web-apps/core'
import { Box, useTheme, Typography } from '@mui/material'
import { GridColDef, GridRowParams } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, SortModel } from '../../data-table'
import { IcpCodeBadge } from '../../icp-components'
import { FLYIN_WIDTH, People, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { useNavigate } from 'react-router-dom'
import { useFlyIn } from '@epix-web-apps/ui'

type TableRow = {
  id: string
  personId: string
  name: string
  countryKey: string
  date: string
}

/* eslint-disable-next-line */
export interface OverviewEventStartersProps {}

export function OverviewEventStarters(props: OverviewEventStartersProps) {
  const { t } = useTranslation()
  const { nameof } = useNameof<TableRow>()
  const navigate = useNavigate()
  const [tableData, setTableData] = useState<TableRow[]>()
  const theme = useTheme()
  const { closeFlyIn } = useFlyIn()

  const { icpFilters } = useGlobalPersistedStore()

  const columns: GridColDef<TableRow>[] = [
    {
      field: nameof('name'),
      headerName: t('analytics.homepage.events.name'),
      flex: 3,
      sortable: false
    },
    {
      field: nameof('countryKey'),
      headerName: t('analytics.homepage.events.country'),
      sortable: false,
      renderCell: params => <IcpCodeBadge icpCode={params.row.countryKey} />
    },
    {
      field: nameof('date'),
      headerName: t('analytics.homepage.events.date'),
      flex: 2,
      sortable: false
    }
  ]

  const { data: getPagedStarters, isLoading } = useGetPagedStartersQuery({
    icps: icpFilters,
    startDate: ToBackendFormatedDate(new Date()),
    endDate: ToBackendFormatedDate(addDays(new Date(), 30)),
    offset: 0,
    limit: -1
  })

  useEffect(() => {
    if (getPagedStarters) {
      const data = getPagedStarters.pagedStarters.data
      setTableData(
        data.map(row => {
          return {
            id: row.contractId,
            personId: row.personId,
            name: `${row.firstName} ${row.lastName}`,
            countryKey: row.countryKey,
            date: new Date(row.upcomingDate).toLocaleDateString()
          }
        })
      )
    }
  }, [getPagedStarters])

  function goToPersonContractDetailPage(personId: string, contractId: string) {
    navigate(People.PEOPLE_ID_CONTRACTS_ID(personId, contractId))
  }

  return (
    <Box
      sx={{
        width: FLYIN_WIDTH.DEFAULT,
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Typography variant="h4">{t('analytics.homepage.events.title.starters')}</Typography>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <DataTable
          totalRowCount={getPagedStarters?.pagedStarters.totalCount}
          data={tableData}
          columns={columns}
          isLoading={isLoading}
          sortModel={new SortModel(nameof('name'), OrderDirection.Asc)}
          onRowClick={(params: GridRowParams<TableRow>) => {
            goToPersonContractDetailPage(params.row.personId, params.row.id)
            closeFlyIn()
          }}
          hideFooter={true}
          emptyStateElement={
            <Typography
              sx={{
                mt: 2,
                color: theme.palette.text.secondary,
                textAlign: 'center'
              }}
            >
              {t('analytics.homepage.events.noresults')}
            </Typography>
          }
        />
      </Box>
    </Box>
  )
}

export default OverviewEventStarters
