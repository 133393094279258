import { Box, Grid, Typography } from '@mui/material'
import { SuspensePaper } from '../../suspense-paper'
import {
  formTypeSelectOptions,
  getNumberOfColors,
  ToBackendFormatedDate,
  useGetMeQuery,
  useGetProductivityOverviewByWeekdayQuery,
  useGetProductivityOverviewForPeriodQuery
} from '@epix-web-apps/core'
import BarchartComponent from '../../analytics-components/barchart-component/barchart-component'
import { useProductivityAnalyticsPersistedStore } from '../../../stores/productivity-store'
import { TypographyBold, useGlobalPersistedStore } from '@epix-web-apps/ui'
import PiechartComponent, { PieData } from '../../analytics-components/piechart-component/piechart-component'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function ProductivityOverviewTab() {
  const { data: me } = useGetMeQuery()
  const { viewPeriod } = useProductivityAnalyticsPersistedStore()
  const { t } = useTranslation()
  const [period, setPeriod] = useState<Date[] | null>([new Date(), new Date()])

  const { productivityViewPeriodTypeOptions } = formTypeSelectOptions
  const viewPeriodTypeLabel = productivityViewPeriodTypeOptions.find(v => v.id === viewPeriod)?.label

  return (
    <Grid container spacing={2} alignItems="start">
      <Grid item xs={12} xl={6}>
        <SuspensePaper title={viewPeriodTypeLabel}>
          <TypographyBold textAlign="center">
            {period && period[0].toLocaleDateString(me?.me.locale.locale)}
            {' - '}
            {period && period[1].toLocaleDateString(me?.me.locale.locale)}
          </TypographyBold>
          <PreviousPeriodComponent />
        </SuspensePaper>
      </Grid>
      <Grid item xs={12} xl={6}>
        <SuspensePaper title={t('productivity.overview.weekdays')}>
          <TypographyBold textAlign="center">
            {period && period[0].toLocaleDateString(me?.me.locale.locale)}
            {' - '}
            {period && period[1].toLocaleDateString(me?.me.locale.locale)}
          </TypographyBold>
          <WeekdaysComponent setPeriod={setPeriod} />
        </SuspensePaper>
      </Grid>
    </Grid>
  )
}

export default ProductivityOverviewTab

function PreviousPeriodComponent() {
  const { icpFilters } = useGlobalPersistedStore()
  const { dateFilter, employerFilter, subgroupFilter, viewPeriod, paygroupFilter } =
    useProductivityAnalyticsPersistedStore()
  const { data: getproductivityOverviewForPeriod } = useGetProductivityOverviewForPeriodQuery({
    viewDate: dateFilter ? ToBackendFormatedDate(dateFilter) : null,
    viewPeriodKey: viewPeriod,
    employerId: employerFilter,
    paygroupId: paygroupFilter,
    subgroupKeys: subgroupFilter,
    icps: icpFilters
  })
  const data = getproductivityOverviewForPeriod?.productivityOverviewForPeriod.payrollGroupHours.map(pgh => {
    return new PieData(pgh.subGroupName, pgh.amount.toString(), `${pgh.subGroupName}: ${pgh.amount}`)
  })
  const total = getproductivityOverviewForPeriod?.productivityOverviewForPeriod.payrollGroupHours.reduce(
    (acc, curr) => {
      return acc + curr.amount
    },
    0
  )

  return <PiechartComponent data={data} total={total} enableArcLinkLabels={false} />
}

function WeekdaysComponent({ setPeriod }: { setPeriod: (value: Date[]) => void }) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const { dateFilter, employerFilter, subgroupFilter, viewPeriod, paygroupFilter } =
    useProductivityAnalyticsPersistedStore()
  const { data: productivityByWeekdayQuery } = useGetProductivityOverviewByWeekdayQuery({
    viewDate: dateFilter ? ToBackendFormatedDate(dateFilter) : null,
    viewPeriodKey: viewPeriod,
    employerId: employerFilter,
    paygroupId: paygroupFilter,
    subgroupKeys: subgroupFilter,
    icps: icpFilters
  })

  useEffect(() => {
    if (productivityByWeekdayQuery?.productivityOverviewByWeekday) {
      setPeriod([
        new Date(productivityByWeekdayQuery?.productivityOverviewByWeekday.startDate),
        new Date(productivityByWeekdayQuery?.productivityOverviewByWeekday.endDate)
      ])
    }
  }, [
    productivityByWeekdayQuery?.productivityOverviewByWeekday.startDate,
    productivityByWeekdayQuery?.productivityOverviewByWeekday.endDate
  ])

  const keys = new Set(
    productivityByWeekdayQuery?.productivityOverviewByWeekday.weekdays.flatMap(dow =>
      dow.payrollGroupHours.map(psgh => psgh.subGroupName)
    )
  )

  const indexByKey = 'day'

  const data = productivityByWeekdayQuery?.productivityOverviewByWeekday.weekdays.map(pbw => {
    const record: Record<string, any> = {}
    record[indexByKey] = pbw.day.charAt(0).toUpperCase() + pbw.day.slice(1).toLowerCase()
    pbw.payrollGroupHours.forEach(pgh => {
      record[pgh.subGroupName] = pgh.amount
    })
    return record
  })

  if (
    productivityByWeekdayQuery?.productivityOverviewByWeekday.weekdays.flatMap(w => w.payrollGroupHours).length === 0
  ) {
    return <Typography sx={{ p: 2, textAlign: 'center' }}>{t('analytics.nodata')}</Typography>
  }

  return (
    <Box height={'25rem'}>
      <BarchartComponent
        keys={Array.from(keys)}
        data={data}
        indexBy={indexByKey}
        layoutMode="vertical"
        groupMode="stacked"
        colors={getNumberOfColors(keys.size)}
        showLegend={keys.size < 4}
        legendWidth={200}
        legendCharLength={30}
        axisLeft={t('barchartcomponent.component.axe.hours')}
        axisLeftPadding={-50}
        marginLeft={70}
      />
    </Box>
  )
}
