import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import DoneIcon from '@mui/icons-material/Done'
import { Box, Button, IconButton, MenuItem } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { SortModel, usePaginationModel, ScrollableDataTable } from '../../components/data-table'
import { OrderDirection, useGetAllFunctionAreaTypesQuery } from '@epix-web-apps/core'
import DropdownMenu from '../../components/dropdown-menu'
import { HeaderTitleNavigation, People, useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import AddEditFunctionArea from '../../components/function-area-components/add-edit-function-area'

/* eslint-disable-next-line */
export interface FunctionAreasPageProps {}

export function FunctionAreasPage(props: FunctionAreasPageProps) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [rowCount, setRowCount] = useState(0)
  const { paginationModel, setPaginationModel } = usePaginationModel()
  const [clickedclickedFunctionAreaKey, setClickedFunctionAreaKey] = useState<string>('')
  const { openFlyIn } = useFlyIn()

  const activeActions = (rowParams: GridRenderCellParams) => (rowParams.value ? <DoneIcon /> : <VisibilityOffIcon />)

  const rowActions = (rowParams: GridRenderCellParams) => {
    return (
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setClickedFunctionAreaKey(rowParams.row.key)
          setAnchorEl(e.currentTarget)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'value',
      headerName: t('functionareaspage.datatable.column.description'),
      flex: 1
    },
    {
      field: 'key',
      headerName: t('functionareaspage.datatable.column.code'),
      flex: 1
    },
    {
      field: 'active',
      headerName: t('functionareaspage.datatable.column.active'),
      flex: 1,
      sortable: true,
      editable: false,
      renderCell: activeActions
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns[0]?.field, OrderDirection.Asc))
  const [tableData, setTableData] = useState<GridRowsProp>()

  const {
    data: functionAreasData,
    isLoading: isLoadingFunctionArea,
    refetch: refetchAllFunctionAreas
  } = useGetAllFunctionAreaTypesQuery(
    {
      offset: paginationModel.offset,
      limit: paginationModel.pageSize,
      sortByProperty: sortModel.field,
      orderDirection: sortModel.orderDirection
    },
    {
      suspense: false
    }
  )

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2]
      }}
    >
      <HeaderTitleNavigation title={t('functionareaspage.title')} backToLink={People.PEOPLE()} />

      <Button
        variant="contained"
        onClick={() =>
          openFlyIn({
            content: <AddEditFunctionArea />,
            callbackAfterClose: () => refetchAllFunctionAreas
          })
        }
      >
        <AddIcon />
        {t('functionareaspage.button.addfunctionarea')}
      </Button>
    </Box>
  )

  useEffect(() => {
    if (functionAreasData && functionAreasData.functionAreaTypes) {
      const functionAreas = functionAreasData.functionAreaTypes
      setRowCount(functionAreas.totalCount)
      setTableData(
        functionAreas.data.map(row => {
          return {
            id: row.key,
            key: row.key,
            value: row.value,
            active: row.active
          }
        })
      )
    }
  }, [functionAreasData])

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onSortChange={setSortModel}
        isLoading={isLoadingFunctionArea}
        sortModel={sortModel}
        filterBarElement={filterBar}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <AddEditFunctionArea functionAreaKey={clickedclickedFunctionAreaKey} />,
              callbackAfterClose: () => refetchAllFunctionAreas
            })
          }
        >
          {t('functionareaspage.list.row.menu.edit-functionareatype')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default FunctionAreasPage
