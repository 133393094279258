// use this object from now on and add routes from modules by adding functions per module like function analyticsRoutes()
export const useRouteDefinitions = () => {
  return {
    peopleRoutes: getPeopleRoutes(),
    analyticsRoutes: getAnalyticsRoutes()
  }
}

function getPeopleRoutes() {
  return {
    ROOT: '/people',
    PEOPLE_ID: (id: string) => `/people/${id}`,
    PEOPLE_ID_DETAILS: (id: string) => `/people/${id}/details`,
    PEOPLE_ID_VALIDATION_ERRORS: (id: string) => `/people/${id}/validation-errors`,
    PEOPLE_ID_ORGANISATION: (id: string) => `/people/${id}/organisation`,
    PEOPLE_ID_CONTRACTS: (id: string) => `/people/${id}/contracts`,
    PEOPLE_ID_CONTRACTS_ID: (id: string, contractId: string) => `/people/${id}/contracts/${contractId}`,
    PEOPLE_ID_CONTRACTS_ID_DOCUMENTTYPE_ID: (id: string, contractId: string, documentTypeId: string) =>
      `/people/${id}/contracts/${contractId}/documenttype/${documentTypeId}`
  }
}

function getAnalyticsRoutes() {
  const root = '/analytics'
  return {
    ROOT: root,
    WORKFORCE: `${root}/workforce`,
    LABOUR_COST: `${root}/labourcost`,
    PRODUCTIVITY: `${root}/productivity`
  }
}

/* prettier-ignore */
export const Import = {
  IMPORT: '/import',
  DATA: '/import/data'
}

export const GeneralRoutes = {
  ROOT: '/home',
  NOTAUTHORIZED: '/not-authorized',
  NOTFOUND: '/404',
  AUTHREDIRECTPAGE: '/auth-redirect'
}

/* prettier-ignore */
export const Configuration = {
  TENANT_CONFIGURATION: () => '/tenant-configuration',
  ICP_PROVIDERS: () => `/configuration/icp-providers`,
  ICP_PROVIDERS_EXTERNAL_CODES: () => '/configuration/external-codes',
  ICP_PROVIDERS_ID_EMPLOYERS: (id: string) => `/configuration/icp-providers/${id}/employers`,
  ICP_PROVIDERS_ID_EMPLOYERID: (id: string, employerId: string) => `/configuration/icp-providers/${id}/employers/${employerId}`,
  ICP_PROVIDERS_ID_EMPLOYERID_PAYROLLCODES: (id: string, employerId: string) => `/configuration/icp-providers/${id}/employers/${employerId}/payroll-codes`,
  ICP_PROVIDERS_ID_EMPLOYERID_SHIFTCODES: (id: string, employerId: string) => `/configuration/icp-providers/${id}/employers/${employerId}/shift-codes`,
  ICP_PROVIDERS_ID_EMPLOYERID_PAYGROUPS: (id: string, employerId: string) => `/configuration/icp-providers/${id}/employers/${employerId}/pay-groups`,
  ICP_PROVIDERS_ID_EMPLOYERID_DAYDEFINITIONS: (id: string, employerId: string) => `/configuration/icp-providers/${id}/employers/${employerId}/day-definitions`,
  ICP_PROVIDERS_ID_EMPLOYERID_DAYDEFINITIONENTRIES: (id: string, employerId: string, dayDefinitionId: string) => `/configuration/icp-providers/${id}/employers/${employerId}/day-definitions/${dayDefinitionId}/day-definition-entries`,
  ICP_PROVIDERS_ID_EMPLOYERID_COLLECTIVE_CALENDAR: (id: string, employerId: string) =>  `/configuration/icp-providers/${id}/employers/${employerId}/collective-calendar`,
  ICP_PROVIDERS_ID_EMPLOYERID_SMART_PAYCOMPONENTS: (id: string, employerId: string) => `/configuration/icp-providers/${id}/employers/${employerId}/smart-paycomponents`,
  ICP_PROVIDERS_ID_EMPLOYERID_SMART_PAYCOMPONENTS_ID: (id: string, employerId: string, smartPayComponentId: string) => `/configuration/icp-providers/${id}/employers/${employerId}/smart-paycomponents/${smartPayComponentId}`,
  ICP_PROVIDERS_ID_EMPLOYERID_WORKSCHEDULE: (id: string, employerId: string) => `/configuration/icp-providers/${id}/employers/${employerId}/workschedule`,
  ICP_PROVIDERS_ID_EMPLOYERID_BRANCHUNIT: (id: string, employerId: string) => `/configuration/icp-providers/${id}/employers/${employerId}/branch-unit`,
  ICP_PROVIDERS_ID_EMPLOYERID_REQUESTPOLICIES: (id: string, employerId: string) =>`/configuration/icp-providers/${id}/employers/${employerId}/request-policies`,
  ICP_PROVIDERS_ID_EMPLOYERID_DOCUMENTS: (id: string, employerId: string) =>`/configuration/icp-providers/${id}/employers/${employerId}/documenttypes`,
  ICP_PROVIDERS_ID_EMPLOYERID_REQUESTPOLICIES_ID: (id: string, employerId: string, requestPolicyId: string) => `/configuration/icp-providers/${id}/employers/${employerId}/request-policies/${requestPolicyId}`,
  ICP_PROVIDERS_ID_EMPLOYERID_COLLECTIVE_CALENDAR_ID: (id: string, employerId: string, collectiveCalendarId: string) => `/configuration/icp-providers/${id}/employers/${employerId}/collective-calendar/${collectiveCalendarId}`,
  ICP_PROVIDERS_ID_EMPLOYERID_DAYDEFINITION_ID_ENTRIES: (id: string, employerId: string, dayDefinitionId: string) => `/configuration/icp-providers/${id}/employers/${employerId}/day-definitions/${dayDefinitionId}/day-definition-entries`,
  USERS: () => '/configuration/users',
  USERS_ID: (id: string) => `/configuration/users/${id}`,
  CAR_CATEGORIES: () => '/configuration/car-categories',
  FUNCTION_AREAS: () => '/configuration/function-areas',
  FUNCTION_LEVELS: () => '/configuration/function-levels',
  ROLES: () => '/configuration/roles',
  ROLES_TEAM_ID: (teamId: string) => `/configuration/roles?teamId=${teamId}`,
  TEAMS: () => '/configuration/teams',
  TEAMS_ID: (id: string) => `/configuration/teams/${id}`,
  TEAM_ID_TAB: (teamId: string, tabIndex: number) => `/configuration/teams/${teamId}?tabIndex=${tabIndex}`
}

export const CompanyCar = {
  COMPANY_CAR: `/company-cars`,
  ID: (id: string) => `/company-cars/${id}`
}

/* prettier-ignore */
export const Process = {
  PROCESSES: () => `/processes`,
  PROCESSES_TYPE: (type: string) => `/processes/${type}`,
  PROCESSES_TYPE_ID: (type: string, id: string) => `/processes/${type}/${id}`
}

/* prettier-ignore */
export const ValidationErrors = {
  VALIDATION_ERROR: () => '/validation-errors'
}

/* prettier-ignore */
export const People = {
  PEOPLE: () => `/people`,
  PEOPLE_ID: (id: string) => `/people/${id}`,
  PEOPLE_ID_DETAILS: (id: string) => `/people/${id}/details`,
  PEOPLE_ID_VALIDATION_ERRORS: (id: string) => `/people/${id}/validation-errors`,
  PEOPLE_ID_ORGANISATION: (id: string) => `/people/${id}/organisation`,
  PEOPLE_ID_CONTRACTS: (id: string) => `/people/${id}/contracts`,
  PEOPLE_ID_CONTRACTS_ID: (id: string, contractId: string) => `/people/${id}/contracts/${contractId}`,
  PEOPLE_ID_CONTRACTS_ID_DOCUMENTTYPE_ID: (id: string, contractId: string, documentTypeId: string) => `/people/${id}/contracts/${contractId}/documenttype/${documentTypeId}`
}

export const AiCompanion = {
  AICOMPANION: '/ai-companion'
}

export const Documents = {
  DOCUMENTS: '/documents',
  DOCUMENTS_ICP_ID_EMPLOYER_ID_DOCUMENTTYPE_ID: (icpId: string, employerId: string, documentTypeId: string) =>
    `/documents/${icpId}/${employerId}/${documentTypeId}`
}

export const Documentation = {
  VALIDATIONS: '/documentation/validations'
}

/* prettier-ignore */
export const SelfService = {
    ROOT : () => `/selfservice`,
    PERSON_CALENDAR_TEAMID_ID: (teamId: string, id: string) => `/selfservice/person-calendar/${teamId}/${id}`,
    BALANCE_OVERVIEW: () => `/selfservice/balance-overview`,
    BALANCE_OVERVIEW_DATE: (date: any) => `/selfservice/balance-overview?date=${date}`,
    BALANCE_CALCULATION_ID: (id: string) => `/selfservice/balance-calculation/${id}`,
    PROFILE_SETTINGS: () => `/selfservice/profile-settings`,
    DOCUMENTS: () => `/selfservice/documents`,
    PENDING_TASKS: () => `/selfservice/pending-tasks`,
    DOCUMENTS_CONTRACTID_DOCUMENTTYPEID: (contractId: string, documentTypeId: string) => `/selfservice/documents/contracts/${contractId}/documenttypes/${documentTypeId}`,
    DOCUMENTS_EMPLOYERID_DOCUMENTTYPEID: (employerId: string, documentTypeId: string) => `/selfservice/documents/employers/${employerId}/documenttypes/${documentTypeId}`,
    MY_REQUESTS: () => `/selfservice/my-requests`,
    MY_REQUESTS_ID: (id: string) => `/selfservice/my-requests/${id}`,
    TEAM_CALENDAR_TEAMID_ID: (teamId: string, id: string) => `/selfservice/team-calendar/${teamId}/${id}`,
    TEAM_CALENDAR_TEAMID_ID_DATE: (teamId: string, id: string, date: any) => `/selfservice/team-calendar/${teamId}/${id}?date=${date}`,
    TEAM_CALENDAR_TEAMID_ID_FILTER: (teamId: string, id: string) => `/selfservice/team-calendar/${teamId}/${id}/teams-filter`,
    PENDING_REQUESTS: () => `/selfservice/pending-requests`,
    ABSENCE_REQUEST_ID: (id: string) => `/selfservice/absence-requests/${id}`,
    REQUEST_ABSENCE_PERSONID: (personId: string) => `/selfservice/request-absence/${personId}`,
    REQUEST_ABSENCE_PERSONID_DATE: (personId: string, date: any) => `/selfservice/request-absence/${personId}?date=${date}`,
    REQUEST_ABSENCE_TEAMID_ID: (teamId: string, id: string) => `/selfservice/request-absence/${teamId}/${id}`,
    REQUEST_ABSENCE_TEAMID_ID_DATE: (teamId: string, id: string, date: any) => `/selfservice/request-absence/${teamId}/${id}?date=${date}`,
    DAY_DETAIL_DAY_ID_TEAMID: (day: string, id: string, teamId: string) => `/selfservice/day-detail/${day}/${id}/${teamId}`,
    DAY_DETAIL_DAY_PERSONID: (day: string, id: string) => `/selfservice/day-detail/${day}/${id}`,
    SELFSERVICE_DATE: (date: any) => `/selfservice?date=${date}`,
    SELFSERVICE_PENDING_REQUESTS_DATE: (date: any) => `/selfservice/pending-requests?date=${date}`,
    SELFSERVICE_ABSENCE_REQUESTS_DATE: (id: string, date: any) => `/selfservice/absence-requests/${id}?date=${date}`,
    SELFSERVICE_REQUEST_ABSENCE_DATE: (personId: string, date: any) => `/selfservice/request-absence/${personId}?date=${date}`,
}
