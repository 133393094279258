import styled from '@emotion/styled'
import { Box, Divider, Paper, SxProps, Typography, useTheme } from '@mui/material'

export type DetailPageBaseQueryParams = {
  id: string
}

export interface DetailPage {
  children: React.ReactNode
}

const DetailPage = styled(Paper)(() => ({
  display: 'flex',
  minHeight: '100%',
  height: '100%',
  border: 0
}))

export const DetailPageSidebar = styled.div(() => {
  const theme = useTheme()
  return {
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    borderRadius: 5,
    minWidth: '15rem',
    width: '15rem',
    padding: '1.5rem',
    marginRight: '1.5rem',
    fontSize: '0.8rem',
    p: {
      margin: 0
    },
    hr: {
      paddingTop: '1rem',
      marginBottom: '1rem'
    }
  }
})

export const DetailPageContentHeader = styled.div(({ noBorderBottom }: { noBorderBottom?: boolean }) => {
  const theme = useTheme()
  return {
    margin: '-3.5rem 0',
    padding: '1rem 1.5rem',
    borderBottom: noBorderBottom ? 'none' : `1px solid ${theme.palette.divider}`,
    marginBottom: '0.5rem',
    'h1,h2,h3,h4,h5,h6': {
      margin: 0
    }
  }
})

export const DetailPageContent = styled.div(() => ({
  flexGrow: 1,
  minWidth: 0,
  padding: '3.5rem 0.5rem 2rem 0.5rem'
}))

interface DetailBoxRowProps {
  sx?: SxProps
  children: React.ReactElement | React.ReactElement[]
}

export function DetailBoxRow({ sx, children }: DetailBoxRowProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '.MuiTypography-root': {
          my: '0.25rem'
        },
        ...sx
      }}
    >
      {children}
    </Box>
  )
}

interface DetailBoxProps {
  title: string
  actionIcon?: React.ReactNode
  children: React.ReactNode
}

export function DetailBox({ title, actionIcon, children }: DetailBoxProps) {
  const theme = useTheme()

  return (
    <Box sx={{ mb: '2rem', flex: 1 }}>
      <DetailBoxRow
        sx={{
          '.MuiSvgIcon-root': {
            color: theme.palette.primary.main,
            padding: 0,
            width: '1.25rem',
            height: '1.25rem'
          }
        }}
      >
        <>
          <Typography variant="h4" my={0}>
            {title}
          </Typography>
          {actionIcon}
        </>
      </DetailBoxRow>
      <Divider />
      {children}
    </Box>
  )
}

export function DetailPageLayout({ children }: DetailPage) {
  return <DetailPage>{children}</DetailPage>
}

export default DetailPageLayout
