import { OrderDirection, useGetTeamAuthorizationsByTeamIdQuery } from '@epix-web-apps/core'
import { Box, Button, Typography, useTheme, IconButton, MenuItem } from '@mui/material'
import { Configuration, useFlyIn } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { DataTable, usePaginationModel, SortModel } from '../../data-table'
import DataTableEmpty from '../../data-table/data-table-empty/data-table-empty'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CheckIcon from '@mui/icons-material/Check'
import AddEditTeamAuthorization from '../add-edit-team-authorization/add-edit-team-authorization'
import EditIcon from '@mui/icons-material/Edit'
import DropdownMenu from '../../dropdown-menu/dropdown-menu'
import RemoveTeamAuthorization from '../remove-team-authorization/remove-team-authorization'
import SettingsButton from '../../settings-button'

/* eslint-disable-next-line */
export interface AssignedRolesProps {
  teamId: string | undefined
}

export function AssignedRoles({ teamId }: AssignedRolesProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const [tableData, setTableData] = useState<GridRowsProp>()
  const [rowCount, setRowCount] = useState(0)
  const { paginationModel, setPaginationModel } = usePaginationModel()
  const { openFlyIn } = useFlyIn()
  const [clickedTeamAuthorization, setClickedTeamAuthorization] = useState('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const rowActions = (rowParams: GridRenderCellParams) => (
    <IconButton
      aria-label="row actions"
      aria-controls="menu-row"
      aria-haspopup="true"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        setClickedTeamAuthorization(rowParams.row.id)
        setAnchorEl(e.currentTarget)
      }}
    >
      <EditIcon fontSize="small" />
    </IconButton>
  )

  const validForChildren = (rowParams: GridRenderCellParams) => {
    if (rowParams.row.validForChildren) {
      return (
        <Typography color={theme.palette.success.main}>
          <CheckIcon />
        </Typography>
      )
    } else {
      return (
        <Typography color={theme.palette.error.light}>
          <CancelOutlinedIcon sx={{ fontSize: 20 }} />
        </Typography>
      )
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('teamroles.datatable.column.name'),
      flex: 1,
      sortable: false
    },
    {
      field: 'function',
      headerName: t('teamroles.datatable.column.role'),
      flex: 1
    },
    {
      field: 'validForChildren',
      headerName: t('teamroles.datatable.column.validforchildren'),
      renderCell: validForChildren,
      flex: 1,
      sortable: false
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns?.[1]?.field, OrderDirection.Asc))

  const {
    data: getTeamAuthorization,
    isLoading: isLoadingTeams,
    refetch: refetchTeam
  } = useGetTeamAuthorizationsByTeamIdQuery({
    id: teamId || '',
    offset: paginationModel.offset,
    limit: paginationModel.pageSize,
    sortByProperty: sortModel.field,
    orderDirection: sortModel.orderDirection
  })

  useEffect(() => {
    if (getTeamAuthorization && getTeamAuthorization?.allTeamAuthorizationsByTeamId.data) {
      const data = getTeamAuthorization.allTeamAuthorizationsByTeamId.data
      setTableData(
        data.map(row => {
          return {
            id: row.id,
            name: row.personName,
            function: row.functionName,
            validForChildren: row.validForChildTeams
          }
        })
      )
      setRowCount(getTeamAuthorization.allTeamAuthorizationsByTeamId.totalCount)
    }
  }, [getTeamAuthorization?.allTeamAuthorizationsByTeamId])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: '0.5rem'
        }}
      >
        <Typography variant="h4">{t('teamdetailspage.assignedroles.title')}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <SettingsButton
            menuItems={[
              {
                label: t('teams.settings.roles'),
                to: Configuration.ROLES_TEAM_ID(teamId || '')
              }
            ]}
          />

          <Button
            sx={{
              display: 'block',
              ml: 'auto',
              mr: 0
            }}
            variant="contained"
            onClick={() =>
              openFlyIn({
                content: <AddEditTeamAuthorization teamId={teamId} />,
                callbackAfterClose: () => refetchTeam
              })
            }
          >
            {t('teamdetailspage.roles.addteamauthorization')}
          </Button>
        </Box>
      </Box>

      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        onSortChange={setSortModel}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        isLoading={isLoadingTeams}
        sortModel={sortModel}
        emptyStateElement={
          <DataTableEmpty
            title={t('emptystate.teamauthorization')}
            icon={<GroupAddIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
          />
        }
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <AddEditTeamAuthorization teamId={teamId} teamAuthorizationId={clickedTeamAuthorization} />,
              callbackAfterClose: () => refetchTeam
            })
          }
        >
          {t('assignedrolespage.list.row.menu.edit-teamauthorization')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <RemoveTeamAuthorization teamAuthorizationId={clickedTeamAuthorization} />,
              callbackAfterClose: () => refetchTeam
            })
          }
        >
          {t('assignedrolespage.list.row.menu.remove-teamauthorization')}
        </MenuItem>
      </DropdownMenu>
    </Box>
  )
}

export default AssignedRoles
