import { useTranslation } from 'react-i18next'
import { minWidthPayrollClosure } from '../steps'
import { StepParams } from '../../generic-steps'
import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { GridColDef, GridRowsProp } from '@mui/x-data-grid'
import {
  DEFAULT_LOCALE,
  OrderDirection,
  useGetPayrollClosureProcessQuery,
  useGetPersonCompanyCarCorrectionsByProcessIdQuery
} from '@epix-web-apps/core'
import { useParams } from 'react-router-dom'
import { DataTable, usePaginationModel, SortModel } from '../../../data-table'
import DoneIcon from '@mui/icons-material/Done'
import DataTableEmpty from '../../../data-table/data-table-empty'
import DataExceedsPagesize from '../data-exceeds-pagesize'

/* eslint-disable-next-line */
export interface CompanyCarCorrectionsComponentProps {}

export function CompanyCarCorrectionsComponent(props: CompanyCarCorrectionsComponentProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useParams<StepParams>()
  const [rowCount, setRowCount] = useState(0)
  const { paginationModel } = usePaginationModel(0, 101)
  const [tableData, setTableData] = useState<GridRowsProp>()

  const { data: getPayrollClosureProcess } = useGetPayrollClosureProcessQuery(
    {
      id: params?.id || ''
    },
    {
      enabled: !!params?.id
    }
  )

  const { data: personCompanyCarCorrections, isLoading } = useGetPersonCompanyCarCorrectionsByProcessIdQuery(
    {
      processId: getPayrollClosureProcess?.payrollClosureProcess.id || '',
      offset: paginationModel.offset,
      limit: paginationModel.pageSize
    },
    {
      suspense: false
    }
  )

  const columns: GridColDef[] = [
    {
      field: 'version',
      headerName: t('personcontractdata.datatable.column.version'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employerNumber',
      headerName: t('personcontractdata.datatable.column.employernumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employeeNumber',
      headerName: t('personcontractdata.datatable.column.employeenumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employeeName',
      headerName: t('personcontractdata.datatable.column.employeename'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'startDate',
      headerName: t('personcontractdata.datatable.column.startdate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'endDate',
      headerName: t('personcontractdata.datatable.column.enddate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'typeOfCarKey',
      headerName: t('personcontractdata.datatable.column.typeofcarkey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'typeOfCar',
      headerName: t('personcontractdata.datatable.column.typeofcar'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'licensePlate',
      headerName: t('personcontractdata.datatable.column.licenseplate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'countryKey',
      headerName: t('personcontractdata.datatable.column.countrykey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'country',
      headerName: t('personcontractdata.datatable.column.country'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'carBrandKey',
      headerName: t('personcontractdata.datatable.column.carbrandkey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'carBrand',
      headerName: t('personcontractdata.datatable.column.carbrand'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'model',
      headerName: t('personcontractdata.datatable.column.model'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'carCategoryCode',
      headerName: t('personcontractdata.datatable.column.carcategorycode'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'carCategory',
      headerName: t('personcontractdata.datatable.column.carcategory'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'motorTypeKey',
      headerName: t('personcontractdata.datatable.column.motortypekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'motorType',
      headerName: t('personcontractdata.datatable.column.motortype'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'co2',
      headerName: t('personcontractdata.datatable.column.co2'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'registrationDate',
      headerName: t('personcontractdata.datatable.column.registrationdate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'carStartDate',
      headerName: t('personcontractdata.datatable.column.carstartdate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'carEndDate',
      headerName: t('personcontractdata.datatable.column.carenddate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'monthlyCost',
      headerName: t('personcontractdata.datatable.column.monthlycost'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'tco',
      headerName: t('personcontractdata.datatable.column.tco'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'carContractTypeKey',
      headerName: t('personcontractdata.datatable.column.carcontracttypekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'carContractType',
      headerName: t('personcontractdata.datatable.column.carcontracttype'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'isPersonalContribution',
      headerName: t('personcontractdata.datatable.column.ispersonalcontribution'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'oneTimeContribution',
      headerName: t('personcontractdata.datatable.column.onetimecontribution'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'monthlyContribution',
      headerName: t('personcontractdata.datatable.column.monthlycontribution'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'hasFuelCard',
      headerName: t('personcontractdata.datatable.column.hasfuelcard'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'totalCarCost',
      headerName: t('personcontractdata.datatable.column.totalcarcost'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'vat',
      headerName: t('personcontractdata.datatable.column.vat'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'catalogueValue',
      headerName: t('personcontractdata.datatable.column.cataloguevalue'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'options',
      headerName: t('personcontractdata.datatable.column.options'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'hasTaxCalculations',
      headerName: t('personcontractdata.datatable.column.hastaxcalculations'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'carUsageKey',
      headerName: t('personcontractdata.datatable.column.carusagekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'carUsage',
      headerName: t('personcontractdata.datatable.column.carUsage'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'hasChargingStation',
      headerName: t('personcontractdata.datatable.column.haschargingstation'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'horsePower',
      headerName: t('personcontractdata.datatable.column.horsepower'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'personalContributionPercentage',
      headerName: t('personcontractdata.datatable.column.personalcontributionpercentage'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    }
  ]

  const [sortModel, _] = useState(new SortModel(columns?.[1]?.field, OrderDirection.Asc))

  useEffect(() => {
    if (personCompanyCarCorrections && personCompanyCarCorrections?.personCompanyCarHistoryCorrectionsByProcessId) {
      const personCompanyCarCorrectionsData = personCompanyCarCorrections.personCompanyCarHistoryCorrectionsByProcessId
      setTableData(
        personCompanyCarCorrectionsData.data.map((row: any, index: number) => {
          return {
            id: index,
            version: row.version,
            employerNumber: row.employerNumber,
            employeeNumber: row.employeeNumber,
            employeeName: row.employeeName,
            startDate: new Date(row.startDate).toLocaleDateString(DEFAULT_LOCALE),
            endDate: row.endDate ? new Date(row.endDate).toLocaleDateString(DEFAULT_LOCALE) : null,
            typeOfCarKey: row.typeOfCar?.key,
            typeOfCar: row.typeOfCar?.value,
            licensePlate: row.licensePlate,
            countryKey: row.countryModel?.code,
            country: row.countryModel?.name,
            carBrandKey: row.carBrand?.key,
            carBrand: row.carBrand?.value,
            model: row.model,
            carCategoryCode: row.carCategoryCode,
            carCategory: row.carCategoryDescription,
            motorTypeKey: row.motorType?.key,
            motorType: row.motorType?.value,
            co2: row.co2,
            registrationDate: row.registrationDate
              ? new Date(row.registrationDate).toLocaleDateString(DEFAULT_LOCALE)
              : null,
            carStartDate: new Date(row.carStartDate).toLocaleDateString(DEFAULT_LOCALE),
            carEndDate: row.carEndDate ? new Date(row.carEndDate).toLocaleDateString(DEFAULT_LOCALE) : null,
            monthlyCost: row.monthlyCost,
            tco: row.tco,
            carContractTypeKey: row.carContractType?.key,
            carContractType: row.carContractType?.value,
            isPersonalContribution: row.isPersonalContribution,
            oneTimeContribution: row.oneTimeContribution,
            monthlyContribution: row.monthlyContribution,
            hasFuelCard: row.hasFuelCard,
            totalCarCost: row.totalCarCost,
            vat: row.vat,
            catalogueValue: row.catalogueValue,
            options: row.options,
            hasTaxCalculations: row.hasTaxCalculations,
            carUsageKey: row.carUsage?.key,
            carUsage: row.carUsage?.value,
            hasChargingStation: row.hasChargingStation,
            horsePower: row.horsePower,
            personalContributionPercentage: row.personalContributionPercentage
          }
        })
      )
      setRowCount(personCompanyCarCorrectionsData.totalCount)
    }
  }, [personCompanyCarCorrections])

  return (
    <>
      {rowCount === paginationModel.pageSize && <DataExceedsPagesize />}
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        hideFooter={true}
        isLoading={isLoading}
        sortModel={sortModel}
        emptyStateElement={
          <DataTableEmpty
            title={t('emptystate.process.contract')}
            icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
          />
        }
      />
    </>
  )
}

export default CompanyCarCorrectionsComponent
