import { Grid } from '@mui/material'
import { People, useFlyIn, useGlobalPersistedStore } from '@epix-web-apps/ui'
import {
  addDays,
  FormatDateToDDMM,
  ToBackendFormatedDate,
  UpcomingAnniversariesBirthdaysModel,
  useGetPagedBirthdaysQuery
} from '@epix-web-apps/core'
import { HideableSuspensePaper } from '../../suspense-paper'
import { useTranslation } from 'react-i18next'
import HomepageEventWidget from '../homepage-event-widget'
import { useNavigate } from 'react-router-dom'
import { OverviewEventBirthdays } from '../overview-event-birthdays'

/* eslint-disable-next-line */
export interface UpcomingBirthdaysProps {}

export function UpcomingBirthdays(props: UpcomingBirthdaysProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const { icpFilters } = useGlobalPersistedStore()
  const { data: pagedBirthdays, isLoading } = useGetPagedBirthdaysQuery(
    {
      icps: icpFilters,
      startDate: ToBackendFormatedDate(new Date()),
      endDate: ToBackendFormatedDate(addDays(new Date(), 30)),
      offset: 0,
      limit: 4
    },
    { suspense: false }
  )

  if (pagedBirthdays && pagedBirthdays.pagedBirthdays.totalCount === 0) return null

  return (
    <Grid item sm={12} lg={6}>
      <HideableSuspensePaper isLoading={isLoading} title={t('homepage.event.birthdays')}>
        <HomepageEventWidget
          data={pagedBirthdays?.pagedBirthdays.data ?? []}
          length={pagedBirthdays?.pagedBirthdays.totalCount ?? 0}
          subtext={(item: UpcomingAnniversariesBirthdaysModel) =>
            `${item.years} (${FormatDateToDDMM(new Date(item.upcomingDate))})`
          }
          onItemClick={item => navigate(People.PEOPLE_ID_DETAILS(item.personId))}
          onMoreClick={() => {
            openFlyIn({ content: <OverviewEventBirthdays /> })
          }}
        />
      </HideableSuspensePaper>
    </Grid>
  )
}

export default UpcomingBirthdays
