import { OrderDirection, useGetAllEmployerDocumentTypesQuery } from '@epix-web-apps/core'
import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { Documents, useGlobalPersistedStore } from '@epix-web-apps/ui'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined'
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view'

/* eslint-disable-next-line */
export interface DocumenttypesTreeViewProps {
  setTitleName: (name: string) => void
}

interface TreeNode {
  id: string
  label: string
  icon?: any
  children: TreeNode[]
  route?: { id: string; label: string }[]
}

function DocumentTypesTreeView({ setTitleName }: DocumenttypesTreeViewProps) {
  const navigate = useNavigate()
  const { icpFilters } = useGlobalPersistedStore()

  const { data = { allEmployerDocumentTypes: [] } } = useGetAllEmployerDocumentTypesQuery({
    activeIcpsOnly: true,
    activeDocumentsOnly: true,
    icps: icpFilters,
    sortByProperty: 'Code',
    orderDirection: OrderDirection.Asc
  })

  const treeData = data.allEmployerDocumentTypes.map(icpDocumentTypes => {
    const icp = { id: icpDocumentTypes.icpId, label: icpDocumentTypes.icpCode }
    return {
      ...icp,
      icon: PublicOutlinedIcon,
      children: icpDocumentTypes.employerDocumentTypesModels.map(employerDocumentTypes => {
        const employer = { id: employerDocumentTypes.employerId, label: employerDocumentTypes.companyName }
        return {
          ...employer,
          icon: ApartmentOutlinedIcon,
          children: employerDocumentTypes.documentTypes.map(documentType => {
            const document = { id: documentType.id, label: documentType.name || documentType.nameDefaultValue }
            return {
              ...document,
              icon: FolderOpenOutlinedIcon,
              children: [],
              route: [icp, employer, document]
            }
          })
        }
      })
    } as TreeNode
  })

  const [expandedItems, setExpandedItems] = useState<string[]>(getTreeIds(treeData, 0))

  return (
    <SimpleTreeView expandedItems={expandedItems} onExpandedItemsChange={(e, itemIds) => setExpandedItems(itemIds)}>
      {treeData.map(t => (
        <DocumentTypesTreeViewItem key={t.id} node={t} setTitleName={setTitleName} navigate={navigate} />
      ))}
    </SimpleTreeView>
  )
}

export default DocumentTypesTreeView

interface DocumentTypesTreeViewItemProps {
  node: TreeNode
  setTitleName: (name: string) => void
  navigate: NavigateFunction
}

function DocumentTypesTreeViewItem({ node, setTitleName, navigate }: DocumentTypesTreeViewItemProps) {
  return (
    <TreeItem
      itemId={node.id}
      label={node.label}
      slots={{ icon: node.icon }}
      onClick={_ => {
        if (node.children.length === 0 && node.route) {
          setTitleName(`${node.route[0].label} - ${node.route[1].label} - ${node.route[2].label}`)
          navigate(
            Documents.DOCUMENTS_ICP_ID_EMPLOYER_ID_DOCUMENTTYPE_ID(node.route[0].id, node.route[1].id, node.route[2].id)
          )
        }
      }}
    >
      {node.children.map(t => (
        <DocumentTypesTreeViewItem key={t.id} node={t} setTitleName={setTitleName} navigate={navigate} />
      ))}
    </TreeItem>
  )
}

function getTreeIds(tree: TreeNode[], depth: number): string[] {
  const ids = tree.flatMap(t => t.id)
  if (depth === 0) {
    return ids
  }
  return [
    ...ids,
    ...getTreeIds(
      tree.flatMap(t => t.children),
      depth - 1
    )
  ]
}
