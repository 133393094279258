import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { SortModel, ScrollableDataTable, usePaginationModel } from '../../components/data-table'
import { ERRORTYPE, OrderDirection, useGetAllValidationErrorsQuery, useNavigateWithParams } from '@epix-web-apps/core'
import { Box, IconButton, MenuItem, Switch, Tooltip, Typography, useTheme } from '@mui/material'
import DropdownMenu from '../../components/dropdown-menu'
import EditViewedValidationError from '../../components/validation-components/edit-viewed-validation-error'
import { Documentation, People, useFlyIn, useGlobalPersistedStore } from '@epix-web-apps/ui'
import FilterButton from '../../components/filter-button'
import DataTableEmpty from '../../components/data-table/data-table-empty'
import DoneIcon from '@mui/icons-material/Done'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { ContractTabs } from '../../components/contract-details-components/contract-tab-general/contract-tab-general'
import { InfoOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

/* eslint-disable-next-line */
export interface ValidationErrorsPageProps {}

export function ValidationErrorsPage(props: ValidationErrorsPageProps) {
  const navigateWithParams = useNavigateWithParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme = useTheme()
  const { openFlyIn } = useFlyIn()
  const { icpFilters } = useGlobalPersistedStore()
  const { paginationModel, setPaginationModel, resetPaginationModel } = usePaginationModel()
  const [rowCount, setRowCount] = useState(0)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [validationErrorId, setValidationErrorId] = useState('')
  const [filters, setFilters] = useState({
    showViewed: false
  })

  const handleOnrowClicked = async (row: any) => {
    goToContractDetailsPage(row.personId, row.contractId)
  }

  const goToContractDetailsPage = (personId: string, contractId: string) => {
    if (contractId) {
      navigateWithParams(People.PEOPLE_ID_CONTRACTS_ID(personId, contractId), {
        tabIndex: ContractTabs.VALIDATION_ERRORS.toString()
      })
    } else {
      navigateWithParams(People.PEOPLE_ID_VALIDATION_ERRORS(personId))
    }
  }

  const errorTypeActions = (rowParams: GridRenderCellParams) =>
    rowParams.value.toString().toUpperCase() === ERRORTYPE.ERROR ? (
      <Tooltip title={t('validationerrorspage.tooltip.error')}>
        <ErrorOutlineOutlinedIcon sx={{ color: theme.palette.error.light }} />
      </Tooltip>
    ) : (
      <Tooltip title={t('validationerrorspage.tooltip.warning')}>
        <WarningAmberOutlinedIcon sx={{ color: theme.palette.warning.light }} />
      </Tooltip>
    )

  const hasSeenActions = (rowParams: GridRenderCellParams) => {
    return rowParams.value && <VisibilityOffIcon />
  }

  const rowActions = (rowParams: GridRenderCellParams) =>
    rowParams.row.errorType.toString().toUpperCase() === ERRORTYPE.WARNING ? (
      <>
        <IconButton
          aria-label="row actions"
          aria-controls="menu-row"
          aria-haspopup="true"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setAnchorEl(e.currentTarget)
            setValidationErrorId(rowParams.row.id)
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <IconButton aria-label="row details" aria-controls="details-row">
          <KeyboardArrowRightIcon />
        </IconButton>
      </>
    ) : (
      <Box sx={{ pl: [5] }}>
        <IconButton aria-label="row details" aria-controls="details-row">
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    )

  const columns: GridColDef[] = [
    {
      field: 'errorType',
      headerName: t('validationerrorspage.datatable.column.errortype'),
      flex: 0.25,
      renderCell: errorTypeActions
    },
    {
      field: 'displayName',
      headerName: t('validationerrorspage.datatable.column.displayname'),
      flex: 0.5
    },
    {
      field: 'errorCode',
      headerName: t('validationerrorspage.datatable.column.errorcode'),
      flex: 0.25
    },
    {
      field: 'contract',
      headerName: t('validationerrorspage.datatable.column.contract'),
      flex: 0.75
    },
    {
      field: 'entity',
      headerName: t('validationerrorspage.datatable.column.entity'),
      flex: 0.5
    },
    {
      field: 'validationErrorMessage',
      headerName: t('validationerrorspage.datatable.column.validationerrormessage'),
      flex: 1,
      sortable: false
    },
    { field: 'hasSeen', headerName: '', renderCell: hasSeenActions },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns[0]?.field, OrderDirection.Asc))
  const [tableData, setTableData] = useState<GridRowsProp>()

  useEffect(() => {
    resetPaginationModel()
  }, [filters])

  const {
    data: validations,
    isLoading,
    refetch: refetchValidations
  } = useGetAllValidationErrorsQuery({
    icps: icpFilters,
    showHasSeen: filters.showViewed,
    offset: paginationModel.offset,
    limit: paginationModel.pageSize,
    sortByProperty: sortModel.field,
    orderDirection: sortModel.orderDirection
  }, { suspense: false })

  useEffect(() => {
    if (validations && validations.validationErrors) {
      const validationData = validations.validationErrors
      setTableData(
        validationData.data.map(row => {
          return {
            id: row.id,
            personId: row.personId,
            contractId: row.contractId,
            contract: row.contractId ? row.companyName + ' (' + row.countryKey + ')' : '',
            displayName: row.displayName,
            errorCode: row.errorCode,
            entity: row.errorCodeEntityName,
            validationErrorMessage: row.validationErrorMessage,
            errorType: row.errorType,
            hasSeen: row.hasSeen
          }
        })
      )
      setRowCount(validationData.totalCount)
    }
  }, [validations])

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2]
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'end',
          gap: 1
        }}
      >
        <Typography m={0} variant="h2">
          {t('validationerrorspage.title')}
        </Typography>
        <Tooltip title={t('validationerrorspage.tooltip.docs')}>
          <IconButton size="small" onClick={() => navigate(Documentation.VALIDATIONS)}>
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      </Box>
      <FilterButton filters={filters}>
        <p>
          <Switch
            checked={filters.showViewed}
            onChange={e => setFilters({ ...filters, showViewed: e.target.checked })}
            aria-label={t('validationerrorspage.filters.showViewed')}
          />
          {t('validationerrorspage.filters.showViewed')}
        </p>
      </FilterButton>
    </Box>
  )

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        onSortChange={setSortModel}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowClick={params => handleOnrowClicked(params.row)}
        isLoading={isLoading}
        sortModel={sortModel}
        filterBarElement={filterBar}
        emptyStateElement={
          <DataTableEmpty
            title={t('emptystate.validations')}
            icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.success.light }} />}
          />
        }
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={e =>
            openFlyIn({
              content: <EditViewedValidationError validationErrorId={validationErrorId!} />,
              callbackAfterClose: () => refetchValidations
            })
          }
        >
          {t('validationerrorspage.list.row.menu.editvalidationerrorhasseen')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default ValidationErrorsPage
