import { useTranslation } from 'react-i18next'
import { Grid, Box, Typography, Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useNavigate } from 'react-router-dom'
import {
  ROLE,
  useGetAverageAgeQuery,
  useGetAverageSeniorityQuery,
  useGetFullTimeEquivalentQuery,
  useGetHeadCountQuery
} from '@epix-web-apps/core'
import { useRouteDefinitions, useGlobalPersistedStore, CanView } from '@epix-web-apps/ui'
import { GeneralStatistics } from '../general-statistics'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import WorkIcon from '@mui/icons-material/Work'
import CakeIcon from '@mui/icons-material/Cake'

/* eslint-disable-next-line */
export interface AnalyticsPaperProps {}

export function AnalyticsOverview(props: AnalyticsPaperProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { analyticsRoutes } = useRouteDefinitions()
  const { icpFilters } = useGlobalPersistedStore()

  const headcountQuery = useGetHeadCountQuery({ icps: icpFilters }, { suspense: false })
  const fteQuery = useGetFullTimeEquivalentQuery({ icps: icpFilters }, { suspense: false })
  const seniorityQuery = useGetAverageSeniorityQuery({ icps: icpFilters }, { suspense: false })
  const ageQuery = useGetAverageAgeQuery({ icps: icpFilters }, { suspense: false })

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', p: 1, mb: 2 }}>
        <Typography variant="h4" m={0}>
          {t('homepage.analystics')}
        </Typography>
        <Box sx={{ m: 1 }}>
          <CanView roles={[ROLE.ADMIN, ROLE.VIEWWORKFORCEANALYTICS]}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                navigate(analyticsRoutes.WORKFORCE)
              }}
            >
              <SearchIcon />
              {t('homepage.analytics.viewdetails')}
            </Button>
          </CanView>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex' }}>
        <Grid container spacing={2}>
          <GeneralStatistics
            image={<AccessibilityIcon />}
            name={t('statistics.headcount')}
            value={headcountQuery.data?.headcount.value}
            isLoading={headcountQuery.isLoading}
          />
          <GeneralStatistics
            image={<AccessibilityIcon />}
            name={t('statistics.fulltimeequivalents')}
            value={fteQuery.data?.peopleFullTimeEquivalents.value}
            isLoading={fteQuery.isLoading}
          />
          <GeneralStatistics
            image={<WorkIcon />}
            name={t('statistics.seniority')}
            value={seniorityQuery.data?.averageSeniority.value}
            isLoading={seniorityQuery.isLoading}
          />
          <GeneralStatistics
            image={<CakeIcon />}
            name={t('statistics.age')}
            value={ageQuery.data?.averageAge.value + 'y'}
            isLoading={ageQuery.isLoading}
          />
        </Grid>
      </Box>
    </>
  )
}

export default AnalyticsOverview
