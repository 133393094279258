import { Box, Button, SxProps, Typography, Link, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import { useNavigate } from 'react-router-dom'
import { IcpCodeBadge } from '../icp-components'
import {
  groupBy,
  IsDateBeforeToday,
  PROCESS_TYPE,
  ToBackendFormatedDate,
  useGetProcessOverviewQuery,
  capitalise,
  ProcessOverviewModel,
  DEFAULT_LOCALE
} from '@epix-web-apps/core'
import { Fragment } from 'react'
import { HideableSuspensePaper } from '../suspense-paper'
import { Process, useGlobalPersistedStore, useGlobalStore } from '@epix-web-apps/ui'
import moment from 'moment'
import { addDays } from 'date-fns'

/* eslint-disable-next-line */
export interface TaskProcessOverviewProps {
  sx?: SxProps
}

export function TaskProcessOverview({ sx }: TaskProcessOverviewProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { icpFilters } = useGlobalPersistedStore()
  const { me } = useGlobalStore()

  const { data: getProcesses, isLoading } = useGetProcessOverviewQuery(
    {
      icps: icpFilters,
      startDate: ToBackendFormatedDate(new Date()),
      endDate: ToBackendFormatedDate(addDays(new Date(), 30))
    },
    { suspense: false }
  )

  if (!getProcesses || (getProcesses && getProcesses.processOverview.length === 0)) return null

  // group by {year}-{month}
  const grouped = groupBy(
    getProcesses.processOverview,
    process => `${new Date(process.dueDate).getFullYear()}-${new Date(process.dueDate).getMonth() + 1}`
  )

  // sort on {year}-{month} ascending
  const sorted = Object.entries(grouped).sort(
    (a, b) => new Date(a[1][0].dueDate).getTime() - new Date(b[1][0].dueDate).getTime()
  )
  return (
    <HideableSuspensePaper isLoading={isLoading}>
      <Box sx={{ mx: 1, display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h4">{t('homepage.taskandprocesses')}</Typography>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                navigate(Process.PROCESSES())
              }}
            >
              <SearchIcon />
              {t('homepage.analytics.viewdetails')}
            </Button>
          </Box>
        </Box>

        <Box sx={{ flexGrow: '1' }}>
          {sorted.map(([yearMonth, processes]) => (
            <Fragment key={yearMonth}>
              <Typography variant="h5">
                {capitalise(
                  moment(yearMonth.split('-')[1], 'M')
                    .locale(me?.locale.locale ?? DEFAULT_LOCALE)
                    .format('MMMM')
                )}
              </Typography>
              {processes.map(process => (
                <ProcessTask process={process} key={process.id} />
              ))}
            </Fragment>
          ))}
        </Box>
      </Box>
    </HideableSuspensePaper>
  )
}

export default TaskProcessOverview

function ProcessTask({ process }: { process: ProcessOverviewModel }) {
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
      <Box
        sx={{
          bgcolor: IsDateBeforeToday(process.dueDate) ? theme.palette.error.main : theme.palette.primary.main,
          minWidth: '1.8rem',
          height: '1.8rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography color={theme.palette.error.contrastText}>{new Date(process.dueDate).getDate()}</Typography>
      </Box>
      <Box>
        <Link
          underline="always"
          onClick={() => {
            navigate(
              Process.PROCESSES_TYPE_ID(
                `${PROCESS_TYPE[process.type.key as keyof typeof PROCESS_TYPE]}`,
                `${process.id}`
              )
            )
          }}
        >
          {process.name}
        </Link>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            height: '1.4rem'
          }}
        >
          <IcpCodeBadge icpCode={process.countryKey} />
          <Typography sx={{ fontSize: '0.8rem', color: theme.palette.text.primary }}>{process.type.value}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
