import { DEFAULT_DATE, IsDefaultBackendDate, ToBackendFormatedDate } from '@epix-web-apps/core'
import { FormControl, GridSize } from '@mui/material'
import { DatePicker, DateView } from '@mui/x-date-pickers'
import { Controller, useFormContext } from 'react-hook-form'
import { OptionalGridWrapper } from '../form-grid-wrapper'

export interface FormDatepickerProps {
  name: string
  label: string
  sx?: GridSize
  onChange?: (value: Date | null) => void
  shouldDisableDate?: (date: Date) => boolean
  disabled?: boolean
  views?: DateView[]
  openTo?: DateView
  inputFormat?: string
}
export const DATE_INPUT_FORMAT = 'dd/MM/yyyy'

export function FormDatepicker({
  name,
  label,
  sx,
  onChange,
  shouldDisableDate,
  disabled = false,
  views = ['year', 'month', 'day'],
  openTo = 'day',
  inputFormat = DATE_INPUT_FORMAT
}: FormDatepickerProps) {
  const form = useFormContext()
  if (!form) throw Error('Form elements can only be used inside the react hook forms provider')
  const { ref, ...register } = form.register(name)
  const defaultValue =
    !isNaN(new Date(form?.getValues(name)).getTime()) || !IsDefaultBackendDate(new Date(form?.getValues(name)))
      ? new Date(form?.getValues(name))
      : null

  return (
    <OptionalGridWrapper sx={sx}>
      <FormControl fullWidth size="small">
        <Controller
          {...register}
          defaultValue={defaultValue}
          render={({ field }) => (
            <DatePicker
              openTo={openTo}
              shouldDisableDate={shouldDisableDate}
              format={inputFormat}
              label={label}
              disabled={disabled}
              {...field}
              onChange={e => {
                if (e !== null && !isNaN(e?.getTime()) && new Date(e)?.getFullYear() < 1900) return
                field.onChange(e)
                onChange && onChange(e)
              }}
              minDate={new Date('01/01/1900')}
              value={
                ToBackendFormatedDate(field.value) === ToBackendFormatedDate(new Date(DEFAULT_DATE))
                  ? null
                  : field.value || null
              }
              views={views}
              slotProps={{
                clearButton: { size: 'small' },
                openPickerButton: { size: 'small' },
                field: { clearable: true },
                textField: {
                  size: 'small',
                  id: register.name,
                  label: label,
                  error: !!form.getFieldState(name).error
                }
              }}
            />
          )}
        />
      </FormControl>
    </OptionalGridWrapper>
  )
}

export default FormDatepicker
