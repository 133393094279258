import {
  FormSelectOption,
  formTypeSelectOptions,
  GraphqlError,
  OrderDirection,
  PayrollCodeContractBankDetailModel,
  useCreatePayrollCodeContractBankDetailMutation,
  useGetAllPayrollCodesByEmployerIdQuery,
  useGetContractByIdQuery,
  useUpdatePayrollCodeContractBankDetailMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { AccountBalanceOutlined } from '@mui/icons-material'
import { Box, Divider, Typography } from '@mui/material'
import { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

interface AddEditBankDetailsProps {
  contractId: string
  payrollCodeContract: PayrollCodeContractBankDetailModel | null
}
export function AddEditBankDetails({ contractId, payrollCodeContract }: Readonly<AddEditBankDetailsProps>) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<GraphqlError[]>([])

  const { groupOptions, subGroupOptions } = formTypeSelectOptions
  const { data: getContractById } = useGetContractByIdQuery({ contractId: contractId }, { suspense: false })
  const { data: payrollCodes } = useGetAllPayrollCodesByEmployerIdQuery(
    {
      employerId: getContractById?.contractById.employerId ?? '',
      payrollCodeFilterModel: { showSalaryCodes: true },
      offset: 0,
      limit: -1,
      orderDirection: OrderDirection.Asc
    },
    {
      suspense: false,
      enabled: !!getContractById?.contractById.employerId,
      onSuccess: data => {
        const payrollCode = data.allPayrollCodesByEmployerId.data.find(p => p.id === payrollCodeContract?.payrollCodeId)
        if (payrollCode) {
          form.setValue('groupType', payrollCode.group.key)
          form.setValue('subGroupType', payrollCode.subGroup!.key)
        }
      }
    }
  )

  const addPayrollcodeContractBankDetailSchema = z.object({
    groupType: z
      .string({
        required_error: t('form.validation.grouptyperequired'),
        invalid_type_error: t('form.validation.grouptyperequired')
      })
      .min(1, t('form.validation.grouptyperequired')),
    subGroupType: z
      .string({
        required_error: t('form.validation.subgrouptyperequired'),
        invalid_type_error: t('form.validation.subgrouptyperequired')
      })
      .min(1, t('form.validation.subgrouptyperequired')),
    payrollCode: z
      .string({
        required_error: t('form.validation.payrollcoderequired'),
        invalid_type_error: t('form.validation.payrollcoderequired')
      })
      .min(1, t('form.validation.payrollcoderequired')),
    bankAccount: z.string().nullable(),
    accountHolder: z.string().nullable(),
    iban: z.string().nullable(),
    bankIdentificationNumber: z.string().nullable(),
    bankSortCode: z.string().nullable()
  })

  type AddPayrollCodeConractBankDetailForm = z.TypeOf<typeof addPayrollcodeContractBankDetailSchema>

  const form = useForm<AddPayrollCodeConractBankDetailForm>({
    resolver: zodResolver(addPayrollcodeContractBankDetailSchema),
    defaultValues: !payrollCodeContract
      ? {}
      : {
          groupType: payrollCodes?.allPayrollCodesByEmployerId.data.find(
            p => payrollCodeContract.payrollCodeId === p.id
          )?.group.key,
          subGroupType: payrollCodes?.allPayrollCodesByEmployerId.data.find(
            p => payrollCodeContract.payrollCodeId === p.id
          )?.subGroup?.key,
          payrollCode: payrollCodeContract.payrollCodeId,
          accountHolder: payrollCodeContract.accountHolder,
          bankAccount: payrollCodeContract.bankAccountNumber,
          iban: payrollCodeContract.iban,
          bankIdentificationNumber: payrollCodeContract.bic,
          bankSortCode: payrollCodeContract.bankSortCode
        }
  })

  const createMutation = useCreatePayrollCodeContractBankDetailMutation()
  const updateMutation = useUpdatePayrollCodeContractBankDetailMutation()

  async function handleOnSubmit(form: AddPayrollCodeConractBankDetailForm) {
    if (payrollCodeContract === null) {
      await createMutation
        .mutateAsync({
          command: {
            contractId: contractId,
            payrollCodeId: form.payrollCode,
            bankAccount: form.bankAccount,
            accountHolder: form.accountHolder,
            iban: form.iban,
            bic: form.bankIdentificationNumber,
            bankSortCode: form.bankSortCode
          }
        })
        .then(closeFlyIn)
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          command: {
            id: payrollCodeContract.id,
            contractId: contractId,
            bankAccount: form.bankAccount,
            accountHolder: form.accountHolder,
            iban: form.iban,
            bic: form.bankIdentificationNumber,
            bankSortCode: form.bankSortCode
          }
        })
        .then(closeFlyIn)
        .catch(e => setBackendErrors([e]))
    }
  }

  const watchedGroupTypeKey = useWatch({ control: form.control, name: 'groupType' })
  const watchedSubGroupTypeKey = useWatch({ control: form.control, name: 'subGroupType' })

  const payrollCodeOptions = payrollCodes?.allPayrollCodesByEmployerId.data
    .filter(p => !watchedGroupTypeKey || p.group.key.includes(watchedGroupTypeKey))
    .filter(p => !watchedSubGroupTypeKey || p.subGroup?.key.includes(watchedSubGroupTypeKey))
    .map(p => new FormSelectOption(p.id, `${p.code} - ${p.userFriendlyDescription ?? p.description}`))

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editperson.bankinfo.title')}</Typography>
      <FormGridLayout>
        <FormSelect
          sx={12}
          name="groupType"
          label={`${t('form.field.grouptype')} *`}
          options={groupOptions}
          disabled={payrollCodeContract !== null}
        />

        <FormSelect
          sx={12}
          name="subGroupType"
          label={`${t('form.field.subgrouptype')} *`}
          options={subGroupOptions.filter(s => s.id.includes(watchedGroupTypeKey))}
          disabled={payrollCodeContract !== null}
        />

        <FormSelect
          sx={12}
          name="payrollCode"
          label={`${t('form.field.payrollcode')} *`}
          options={payrollCodeOptions}
          disabled={payrollCodeContract !== null}
          onChange={(_e, payrollCodeOption) => {
            const groupTypeKey = payrollCodes?.allPayrollCodesByEmployerId.data.find(
              p => p.id === payrollCodeOption?.id
            )?.group.key
            const subGroupTypeKey = payrollCodes?.allPayrollCodesByEmployerId.data.find(
              x => x.id === payrollCodeOption?.id
            )?.subGroup?.key
            form.resetField('groupType', {
              defaultValue: groupTypeKey
            })
            form.resetField('subGroupType', {
              defaultValue: subGroupTypeKey
            })
          }}
        />
      </FormGridLayout>
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Box>
          <AccountBalanceOutlined />
        </Box>
        <FormGridLayout>
          <FormInput sx={12} name="accountHolder" label={t('form.field.accountholder')} />

          <FormInput sx={12} name="bankAccount" label={t('form.field.bankaccount')} />

          <FormInput sx={12} name="iban" label={t('form.field.iban')} />

          <FormInput sx={12} name="bankIdentificationNumber" label={t('form.field.bankidentificationnumber')} />

          <FormInput sx={12} name="bankSortCode" label={t('form.field.banksortcode')} />
        </FormGridLayout>
      </Box>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createMutation.isLoading || updateMutation.isLoading}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}
