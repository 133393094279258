import {
  OrderDirection,
  useGetAllPayComponentHistoriesByPayComponentIdQuery,
  CALCULATED_DATA_VALUE_TYPE_KEYS,
  VALUE_TYPE_KEYS
} from '@epix-web-apps/core'
import { Box, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, SortModel } from '../../data-table'
import { FLYIN_WIDTH } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface ViewPaycomponentHistoryProps {
  payComponentId: string
  endDate: Date
  contractId: string
  icpCurrencySymbol: string
}

export function ViewPaycomponentHistory({
  payComponentId,
  contractId,
  icpCurrencySymbol
}: ViewPaycomponentHistoryProps) {
  const { t } = useTranslation()
  const [tableDateHistory, setTableDateHistory] = useState<GridRowsProp>()

  const { data: history, isLoading: isLoadingHistory } = useGetAllPayComponentHistoriesByPayComponentIdQuery({
    id: payComponentId,
    contractId: contractId || ''
  })

  const valueActions = (rowParams: GridRenderCellParams) =>
    rowParams.row.valueType === CALCULATED_DATA_VALUE_TYPE_KEYS.PERCENTAGE ? (
      <p>
        {rowParams.row.value} {'%'}
      </p>
    ) : rowParams.row.valueType === VALUE_TYPE_KEYS.DEFAULT ? (
      <p>
        {rowParams.row.value} {icpCurrencySymbol}
      </p>
    ) : (
      <p>{rowParams.row.value}</p>
    )

  const columns: GridColDef[] = [
    {
      field: 'startDate',
      headerName: t('contractsalarypage.datatable.column.periodstart'),
      flex: 2,
      sortable: false
    },
    {
      field: 'endDate',
      headerName: t('contractsalarypage.datatable.column.periodend'),
      flex: 2,
      sortable: false
    },
    {
      field: 'value',
      headerName: t('contractsalarypage.datatable.column.value'),
      renderCell: valueActions,
      flex: 1.5,
      sortable: false,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'reasonSalaryChange',
      headerName: t('contractsalarypage.datatable.column.reasonsalarychange'),
      sortable: false
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns[0]?.field, OrderDirection.Asc))

  useEffect(() => {
    if (history && history?.payComponentHistoriesByPayComponentId) {
      const payComponentsData = history.payComponentHistoriesByPayComponentId
      setTableDateHistory(
        payComponentsData.map(row => {
          return {
            id: row.id,
            reasonSalaryChange: row.reasonSalaryChangeType?.value,
            value: row.value,
            valueType: row.valueType?.key,
            startDate: new Date(row.startDate).toLocaleDateString(),
            endDate: row.endDate ? new Date(row.endDate).toLocaleDateString() : null
          }
        })
      )
    }
  }, [history])

  return (
    <Box sx={{ width: FLYIN_WIDTH.DEFAULT }}>
      <Typography variant="h4">{t('flyin.earning.viewhistory')}</Typography>
      <DataTable
        data={tableDateHistory}
        columns={columns}
        onSortChange={setSortModel}
        isLoading={isLoadingHistory}
        sortModel={sortModel}
        hideFooter={true}
      />
    </Box>
  )
}

export default ViewPaycomponentHistory
