import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import {
  DEFAULT_LOCALE,
  OrderDirection,
  useGetPayrollClosureProcessQuery,
  useGetPersonFamilyMemberStateCorrectionsByProcessIdQuery
} from '@epix-web-apps/core'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { DataTable, usePaginationModel, SortModel } from '../../../data-table'
import DataTableEmpty from '../../../data-table/data-table-empty/data-table-empty'
import DoneIcon from '@mui/icons-material/Done'
import { minWidthPayrollClosure } from '../steps'
import { StepParams } from '../../generic-steps'
import DataExceedsPagesize from '../data-exceeds-pagesize'

/* eslint-disable-next-line */
export interface FamilyStateCorrectionsComponentProps {}

export function FamilyStateCorrectionsComponent(props: FamilyStateCorrectionsComponentProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useParams<StepParams>()
  const [rowCount, setRowCount] = useState(0)
  const { paginationModel } = usePaginationModel(0, 101)
  const [tableData, setTableData] = useState<GridRowsProp>()

  const { data: getPayrollClosureProcess } = useGetPayrollClosureProcessQuery(
    {
      id: params?.id || ''
    },
    {
      enabled: !!params?.id
    }
  )

  const { data: personFamilyMemberStateCorrections, isLoading } =
    useGetPersonFamilyMemberStateCorrectionsByProcessIdQuery(
      {
        processId: getPayrollClosureProcess?.payrollClosureProcess.id || '',
        offset: paginationModel.offset,
        limit: paginationModel.pageSize
      },
      {
        suspense: false
      }
    )

  const columns: GridColDef[] = [
    {
      field: 'version',
      headerName: t('personcontractdata.datatable.column.version'),
      flex: 200,
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employerNumber',
      headerName: t('personcontractdata.datatable.column.employernumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employeeNumber',
      headerName: t('personcontractdata.datatable.column.employeenumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'familyMemberName',
      headerName: t('personcontractdata.datatable.column.familymembername'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'validFrom',
      headerName: t('personcontractdata.datatable.column.validfrom'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'validTo',
      headerName: t('personcontractdata.datatable.column.validTo'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'dependant',
      headerName: t('personcontractdata.datatable.column.dependant'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'disabled',
      headerName: t('personcontractdata.datatable.column.disabled'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'disabledPercentage',
      headerName: t('personcontractdata.datatable.column.disabledPercentage'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'relationTypeKey',
      headerName: t('personcontractdata.datatable.column.relationtypekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'relationType',
      headerName: t('personcontractdata.datatable.column.relationtype'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    }
  ]

  const [sortModel, _] = useState(new SortModel(columns?.[1]?.field, OrderDirection.Asc))

  useEffect(() => {
    if (
      personFamilyMemberStateCorrections &&
      personFamilyMemberStateCorrections?.personFamilyMemberStateCorrectionsByProcessId
    ) {
      const personFamilyMemberStateCorrectionsData =
        personFamilyMemberStateCorrections.personFamilyMemberStateCorrectionsByProcessId
      setTableData(
        personFamilyMemberStateCorrectionsData.data.map((row: any, index: number) => {
          return {
            id: index,
            version: row.version,
            employeeNumber: row.employeeNumber,
            employerNumber: row.employerNumber,
            familyMemberName: row.familyMemberName,
            validFrom: new Date(row.validFrom).toLocaleDateString(DEFAULT_LOCALE),
            validTo: row.validTo ? new Date(row.validTo).toLocaleDateString(DEFAULT_LOCALE) : null,
            dependant: row.dependant,
            disabled: row.disabled,
            disabledPercentage: row.disabledPercentage,
            relationTypeKey: row.relationType?.key,
            relationType: row.relationType?.value
          }
        })
      )
      setRowCount(personFamilyMemberStateCorrectionsData.totalCount)
    }
  }, [personFamilyMemberStateCorrections])

  return (
    <>
      {rowCount === paginationModel.pageSize && <DataExceedsPagesize />}
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        hideFooter={true}
        isLoading={isLoading}
        sortModel={sortModel}
        emptyStateElement={
          <DataTableEmpty
            title={t('emptystate.process.contract')}
            icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
          />
        }
      />
    </>
  )
}

export default FamilyStateCorrectionsComponent
