import {
  AsbplContractDetailModel,
  FormSelectOption,
  GraphqlError,
  PARTNER_LIST_IDENTIFIERS,
  useGetAllPartnerContractDetailTypesByListIdentifierQuery,
  useUpdateAsbplGeneralMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

interface EditASBPLGeneralProps {
  contractDetail: AsbplContractDetailModel
}

export function EditASBPLGeneral({ contractDetail }: EditASBPLGeneralProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<GraphqlError[]>([])

  const { data: plDisabilityStages } = useGetAllPartnerContractDetailTypesByListIdentifierQuery({
    listIdentifier: PARTNER_LIST_IDENTIFIERS.PL_GENERAL_DISABILITY_STAGE,
    limit: -1,
    offset: 0
  })

  const disabilityStages = plDisabilityStages?.allPartnerContractDetailTypesByListIdentifier.data.map(
    p => new FormSelectOption(p.id, p.value)
  )

  const editASBPLGeneralSchema = object({
    disabilityStage: string().nullable().optional()
  })

  type EditASBPLGeneralForm = TypeOf<typeof editASBPLGeneralSchema>

  const form = useForm<EditASBPLGeneralForm>({
    resolver: zodResolver(editASBPLGeneralSchema),
    defaultValues: {
      disabilityStage: contractDetail.generalDisabilityStageType?.id
    }
  })

  const mutation = useUpdateAsbplGeneralMutation()

  function handleOnSubmit(form: EditASBPLGeneralForm) {
    mutation
      .mutateAsync({
        updateASBPLGeneralCommand: {
          id: contractDetail.id,
          disabilityStageTypeId: form.disabilityStage
        }
      })
      .then(() => closeFlyIn())
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('asbpldetailpage.general.flyin.title')}</Typography>
      <FormGridLayout>
        <FormSelect
          sx={12}
          name={'disabilityStage'}
          label={t('asbpldetailpage.general.flyin.disabilitystage')}
          options={disabilityStages}
        />
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons onCancel={() => closeFlyIn()} isMutating={mutation.isLoading} />
    </FormContainer>
  )
}
