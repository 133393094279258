import { Grid, Typography, Box, useTheme, Skeleton } from '@mui/material'

/* eslint-disable-next-line */
export interface GeneralStatisticsProps {
  image: any
  name: string
  value?: string | number
  isLoading: boolean
}

export function GeneralStatistics({ image, name, value, isLoading }: GeneralStatisticsProps) {
  const theme = useTheme()
  return (
    <Grid textAlign="center" item xs={12} md={6} lg={3}>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'end',
          gap: 1,
          svg: {
            color: theme.palette.primary.main,
            height: '2.5rem',
            width: '2.5rem'
          },
          h4: {
            marginBottom: '0',
            color: theme.palette.text.primary
          }
        }}
      >
        {image}
        {isLoading ? <Skeleton width={'3rem'} /> : <Typography variant="h4">{value}</Typography>}
      </Box>
      <Typography color={theme.palette.text.primary} variant="h6">
        {name}
      </Typography>
    </Grid>
  )
}

export default GeneralStatistics
