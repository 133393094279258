import { Avatar, Box, useTheme } from '@mui/material'
import { EpixAvatarInfoBlock } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react'

type UpcomingPeopleBaseModel = {
  personId: string
  firstName: string
  lastName: string
  upcomingDate: string
}
/* eslint-disable-next-line */
export interface HomepageEventWidgetProps {
  data: UpcomingPeopleBaseModel[]
  length: number
  subtext: (item: any) => string
  onItemClick: (item: UpcomingPeopleBaseModel) => void
  onMoreClick: () => void
}

export function HomepageEventWidget({ data, length, subtext, onItemClick, onMoreClick }: HomepageEventWidgetProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Box sx={{ display: 'flex', padding: 1, justifyContent: 'space-around' }}>
      {data?.map((item, index) => {
        return (
          <Fragment key={`${item.personId}-${index}`}>
            {(index < 3 || (index === 3 && length === 4)) && (
              <EpixAvatarInfoBlock
                personId={item.personId}
                linkText={`${item.firstName} ${item.lastName.substring(0, 1) + '.'}`}
                subText={(subtext && subtext(item)) ?? ''}
                onClick={() => onItemClick(item)}
              />
            )}
            {index === 3 && length > 4 && (
              <EpixAvatarInfoBlock
                sx={{ backgroundColor: theme.palette.secondary.main }}
                linkText={t('common.viewall')}
                onClick={() => onMoreClick()}
              >
                {length ? `+${length - 3}` : 0}
              </EpixAvatarInfoBlock>
            )}
          </Fragment>
        )
      })}
      {length < 4 &&
        [...Array(4 - length)].map((_, index) => (
          <Avatar key={index} sx={{ width: '3rem', height: '3rem', backgroundColor: 'white' }} />
        ))}
    </Box>
  )
}

export default HomepageEventWidget
