import {
  OrderDirection,
  ProductivityBradfordContractViewModel,
  ToBackendFormatedDate,
  useGetPagedProductivityBradfordPeopleQuery,
  useGetProductivityBradfordForPersonQuery
} from '@epix-web-apps/core'
import { useProductivityAnalyticsPersistedStore } from '../../../stores/productivity-store'
import {
  Box,
  CircularProgress,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  BorderedTableCell,
  PageOptions,
  PagingNavigation,
  TypographyBold,
  useGlobalPersistedStore
} from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { environment } from '../../../../../environments/environment'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { usePaginationModel } from '../../data-table'

function ProductivityBradfordTab() {
  const store = useProductivityAnalyticsPersistedStore()
  const theme = useTheme()
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const { paginationModel, setPaginationModel, resetPaginationModel } = usePaginationModel()
  const [pageDetails, setPageDetails] = useState(new PageOptions(paginationModel.page, paginationModel.pageSize, 0, 0))

  const { data, isFetching, isLoading } = useGetPagedProductivityBradfordPeopleQuery(
    {
      viewDate: ToBackendFormatedDate(store.dateFilter ?? new Date()),
      icps: icpFilters,
      employerId: store.employerFilter,
      paygroupId: store.paygroupFilter,
      offset: paginationModel.offset,
      limit: paginationModel.pageSize,
      sortByProperty: 'name',
      orderDirection: OrderDirection.Asc
    },
    { suspense: false, refetchOnWindowFocus: false }
  )

  useEffect(() => {
    resetPaginationModel()
  }, [icpFilters, store])

  useEffect(() => {
    if (data) {
      setPageDetails(
        new PageOptions(
          paginationModel.page,
          paginationModel.pageSize,
          data.pagedProductivityBradfordPeople.data.length,
          data.pagedProductivityBradfordPeople.totalCount
        )
      )
    }
  }, [data])

  return (
    <>
      <Box sx={{ overflowY: 'scroll', height: '100%', flex: 1 }}>
        <Box sx={{ height: '50vh' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TypographyBold color={theme.palette.primary.main}>
              {t('productivity.bradford.title')}{' '}
              {data &&
                `${new Date(data.pagedProductivityBradfordPeople.startDate).toLocaleDateString()} - ${new Date(
                  data.pagedProductivityBradfordPeople.endDate
                ).toLocaleDateString()}`}
            </TypographyBold>
            <Link
              target="_blank"
              href={`${environment.docs.domainName}/Epix/epix-analytics/productivityanalytics/bradford`}
            >
              <Typography color={theme.palette.text.secondary}>{t('productivity.bradford.moreinfo')}</Typography>
            </Link>
          </Box>
          <Table sx={{ border: `1px solid ${theme.palette.divider}` }}>
            <TableHead sx={{ backgroundColor: theme.palette.grey[100], position: 'sticky', top: -1 }}>
              <TableRow>
                <BorderedTableCell>{t('productivity.bradford.table.name')}</BorderedTableCell>
                <BorderedTableCell>{t('productivity.bradford.table.country')}</BorderedTableCell>
                <BorderedTableCell>{t('productivity.bradford.table.employer')}</BorderedTableCell>
                <BorderedTableCell>{t('productivity.bradford.table.paygroup')}</BorderedTableCell>
                <BorderedTableCell>{t('productivity.bradford.table.periods')}</BorderedTableCell>
                <BorderedTableCell>{t('productivity.bradford.table.days')}</BorderedTableCell>
                <BorderedTableCell>{t('productivity.bradford.table.factor')}</BorderedTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {!isLoading &&
                data?.pagedProductivityBradfordPeople.data.map(p => (
                  <UserTableRow person={p} date={store.dateFilter} key={p.contractId} />
                ))}
            </TableBody>
          </Table>
          {data?.pagedProductivityBradfordPeople.totalCount === 0 && (
            <Typography sx={{ p: 2, textAlign: 'center' }}>{t('analytics.nodata')}</Typography>
          )}
        </Box>
      </Box>{' '}
      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <PagingNavigation
          hasNext={data?.pagedProductivityBradfordPeople.hasNext ?? false}
          onNextClick={() => {
            paginationModel.page += 1
            setPaginationModel(paginationModel)
          }}
          hasPrevious={data?.pagedProductivityBradfordPeople.hasPrevious ?? false}
          onPreviousClick={() => {
            paginationModel.page -= 1
            setPaginationModel(paginationModel)
          }}
          isFetching={isFetching}
          pageOptions={pageDetails}
        />
      </Box>
    </>
  )
}

export default ProductivityBradfordTab

function UserTableRow({ person, date }: { person: ProductivityBradfordContractViewModel; date: Date | null }) {
  const queryClient = useQueryClient()
  const { data, isFetching } = useGetProductivityBradfordForPersonQuery(
    {
      contractId: person.contractId,
      viewDate: ToBackendFormatedDate(date ?? new Date())
    },
    {
      suspense: false,
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    return () => {
      queryClient.cancelQueries(
        useGetProductivityBradfordForPersonQuery.getKey({
          contractId: person.contractId,
          viewDate: ToBackendFormatedDate(date ?? new Date())
        })
      )
    }
  }, [])

  return (
    <TableRow>
      <BorderedTableCell>{person.name}</BorderedTableCell>
      <BorderedTableCell>{person.countryKey}</BorderedTableCell>
      <BorderedTableCell>{person.employerName}</BorderedTableCell>
      <BorderedTableCell>{person.paygroupName}</BorderedTableCell>
      {isFetching ? (
        <LoadingTableCell />
      ) : (
        <>
          <BorderedTableCell>{data?.productivityBradfordForPerson.periods}</BorderedTableCell>
          <BorderedTableCell>{data?.productivityBradfordForPerson.days}</BorderedTableCell>
          <BorderedTableCell>{data?.productivityBradfordForPerson.factors}</BorderedTableCell>
        </>
      )}
    </TableRow>
  )
}

function LoadingTableCell() {
  return (
    <>
      <BorderedTableCell>
        <Skeleton />
      </BorderedTableCell>
      <BorderedTableCell>
        <Skeleton />
      </BorderedTableCell>
      <BorderedTableCell>
        <Skeleton />
      </BorderedTableCell>
    </>
  )
}
